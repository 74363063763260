// @flow
import React,{Component} from 'react'
import {  Page,  Icon,  Grid,  Card,  Table,  Button,  Form, Text, StampCard } from "tabler-react";
import SiteWrapper from "../components/SiteWrapper.react";
import axios from 'axios';
//import ModalAddUser from "../components/ModalAddUser";
//import ModalFormLarge from "../components/ModalFormLarge";
import ModalFormLarge from "../components/ModalFormImage";
import ModalFormSmall from "../components/ModalFormSmall";
import SweetAlert from 'react-bootstrap-sweetalert'
import Pagination from 'react-bootstrap/Pagination'
import storeGlobal from "../components/globalStore";
//import SelectBranchs from "../components/SelectBranchs";
//import LoadingOverlay from 'react-loading-overlay'
import PulseLoader from 'react-spinners/PulseLoader'
import { Redirect } from "react-router-dom";
import Moment from 'react-moment';
import moment from 'moment'
import SingleSelect from "../components/SingleSelect";
import CountUp from 'react-countup';

class branchSetting extends Component{
  constructor(props) {
    super(props);
    const pumpout = window.vendingSettingPage.pumpout
    const var_PH = window.vendingSettingPage.var_PH
    const var_ORP = window.vendingSettingPage.var_ORP
    const var_EC = window.vendingSettingPage.var_EC
    const PumpFlow = window.vendingSettingPage.PumpFlow
    const power = window.vendingSettingPage.power
    this.rtTimeout = null

    this.state = {
      user: storeGlobal.getusers(),

      modalWorning:"",
      modalFormTitle:"",
      header:[
        {"name":"ITEM", "class":"text-center table-sticky-left"},
        {"name":"IMAGE", "class":"text-center"},
        {"name":"NAME", "class":"text-center"},
        {"name":"PRICE", "class":"text-center"},
        {"name":"CHANNEL", "class":"text-center"},
        {"name":"CURRENT VALUE", "class":"text-center"},
        {"name":"MAX VALUE", "class":"text-center"},
        {"name":"STATUS", "class":"text-center"},
        {"name":"ACTION", "class":"text-center table-sticky-right"}
      ],
      tempModalForm:{},
      modalForm: [
        { key: "branchID", name: "ID", placeholder:"", value: "", disabled: false, type: 'image', col: '2' }, 
        { key: "branchID", name: "ID", placeholder:"", value: "", disabled: false, type: 'input', col: '2' }, 
        { key: "branch", name: "Name", placeholder:"", value: "", disabled: false, type: 'input',col: '2' }, 
        { key: "location", name: "Location", placeholder:"", value: "", disabled: false, type: 'input',col: '2' }, 
        { key: "project", name: "Project", placeholder:"", value: "", disabled: false, type: 'select',col: '2', items: [
          'p1', 'p2', 'p3'
        ] }, 
      ],

      alert: null,
      paginationItem: [1],
      paginationActive: 1,
      countPerPage:48,
      organizItems:[],
      projectItems:[],
      dataResponse:{products:[]},
      dataShow:[],
      device:[],

      redirect: false,
      btnSaveClick: false,
      btnSaveEnable: false,
      btnBackupEnable : false,
      btnBackupClick : false,
      btnRestoreEnable : false,
      btnRestoreClick : false,
      btnCalibrateEnable: false,
      btnCalibrateClick: false,
      realtimeEnable : true,
      defaultFileUpload:{
        organization:'',
        uri:window.url + 'upload',
        type:'campaigns',
        filetype:['image/png', 'image/jpeg']
      },
      alertShow: false,
      alertText: '',
      isButtonLoading: true,
      isButtonSelectFileEnable: true,
      isFilePondEnable: true,
      listRestore: ['backup1'],

      pumpout: { 
        start: pumpout.start, 
        end: pumpout.end, 
        duration: pumpout.duration
      },
      var_PH: { 
        start: var_PH.start, 
        end: var_PH.end, 
        duration: var_PH.duration
      },
      var_ORP: { 
        start: var_ORP.start, 
        end: var_ORP.end, 
        duration: var_ORP.duration
      },
      var_EC: { 
        start: var_EC.start, 
        end: var_EC.end, 
        duration: var_EC.duration
      },
      PumpFlow: { 
        start: PumpFlow.start, 
        end: PumpFlow.end, 
        duration: PumpFlow.duration
      },
      power: { 
        start: power.start, 
        end: power.end, 
        duration: power.duration
      },
    }
  }

  refresh = () => {
    // this.setState({title: 'company'});
    // console.log('this.state 1 -->', this.state)
    //+-console.log('props -->', this.props)
    let users = this.state.user
    if(!Object.entries(users).length) return this.goto401()
    if(this.props.location.state === undefined) return this.goto401()

    if(!(Object.entries(users).length === 0)){
      if(!(Object.entries(this.props.location.state).length === 0)){
        let THIS = this
        let organization = this.props.location.state.organization
        let id = this.props.location.state.id
        let device = [{label:'null',value:'null'}]
        let devices = []
        let defaultFileUpload = this.state.defaultFileUpload 
        defaultFileUpload['organization'] = organization 
        this.setState({
          defaultFileUpload : defaultFileUpload,
          isButtonLoading : false
        })
        axios({
          method: 'get',
          url: window.url + 'api/v1/collection/' + organization + '/branch/' + id,
          data: 'values',
          headers:{
            "Authorization": users.token
          }
        })
        .then(function (response) {
          let dataResponse = response.data.value
          if(dataResponse){
            if(dataResponse.deviceID){
              let changedeviceID = dataResponse.deviceID
              dataResponse.deviceID = {label:changedeviceID,value:changedeviceID}
            }
          }
          //console.log('dataResponse =>',dataResponse)
          axios({
              method: 'get',
              url: window.url + 'api/v1/collection/' + organization + '/device',
              data: 'values',
              headers:{
                "Authorization": users.token
              }
            })
          .then(function (response1) {
            //console.log("response1 -->",response1.data);
            if(response1.data.value){
              devices = response1.data.value
              response1.data.value.forEach(element => {
                if( (element.deviceID)  && ( (element.branchID === undefined) || (element.branchID === null) || (element.branchID === 'null') || (element.branchID == '') ) ){
                  let tmpObj = {
                    label : element.deviceID,
                    value : element.deviceID
                  }    
                  device.push(tmpObj)
                }
              });
            }
            //console.log("device =>",device)
            //THIS.setState({products:response.data.value[0][5]})
            let projectItems = []
            for(let i=0; i<THIS.state.user.project.length; i++) {      
              projectItems.push(THIS.state.user.project[i].projectName)
            }

            let organizItems = []
            for(let i=0; i<THIS.state.user.organization.length; i++) {      
              organizItems.push(THIS.state.user.organization[i])
            }
            let btnSaveEnable = THIS.state.btnSaveEnable 
            if(response.data.value.status === 'synchronize'){
              btnSaveEnable = false  
            }  
            //(response.data.value.status=='synchronize') ? THIS.state.btnSaveEnable=false : THIS.state.btnSaveEnable=true
            
            // //sort product by name 
            // let p = response.data.value.products
            // p.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)); 

            //sort product by name 
            let p = response.data.value.products
            p.sort((a,b) => ((a.channel*1) > (b.channel*1)) ? 1 : (((b.channel*1) > (a.channel*1)) ? -1 : 0)); 

            let pumpout = THIS.state.pumpout
            if(response.data.value.sensor){
              pumpout.duration = response.data.value.sensor.pumpout
            }

            let var_PH = THIS.state.var_PH
            if(response.data.value.sensor){
              var_PH.duration = response.data.value.sensor.var_PH
            }

            let var_ORP = THIS.state.var_ORP
            if(response.data.value.sensor){
              var_ORP.duration = response.data.value.sensor.var_ORP
            }

            let var_EC = THIS.state.var_EC
            if(response.data.value.sensor){
              var_EC.duration = response.data.value.sensor.var_EC
            }

            let PumpFlow = THIS.state.PumpFlow
            if(response.data.value.sensor){
              PumpFlow.duration = (response.data.value.sensor.PumpP_Lmin*1) + (response.data.value.sensor.PumpN_Lmin*1)
            }

            let power = THIS.state.power
            if(response.data.value.sensor){
              power.duration = (response.data.value.sensor.watt_1*1) + (response.data.value.sensor.watt_2*1)
            }
            //console.log('power =>',power.duration)
            //console.log('PumpFlow =>',PumpFlow.duration)
 
            THIS.setState({
              dataResponse:dataResponse,
              organizItems:organizItems, 
              projectItems:projectItems,
              btnSaveEnable: btnSaveEnable,
              btnBackupEnable: true,
              device: device,
              devices: devices,
              isButtonLoading: true,
              pumpout : pumpout,
              var_PH : var_PH,
              var_ORP : var_ORP,
              var_EC : var_EC,
              PumpFlow : PumpFlow,
              power : power
            })
            // console.log("btnSaveEnable ========>", THIS.state.btnSaveEnable, response.data.value.status);
            let products = []
            if(response.data.value.products !== undefined ){
              products = response.data.value.products
            }
            let paginationActive = THIS.state.paginationActive
            THIS.paginationItemCal(products,paginationActive)
            if( (dataResponse.type !== null) &&  ( (dataResponse.type === 'HVM2S') ||  (dataResponse.type === 'HVM8S') ) ){
              if( (!THIS.state.btnSaveEnable) && (!THIS.state.btnCalibrateEnable) ){
                THIS.sendApiRealtime(dataResponse)
              }else{
                THIS.setState({
                  realtimeEnable : false
                })
              }
            }
          })
          .catch(function (error) {
            THIS.setState({
              alertShow: true,
              alertText: error.message
            })
            console.log('axios error =>',error.message);
          });
        })
        .catch(function (error) {
          THIS.setState({
            alertShow: true,
            alertText: error.message
          })
          console.log('axios error =>',error.message);
        });
      }
    }
  }

  sendApiRealtime = (dataIn) => {
    let users = this.state.user
    if(JSON.stringify(users) !== '{}'){
      if(JSON.stringify(this.props.location.state) !== '{}'){
        if(dataIn.topic != null){
          let values ={
            "topic" : dataIn.topic,
            "msg" : {"cmd" : "GetSensor"}
          }
          console.log('sendApiRealtime value =>',values)
          let THIS =this
          axios({
            method: 'post',
            url: window.url + 'api/v1/mqtt/publish',
            data: values,
            headers:{
              "Authorization": users.token
            }
          })
          .then(function (response) {
            console.log('response =>',response.data)
            //console.log('THIS.rtTimeout =>',THIS.rtTimeout)
            if(THIS.rtTimeout != null){
              clearTimeout(THIS.rtTimeout)
            }
            let timeoutGetRealtime = 10000      //10 sec
            //console.log('timeoutGetRealtime =>',window.configVending.timeoutGetRealtime)
            if(window.configVending.timeoutGetRealtime){
              timeoutGetRealtime = window.configVending.timeoutGetRealtime * 1000
            }

            THIS.rtTimeout = setTimeout(() => {    
              if( (!THIS.state.btnSaveEnable) && (!THIS.state.btnCalibrateEnable) && (!THIS.state.modalSmallShow) && (!THIS.state.modalAddbranchShow) ){  
                THIS.refresh()
              }else{
                THIS.setState({
                  realtimeEnable : false
                })
              }
            }, timeoutGetRealtime); 
          })
          .catch(function (error) {
            console.log('api/v1/mqtt/publish =>',error);
          });  
        }
      }
    }
  }


  componentWillUnmount(){    
    if(this.rtTimeout != null){
      clearTimeout(this.rtTimeout)
    }      
    //console.log("componentWillUnmount......", this.rtTimeout);
  }

  componentDidMount() {
    let users = this.state.user
    if(JSON.stringify(users) !== '{}'){
      // console.log('users =>',users)
      //console.log('state =>',this.props.location)
      //if(JSON.stringify(this.props.location.state) !== '{}'){
      if(this.props.location.state !== undefined){
        let organization = this.props.location.state.organization

        if(this.props.location.pageFrom !== undefined){
          let location = {
            pathname: this.props.location.pageFrom,
            state: {}
          }
          this.setState({
            location
          })
        }

        let values = {
          "query" : { 
            "branchID": this.props.location.state.branchID
          },
          "sortNatural": "-1",
          "countSkip":"0",
          "countLimit": "0",
          "projection":{"name":1, "_id":1}
        }
        let THIS = this
        axios({
          method: 'post',
          url: window.url + 'api/v1/collection/' + organization + '/branch_backup/query',
          data: values,
          headers:{
            "Authorization": users.token
          }
        })
        .then(function (response) {
          //console.log('response =>',response)
          if(response.data.value.resultCount != 0){
            if(response.data.value.resultCount < 5){
              if(response.data.value.result)
              THIS.setState({
                listRestore : response.data.value.result,
                btnRestoreEnable : true
              })
            }else{
              let listRestore = []
              for(let i = 0; i < 5; i++){
                listRestore.push(response.data.value.result[i])
              }
              THIS.setState({
                listRestore : listRestore,
                btnRestoreEnable : true
              }) 

              let id  = response.data.value.result[response.data.value.resultCount-1]._id
              axios({
                method: "delete",
                url:window.url + "api/v1/collection/" + organization + "/" + "branch_backup" + "/" + id,
                data: { user: users },
                headers: {
                  Authorization: users.token
                }
              })   
              .then(function(response) {
                console.log('delete response =>',response)
              })
              .catch(function (error) {
                console.log(error);
                THIS.hideAlert()
              });  

            }
          }else{
            THIS.setState({
              btnRestoreEnable :false
            })
          }
          THIS.refresh()
        })
        .catch(function (error) {
          console.log(error);
          THIS.hideAlert()
        });  
      }
    }
  }

  handleClick(e) {
    //+-console.log('this ->', e)
  }

  hideAlert() {
    this.setState(state => ({
      alert: null
    }));
  }
  
  alert(e) {
    // console.log(e)
    switch(e.action) {
      case 'delete':
        this.setState( state => ({
          alert: (
            <SweetAlert
              warning
              showCancel
              confirmBtnText="Yes, delete it!"
              confirmBtnBsStyle="danger"
              cancelBtnBsStyle="default"
              title="Are you sure?"
              onConfirm={()=> {this.delete(e)} } //{this.deleteFile}
              onCancel={ this.hideAlert.bind(this) }
              >
              Delete "{e.name}"
            </SweetAlert>
          )
        }));
        break
      case 'save':
        this.setState( state => ({
          alert: (
            <SweetAlert
              warning
              showCancel
              confirmBtnText="Yes, Save it!"
              confirmBtnBsStyle="warning"
              cancelBtnBsStyle="default"
              title="Are you sure?"
              onConfirm={()=> {this.saveConfig()} } //{this.deleteFile}
              onCancel={ this.hideAlert.bind(this) }
              >
              Save config vending "{e.fullname}"
            </SweetAlert>
          )
        }));
      break
      case 'deploy':
        this.setState( state => ({
          alert: (
            <SweetAlert
              warning
              showCancel
              confirmBtnText="Yes, Deploy it!"
              confirmBtnBsStyle="warning"
              cancelBtnBsStyle="default"
              title="Are you sure?"
              onConfirm={()=> {this.saveConfigAndDeploy()} } //{this.deleteFile}
              onCancel={ this.hideAlert.bind(this) }
              >
              Deploy config vending "{e.fullname}"
            </SweetAlert>
          )
        }));
      break
      case 'restart':
        this.setState( state => ({
          alert: (
            <SweetAlert
              danger
              showCancel
              confirmBtnText="Yes, Restar now"
              confirmBtnBsStyle="danger"
              cancelBtnBsStyle="default"
              title="Are you sure?"
              onConfirm={()=> {this.restartDeploy()} } //{this.deleteFile}
              onCancel={ this.hideAlert.bind(this) }
              >
              Restart vending "{e.fullname}"
            </SweetAlert>
          )
        }));
      break
      case 'calibrate':
        this.setState( state => ({
          alert: (
            <SweetAlert
              warning
              showCancel
              confirmBtnText="Yes, Calibrate it!"
              confirmBtnBsStyle="warning"
              cancelBtnBsStyle="default"
              title="Are you sure?"
              onConfirm={()=> {this.saveConfigCalibrate()} } //{this.deleteFile}
              onCancel={ this.hideAlert.bind(this) }
              >
              Calibrate config vending "{e.fullname}"
            </SweetAlert>
          )
        }));
      break
      default:
      break
    }
    
  } 
  
  delete(dataIn) {
    //+-console.log('delete =>', dataIn)
    let dataSave = this.state.dataResponse.products
    for(var i = 0; i < dataSave.length; i++){
      if(dataSave[i]._id === dataIn.id){
        dataSave.splice(i,1)
        i--
      }
    }
    let THIS = this
    let id = this.state.dataResponse._id
    let values = {
      products:dataSave,
      status:'Delete Product',
      createBy:this.state.user.username,
      updateBy:this.state.user.username
    }
    axios({
      method: 'put',
      url: window.url + 'api/v1/collection/' + this.state.user.OrganizationSelect + '/branch/'+ id,
      // url: 'http://13.251.52.58:3001/api/v1/users/' + this.state.user.organization,
      data: values,
      headers:{
        "Authorization": this.state.user.token
      }
    })
    .then(function (response) {
      //+-console.log("Add branch response =>", response.data.value); // {n: 1, nModified: 1, ok: 1}
      //THIS.setState({dataResponse:response.data.value})
      // let branchAdd = response.data.value[0]
      // THIS.state.branchsPage.unshift(branchAdd)
      // THIS.setState({usersPage: THIS.state.branchsPage})
      let products = THIS.state.dataResponse.products
      let paginationActive = THIS.state.paginationActive
      THIS.paginationItemCal(products,paginationActive)
      let dataSave = {
        "type" : 'warning', //information //warning // error
        "date" : new Date(),
        "surce" : 'branchSetting',
        "category" : 'webApp',
        "even" : values.status,
        "user" :  THIS.state.user.username,
        "ip" : "",
        "discription" : 'Delete product =>'+ dataIn.name + ' in branch'
      }
      THIS.saveLog(dataSave)

      THIS.hideAlert()
    })
    .catch(function (error) {
      console.log(error);
      THIS.hideAlert()
    });    
  }
  
  // paginationActive(page) {
  //   this.setState(state => ({
  //     paginationActive: page
  //   }));

  //   this.usersPage(page)
  // }


  paginationActive(e) {
    let dataShow = this.state.dataResponse.products
    this.paginationItemCal(dataShow,e)
  }

  paginationItemCal(dataIn,paginationActive) {
    //console.log("pageCount ->",dataIn.length,"paginationActive ->",paginationActive)
    let page = this.state.countPerPage
    let pageCount = Math.ceil(dataIn.length / page)
    let tmppaginationActive = this.state.paginationActive 
    let paginationItem = []

    switch (paginationActive){
      case '>':
          tmppaginationActive++;
      break;
      case '<':
          tmppaginationActive--;
      break;
      case '>|':
          tmppaginationActive = pageCount;
      break;
      case '|<':
          tmppaginationActive  = 1;
      break;
      default:
          tmppaginationActive = paginationActive*1
      break;
    }

    if(tmppaginationActive > pageCount){
      tmppaginationActive = pageCount;
    }

    if(pageCount < 6){
      for(let i = 1; i <= pageCount; i++){
          paginationItem.push(i)
      }
    }else{
      if(tmppaginationActive < 4){
        for(let i = 1; i <= pageCount; i++){
          if(i < 6){
            paginationItem.push(i)
          }else if(i === 6){
            paginationItem.push(">")
          }else if(i === 7){
            paginationItem.push(">|")
          }else{
            break;
          }  
        }
      }else if(tmppaginationActive > (pageCount-3)){
        for(let i = 1; i < 8; i++){
          if(i > 2){
            paginationItem.push((pageCount - (7-i)))
          }else if(i === 2){
            paginationItem.push("<")
          }else if(i === 1){
            paginationItem.push("|<")
          }else{
            break;
          }  
        }  
      }else{
        for(let i = 1; i < 10; i++){
          switch(i){
            case 1:
              paginationItem.push("|<")
            break;
            case 2:
              paginationItem.push("<")
            break;
            case 3:
                paginationItem.push(tmppaginationActive-2)
            break;
            case 4:
                paginationItem.push(tmppaginationActive-1)
            break;
            case 5:
              paginationItem.push(tmppaginationActive)
            break;
            case 6:
                paginationItem.push(tmppaginationActive+1)
            break;
            case 7:
              paginationItem.push(tmppaginationActive+2)
            break;
            case 8:
                paginationItem.push(">")
            break;
            case 9:
                paginationItem.push(">|")
            break;
            default:
              paginationItem.push(tmppaginationActive)  
            break
          }
        }  
      }
    }

    let dataShow = []
    if(dataIn.length){
      for(let i = page * (tmppaginationActive -1); ((i < page*tmppaginationActive) &&  (i < dataIn.length)); i++){
        dataShow.push(dataIn[i])
      }
    }

    this.setState({
      dataShow: dataShow,
      paginationItem: paginationItem,
      paginationActive: tmppaginationActive
    })
  }
  
  modalClose = () => {
    // this.setState({modalWorning: ""})
    this.setState({ modalWorning: "", modalAddbranchShow: false })
    // console.log('this.state.modalForm =>', this.state.modalForm)
  };

  modalSmallClose = () => {
    // this.setState({modalWorning: ""})
    this.setState({ modalWorning: "", modalSmallShow: false })
    // console.log('this.state.modalForm =>', this.state.modalForm)
  };


  getForm = () => {
    const uuidv1 = require('uuid/v1');
    //console.log('uuidv1 ->',uuidv1());
    let modalForm = this.state.modalForm 
    //+-console.log('getForm modalForm =>', modalForm);    
    let dataEdit = this.state.tempModalForm
    for(let k=0; k<modalForm.length; k++) {
      if(modalForm[k].value === '') {
        return this.setState({modalWorning: "Input is blank!"})
      }
      dataEdit[modalForm[k].key] = modalForm[k].value
      if(modalForm[k].key === 'image'){
        dataEdit['path']= modalForm[k].path 
      }
    }

    let dataSave = []   
    if(this.state.dataResponse.products !== undefined){
      dataSave = this.state.dataResponse.products
    }
    for(var i = 0; i < dataSave.length; i++){
      if(dataSave[i]._id === dataEdit._id){
        for(let j = 0; j < modalForm.length; j++){
          dataSave[i][modalForm[j].key] = modalForm[j].value
          if(modalForm[j].key === 'image'){
            dataSave[i]['path']= modalForm[j].path 
          }
        }
        dataSave[i].createBy = this.state.user.username
        dataSave[i].updateBy = this.state.user.username
        break;
      }
    }
    if(i >= dataSave.length){
      dataEdit._id = uuidv1()
      dataEdit.createBy = this.state.user.username
      dataEdit.updateBy = this.state.user.username
      dataSave.push(dataEdit)
      //--------------------------cerren last page
      let products = []
      if(this.state.dataResponse.products !== undefined){
        products = this.state.dataResponse.products
      }
      let page = this.state.countPerPage
      let pageCount = Math.ceil(products.length / page)
      this.setState({
        paginationActive: pageCount
      })
    }
    //+-console.log('getForm data =>', dataSave);
    //this.modalClose()
    //return
    let THIS = this
    let id = this.state.dataResponse._id
    let values = {
      products:dataSave,
      status:dataEdit.status,
      createBy:dataEdit.createBy,
      updateBy:dataEdit.updateBy
    }
    //+-console.log('values =>',values)
    axios({
      method: 'put',
      url: window.url + 'api/v1/collection/' + this.state.user.OrganizationSelect + '/branch/'+ id,
      // url: 'http://13.251.52.58:3001/api/v1/users/' + this.state.user.organization,
      data: values,
      headers:{
        "Authorization": this.state.user.token
      }
    })
    .then(function (response) {
      //+-console.log("Add branch response =>", response.data.value); // {n: 1, nModified: 1, ok: 1}
      let discription = ''
      let status = ''
      if( values.status == 'New product'){
        discription = 'Add new product => '+ dataEdit.name + ' in branch'
        status = values.status
      }else{
        discription = 'Edit product => '+ dataEdit.name + ' in branch'
        status = 'Edit product'
      }
      
      let dataSave = {
        "type" : 'information', //information //warning // error
        "date" : new Date(),
        "surce" : 'branchSetting',
        "category" : 'webApp',
        "even" : status,
        "user" :  THIS.state.user.username,
        "ip" : "",
        "discription" : discription
      }
      THIS.saveLog(dataSave)

      let products = []
      if(THIS.state.dataResponse.products !== undefined ){
        products = THIS.state.dataResponse.products
      }else{
        products.push(dataSave) 
      }
      let paginationActive = THIS.state.paginationActive
      THIS.paginationItemCal(products,paginationActive)
      THIS.modalClose()
    })
    .catch(function (error) {
      console.log(error);
      THIS.setState({modalWorning: "Add product error"})
    });

  }

  saveConfigAndDeploy = () => {
    let dataSave = this.state.dataResponse
    let THIS = this
    let id = this.state.dataResponse._id
    let values = JSON.parse(JSON.stringify(dataSave));
    let deviceSave = []
    let device = this.state.device
    let changedeviceID = values.deviceID
    if(changedeviceID && changedeviceID.value !== undefined){
      values.deviceID = changedeviceID.value
    }

    THIS.state.btnSaveClick = true
    THIS.setState({btnSaveClick: THIS.state.btnSaveClick})

      values['status'] = 'saveConfig'
      values['createBy'] = this.state.user.username
      values['updateBy'] = this.state.user.username
      delete values['_id']
      //console.log('values =>',values);  
    axios({
      method: 'put',
      url: window.url + 'api/v1/collection/' + this.state.user.OrganizationSelect + '/branch/'+ id,
      // url: 'http://13.251.52.58:3001/api/v1/users/' + this.state.user.organization,
      data: values,
      headers:{
        "Authorization": this.state.user.token
      }
    })
    .then(function (response) {
      console.log("update branch response =>", response.data.value); // {n: 1, nModified: 1, ok: 1}
      if(values.deviceID == 'null'){
        let devices =THIS.state.devices
        for(let i = 0; i < devices.length; i++){
          if(devices[i].branchID == values.branchID){
            let tmpDevice = devices[i]
            tmpDevice.branchID = null
            deviceSave.push(tmpDevice)
            device.push({label:devices[i].deviceID,value:devices[i].deviceID})
            break
          }
        }
      }else if (values.deviceID){
        let devices =THIS.state.devices
        for(let i = 0; i < devices.length; i++){
          if(devices[i].deviceID == values.deviceID){
            let tmpDevice = devices[i]
            tmpDevice.branchID = values.branchID
            deviceSave.push(tmpDevice)
          }else if(devices[i].branchID == values.branchID){
            let tmpDevice = devices[i]
            tmpDevice.branchID = null
            deviceSave.push(tmpDevice)
            device.push({label:devices[i].deviceID,value:devices[i].deviceID})
          }
        }
      }
      //console.log('device =>',device)
      //console.log('deviceSave =>',deviceSave)
      if(deviceSave.length){
        THIS.setState({
          device : device
        })
        // let id = deviceSave._id
        // delete values._id
        axios({
          method: 'patch',
          url: window.url + 'api/v1/collection/' + THIS.state.user.OrganizationSelect + '/device',
          // url: 'http://13.251.52.58:3001/api/v1/users/' + this.state.user.organization,
          data: deviceSave,
          headers:{
            "Authorization": THIS.state.user.token
          }
        })
        .then(function (response1) {
          console.log("update device response =>", response1.data.value); // {n: 1, nModified: 1, ok: 1}
        })
        .catch(function (error) {
          console.log(error);
          THIS.hideAlert()
          //THIS.state.btnSaveClick = false
          THIS.setState({btnSaveClick: false})
        }); 
      }


      let dataSave = {
        "type" : 'information', //information //warning // error
        "date" : new Date(),
        "surce" : 'branchSetting',
        "category" : 'webApp',
        "even" : values.status,
        "user" :  THIS.state.user.username,
        "ip" : "",
        "discription" : 'Save config complete => '+ values.branch
      }
      THIS.saveLog(dataSave)

      let products = THIS.state.dataResponse.products
      let paginationActive = THIS.state.paginationActive
      THIS.paginationItemCal(products,paginationActive)
      THIS.hideAlert()

      let data = {
        name: values.branch,
        type: 'product',
        status: 'Synchronize',
        branchID: values.branchID,
        deployID: id,
        updateBy: THIS.state.user.username,
        deployTime: (new Date()).getTime(),
        vendingType: values.type
      }

      axios({
        method: 'post',
        url: window.url + 'api/v1/collection/' + THIS.state.user.OrganizationSelect + '/deploy', 
        data: data,
        headers:{
          "Authorization": THIS.state.user.token
        }
      })
      .then(function (response) {
        // console.log("Insert deploy response =>", response); 
        //THIS.state.btnSaveClick = false
        THIS.setState({btnSaveClick: false})
      })
      .catch(function (error) {
        console.log('error ->', error);
        THIS.setState({modalWorning: "Insert deploy error!"})
      })

    })
    .catch(function (error) {
      console.log(error);
      THIS.hideAlert()
      //THIS.state.btnSaveClick = false
      THIS.setState({btnSaveClick: false})
    });    
  }

  saveConfigCalibrate = () => {
    let dataSave = this.state.dataResponse
    let THIS = this
    let id = this.state.dataResponse._id
    let values = JSON.parse(JSON.stringify(dataSave));
    let deviceSave = []
    let device = this.state.device
    let changedeviceID = values.deviceID
    if(changedeviceID && changedeviceID.value !== undefined){
      values.deviceID = changedeviceID.value
    }

    THIS.setState({btnCalibrateClick : true})

      values['status'] = 'mqttConfig'
      values['createBy'] = this.state.user.username
      values['updateBy'] = this.state.user.username
      delete values['_id']
      //console.log('saveConfig =>',dataSave);  
    axios({
      method: 'put',
      url: window.url + 'api/v1/collection/' + this.state.user.OrganizationSelect + '/branch/'+ id,
      // url: 'http://13.251.52.58:3001/api/v1/users/' + this.state.user.organization,
      data: values,
      headers:{
        "Authorization": this.state.user.token
      }
    })
    .then(function (response) {
      //console.log("update branch response =>", response.data.value); // {n: 1, nModified: 1, ok: 1}
      if(values.deviceID == 'null'){
        let devices =THIS.state.devices
        for(let i = 0; i < devices.length; i++){
          if(devices[i].branchID == values.branchID){
            let tmpDevice = devices[i]
            tmpDevice.branchID = null
            deviceSave.push(tmpDevice)
            device.push({label:devices[i].deviceID,value:devices[i].deviceID})
            break
          }
        }
      }else if (values.deviceID){
        let devices =THIS.state.devices
        for(let i = 0; i < devices.length; i++){
          if(devices[i].deviceID == values.deviceID){
            let tmpDevice = devices[i]
            tmpDevice.branchID = values.branchID
            deviceSave.push(tmpDevice)
          }else if(devices[i].branchID == values.branchID){
            let tmpDevice = devices[i]
            tmpDevice.branchID = null
            deviceSave.push(tmpDevice)
            device.push({label:devices[i].deviceID,value:devices[i].deviceID})
          }
        }
      }
      //console.log('device =>',device)
      //console.log('deviceSave =>',deviceSave)
      if(deviceSave.length){
        THIS.setState({
          device : device
        })
        // let id = deviceSave._id
        // delete values._id
        axios({
          method: 'patch',
          url: window.url + 'api/v1/collection/' + THIS.state.user.OrganizationSelect + '/device',
          // url: 'http://13.251.52.58:3001/api/v1/users/' + this.state.user.organization,
          data: deviceSave,
          headers:{
            "Authorization": THIS.state.user.token
          }
        })
        .then(function (response1) {
          console.log("update device response =>", response1.data.value); // {n: 1, nModified: 1, ok: 1}
        })
        .catch(function (error) {
          console.log(error);
        }); 
      }


      let dataSave = {
        "type" : 'information', //information //warning // error
        "date" : new Date(),
        "surce" : 'branchSetting',
        "category" : 'webApp',
        "even" : values.status,
        "user" :  THIS.state.user.username,
        "ip" : "",
        "discription" : 'Save config complete => '+ values.branch
      }
      THIS.saveLog(dataSave)

      let products = THIS.state.dataResponse.products
      let paginationActive = THIS.state.paginationActive
      THIS.paginationItemCal(products,paginationActive)
      THIS.hideAlert()

      let data = {
        name: values.branch,
        type: 'mqttConfig',
        status: 'Synchronize',
        branchID: values.branchID,
        deployID: id,
        updateBy: THIS.state.user.username,
        deployTime: (new Date()).getTime(),
        vendingType: values.type
      }

      axios({
        method: 'post',
        url: window.url + 'api/v1/collection/' + THIS.state.user.OrganizationSelect + '/deploy', 
        data: data,
        headers:{
          "Authorization": THIS.state.user.token
        }
      })
      .then(function (response) {
        // console.log("Insert deploy response =>", response); 
        THIS.setState({btnCalibrateClick : false})
      })
      .catch(function (error) {
        console.log('error ->', error);
        THIS.setState({modalWorning: "Insert deploy error!"})
        THIS.setState({btnCalibrateClick : false})
      })

    })
    .catch(function (error) {
      console.log(error);
      THIS.hideAlert()
      THIS.setState({btnCalibrateClick : false})
    });    
  }

  saveConfig = () => {
    let dataSave = this.state.dataResponse
    let THIS = this
    let id = this.state.dataResponse._id
    let values = JSON.parse(JSON.stringify(dataSave));
    let deviceSave = []
    let device = this.state.device
    let changedeviceID = values.deviceID
    if(changedeviceID && changedeviceID.value !== undefined){
      values.deviceID = changedeviceID.value
    }

    THIS.setState({btnSaveClick: true})

      values['status'] = 'saveConfig'
      values['createBy'] = this.state.user.username
      values['updateBy'] = this.state.user.username
      delete values['_id']
      //console.log('saveConfig values =>',values);  
    axios({
      method: 'put',
      url: window.url + 'api/v1/collection/' + this.state.user.OrganizationSelect + '/branch/'+ id,
      // url: 'http://13.251.52.58:3001/api/v1/users/' + this.state.user.organization,
      data: values,
      headers:{
        "Authorization": this.state.user.token
      }
    })
    .then(function (response) {
      //console.log("update branch response =>", response.data.value); // {n: 1, nModified: 1, ok: 1}
      if(values.deviceID == 'null'){
        let devices =THIS.state.devices
        for(let i = 0; i < devices.length; i++){
          if(devices[i].branchID == values.branchID){
            let tmpDevice = devices[i]
            tmpDevice.branchID = null
            deviceSave.push(tmpDevice)
            device.push({label:devices[i].deviceID,value:devices[i].deviceID})
            break
          }
        }
      }else if (values.deviceID){
        let devices =THIS.state.devices
        for(let i = 0; i < devices.length; i++){
          if(devices[i].deviceID == values.deviceID){
            let tmpDevice = devices[i]
            tmpDevice.branchID = values.branchID
            deviceSave.push(tmpDevice)
          }else if(devices[i].branchID == values.branchID){
            let tmpDevice = devices[i]
            tmpDevice.branchID = null
            deviceSave.push(tmpDevice)
            device.push({label:devices[i].deviceID,value:devices[i].deviceID})
          }
        }
      }
      //console.log('device =>',device)
      //console.log('deviceSave =>',deviceSave)
      if(deviceSave.length){
        THIS.setState({
          device : device
        })
        // let id = deviceSave._id
        // delete values._id
        axios({
          method: 'patch',
          url: window.url + 'api/v1/collection/' + THIS.state.user.OrganizationSelect + '/device',
          // url: 'http://13.251.52.58:3001/api/v1/users/' + this.state.user.organization,
          data: deviceSave,
          headers:{
            "Authorization": THIS.state.user.token
          }
        })
        .then(function (response1) {
          console.log("update device response =>", response1.data.value); // {n: 1, nModified: 1, ok: 1}
        })
        .catch(function (error) {
          console.log(error);
          THIS.hideAlert()
          //THIS.state.btnSaveClick = false
          THIS.setState({btnSaveClick: false})
        }); 
      }

      THIS.setState({btnSaveClick: false})
      
      let dataSave = {
        "type" : 'information', //information //warning // error
        "date" : new Date(),
        "surce" : 'branchSetting',
        "category" : 'webApp',
        "even" : values.status,
        "user" :  THIS.state.user.username,
        "ip" : "",
        "discription" : 'Save config complete => '+ values.branch
      }
      THIS.saveLog(dataSave)

      let products = THIS.state.dataResponse.products
      let paginationActive = THIS.state.paginationActive
      THIS.paginationItemCal(products,paginationActive)
      THIS.hideAlert()

      // let data = {
      //   name: values.branch,
      //   type: 'product',
      //   status: 'Synchronize',
      //   branchID: values.branchID,
      //   deployID: id,
      //   updateBy: THIS.state.user.username,
      //   deployTime: (new Date()).getTime(),
      //   vendingType: values.type
      // }

      // axios({
      //   method: 'post',
      //   url: window.url + 'api/v1/collection/' + THIS.state.user.OrganizationSelect + '/deploy', 
      //   data: data,
      //   headers:{
      //     "Authorization": THIS.state.user.token
      //   }
      // })
      // .then(function (response) {
      //   // console.log("Insert deploy response =>", response); 
      //   //THIS.state.btnSaveClick = false
      //   THIS.setState({btnSaveClick: false})
      // })
      // .catch(function (error) {
      //   console.log('error ->', error);
      //   THIS.setState({modalWorning: "Insert deploy error!"})
      // })

    })
    .catch(function (error) {
      console.log(error);
      THIS.hideAlert()
      //THIS.state.btnSaveClick = false
      THIS.setState({btnSaveClick: false})
    });    
  }

  restartDeploy = () => {
    const THIS = this
    const branch = this.state.dataResponse
    const data = {
      name: branch.branch,
      type: 'restart',
      status: 'Synchronize',
      branchID: branch.branchID,
      deployID: branch._id,
      updateBy: THIS.state.user.username,
      deployTime: (new Date()).getTime(),
      vendingType: branch.type
    }

    axios({
      method: 'post',
      url: window.url + 'api/v1/collection/' + THIS.state.user.OrganizationSelect + '/deploy', 
      data: data,
      headers:{
        "Authorization": THIS.state.user.token
      }
    })
    .then(function (response) {
      // console.log("Insert deploy response =>", response); 
      // THIS.setState({btnCalibrateClick : false})
      THIS.hideAlert()
    })
    .catch(function (error) {
      console.log('error ->', error);
      THIS.setState({modalWorning: "Insert deploy error!"})
      // THIS.setState({btnCalibrateClick : false})
      THIS.hideAlert()
    })
  }

  restore = () => {
    //console.log("backup =>",this.state.dataResponse)
    let users = this.state.user
    if(JSON.stringify(users) !== '{}'){
      //console.log('this.props.location.state =>',this.props.location.state)
      if(JSON.stringify(this.props.location.state) !== '{}'){
        if(this.state.listRestore.length){
          let items = []
          for(let i = 0; i < this.state.listRestore.length; i++){
            items.push(this.state.listRestore[i].name)
          }
          let modalFormTitle = "Restore"
          let modalForm = [
            { key: "name", name: "Name", placeholder:"", value: items[0], items: items, disabled: window.modalFormImageConfig.modifyProductVending.disableName, type: 'select' }, 
          ]
          this.hideAlert()
          this.setState({
            modalForm: modalForm,
            modalSmallShow: true,
            modalFormTitle: modalFormTitle
          });
        }
      }
    }
  }

  backup = () => {
    //console.log("backup =>",this.state.dataResponse)
    let users = this.state.user
    if(JSON.stringify(users) !== '{}'){
      //console.log('this.props.location.state =>',this.props.location.state)
      if(JSON.stringify(this.props.location.state) !== '{}'){
        let tempModalForm = {
          name : "backup_"+new Date().toISOString().replace(/T/, '_').replace(/-/g, '_').replace(/:/g, '_').replace(/\..+/, ''),
        }
        let modalFormTitle = "Backup"
        let modalForm = [
          { key: "name", name: "Name", placeholder:"", value: tempModalForm.name, disabled: window.modalFormImageConfig.modifyProductVending.disableName, type: 'input' }, 
        ]
        this.hideAlert()
        this.setState({
          modalForm: modalForm,
          modalSmallShow: true,
          modalFormTitle: modalFormTitle,
          tempModalForm: tempModalForm
        });
      }
    }
  }

  saveBackup = () => {
    let users = this.state.user
    if(JSON.stringify(users) !== '{}'){
      //console.log('this.props.location.state =>',this.props.location.state)
      if(JSON.stringify(this.props.location.state) !== '{}'){
      //if(!(Object.entries(this.props.location.state).length === 0)){
        if(this.state.modalFormTitle == 'Backup'){
          let modalForm = this.state.modalForm 
          //+-console.log('getForm modalForm =>', modalForm);    
          let dataEdit = this.state.tempModalForm
          for(let k=0; k<modalForm.length; k++) {
            if(modalForm[k].value === '') {
              return this.setState({modalWorning: "Input is blank!"})
            }
            dataEdit[modalForm[k].key] = modalForm[k].value
          }
          //console.log('dataEdit =>',dataEdit)
          let listRestore = this.state.listRestore
          for(let i = 0; i < listRestore.length; i++){
            if(listRestore[i].name === dataEdit.name){
              return this.setState({ modalWorning: "name "+dataEdit.name+" is duplicate!" });
            }
          }

          this.setState({btnBackupClick: true})
          let THIS = this
          let organization = this.props.location.state.organization
          let id = this.props.location.state.id
          axios({
            method: 'get',
            url: window.url + 'api/v1/collection/' + organization + '/branch/' + id,
            data: 'values',
            headers:{
              "Authorization": users.token
            }
          })
          .then(function (response) {
            let dataBackup = response.data.value
            //console.log('dataBackup =>',dataBackup)
            if(dataBackup){
              dataEdit.dataBackup = dataBackup
              dataEdit.branchID = dataBackup.branchID
              dataEdit.branch = dataBackup.branch
              dataEdit.createBy = users.username
              dataEdit.createDate = new Date()
              //console.log('dataBackup =>',dataEdit)
              // console.log("ok")
              axios({
                method: 'post',
                url: window.url + 'api/v1/collection/' + organization + '/branch_backup',
                data: dataEdit,
                headers:{
                  "Authorization": users.token
                }
              })
              .then(function (response) {
                //console.log('response =>',response)
                if(response.data.value.length){
                  let dataRes = response.data.value[0]
                  let tpmListRestore = {
                    name : dataRes.name,
                    _id : dataRes._id
                  }
                  if(listRestore.length < 5){
                    listRestore.unshift(tpmListRestore)
                  }else{
                    listRestore.unshift(tpmListRestore)
                    listRestore.pop()
                  }
                  console.log('listRestore =>',listRestore)
                  THIS.setState({
                    listRestore : listRestore,
                    btnBackupClick : false
                  })
                }
                THIS.modalSmallClose()
              })
              .catch(function (error) {
                THIS.modalSmallClose()
                console.log(error);
                THIS.setState({btnBackupClick: false})
              }); 
            }else{
              THIS.setState({btnBackupClick: false})
              THIS.modalSmallClose()
            }
          })
          .catch(function (error) {
            console.log(error);
            THIS.setState({btnBackupClick: false})
            THIS.modalSmallClose()
          }); 
        }else if(this.state.modalFormTitle === 'Restore'){
          let modalForm = this.state.modalForm 
          let dataEdit = this.state.tempModalForm
          for(let k=0; k<modalForm.length; k++) {
            if(modalForm[k].value === '') {
              return this.setState({modalWorning: "Input is blank!"})
            }
            dataEdit[modalForm[k].key] = modalForm[k].value
          }
          //console.log('dataEdit =>',dataEdit)
          let nowRestore = null
          let listRestore = this.state.listRestore
          for(let i = 0; i < listRestore.length; i++){
            if(listRestore[i].name === dataEdit.name){
              //console.log('listRestore =>',listRestore[i])
              nowRestore = listRestore[i]
              break
            }
          }

          if(nowRestore){
            this.setState({btnRestoreClick: true})
            let THIS = this
            let organization = this.props.location.state.organization
            let id = nowRestore._id
            axios({
              method: 'get',
              url: window.url + 'api/v1/collection/' + organization + '/branch_backup/' + id,
              data: 'values',
              headers:{
                "Authorization": users.token
              }
            })
            .then(function (response) {
              let dataRestore = response.data.value
              //console.log('dataBackup =>',dataRestore)
              if(dataRestore){
                let id = THIS.state.dataResponse._id
                let values = JSON.parse(JSON.stringify(dataRestore.dataBackup));
                THIS.setState({btnRestoreClick: true})

                values['status'] = 'restore'
                values['createBy'] = users.username
                values['updateBy'] = users.username
                delete values['_id']
                //console.log('saveConfig values =>',values);  
                axios({
                  method: 'put',
                  url: window.url + 'api/v1/collection/' + organization + '/branch/'+ id,
                  // url: 'http://13.251.52.58:3001/api/v1/users/' + this.state.user.organization,
                  data: values,
                  headers:{
                    "Authorization": users.token
                  }
                })
                .then(function (response) {
                  //console.log('response.data.value =>',response.data.value)
                  THIS.modalSmallClose()
                  THIS.setState({btnRestoreClick: false})
                  THIS.refresh()
                })
                .catch(function (error) {
                  console.log(error);
                  THIS.setState({btnRestoreClick: false})
                  THIS.modalSmallClose()
                }); 

              }else{
                THIS.setState({btnRestoreClick: false})
                THIS.modalSmallClose()  
              }
            })
            .catch(function (error) {
              console.log(error);
              THIS.setState({btnRestoreClick: false})
              THIS.modalSmallClose()
            }); 

          }else{
            this.setState({btnRestoreClick: false})
            this.modalSmallClose() 
          }
        }

      }
    }
  }

  edit = (item) => {    
    //+-console.log('addBranch item =>',item);
    let tempModalForm = JSON.parse(JSON.stringify(item))
        tempModalForm.status = 'Modify product'
    let modalFormTitle = "Modify product"
    let modalForm = [
      { key: "image", name: "image", placeholder:"", value: item.image, disabled: window.modalFormImageConfig.modifyProductVending.disableImage, type: 'image', path:item.path, col: '1' }, 
      { key: "sku", name: "SKU", placeholder:"", value: item.sku, disabled: window.modalFormImageConfig.modifyProductVending.disableSKU, type: 'input', col: '1' },
      { key: "countAlert", name: "Count alert", placeholder:"", value:item.countAlert, disabled: window.modalFormImageConfig.newProductVending.disableCountAlert, type: 'input', col: '1' },
      { key: "name", name: "Name", placeholder:"", value: item.name, disabled: window.modalFormImageConfig.modifyProductVending.disableName, type: 'input', col: '2' }, 
      { key: "price", name: "Price", placeholder:"", value: item.price, disabled: window.modalFormImageConfig.modifyProductVending.disablePrice, type: 'input', col: '2' }, 
      { key: "group", name: "Group", placeholder:"", value: item.group, disabled: window.modalFormImageConfig.modifyProductVending.disableGroup, type: 'input', col: '2' }, 
      { key: "channel", name: "Channel", placeholder:"", value: item.channel, disabled: window.modalFormImageConfig.modifyProductVending.disableChannel, type: 'input', col: '2' }, 
      { key: "count", name: "Cerrent Value", placeholder:"", value: item.count, disabled: window.modalFormImageConfig.modifyProductVending.disableCerrentValue, type: 'input', col: '2' }, 
      { key: "max", name: "Max Value", placeholder:"", value: item.max, disabled: window.modalFormImageConfig.modifyProductVending.disableMaxValue, type: 'input', col: '2' }
    ]
    // console.log('modalForm =>', modalForm);
    //this.setState({ modalForm: this.state.modalForm, modalAddbranchShow: true })
    this.setState({ 
      tempModalForm: tempModalForm,
      modalForm: modalForm,
      modalFormSave: modalForm,
      modalFormTitleSave: modalFormTitle,
      modalFormTitle: modalFormTitle,
      modalAddbranchShow: true,
      isButtonSelectFileEnable: false,
      isFilePondEnable: false
    })
  }

  add = () => {    
    //+-console.log('addBranch item =>');
    let tempModalForm = {
      name : "",
      sku: "",
      countAlert:5,
      image : "blank-image.png",
      path:"/products/blank-image.png",
      price : 0,
      group : 0,
      sold : 0,
      max : 24,
      count : 0,
      channel : 0,
      saleTime:"",
      expireDate:"",
      cooling : "",
      recommend : true,
      status : "New product"
    }
    let modalFormTitle = "New product"
    let modalForm = [
      { key: "image", name: "image", placeholder:"", value: "blank-image.png",disabled: window.modalFormImageConfig.newProductVending.disableImage, type: 'image', path:"/products/blank-image.png", col: '1' }, 
      { key: "sku", name: "SKU", placeholder:"", value:"", disabled: window.modalFormImageConfig.newProductVending.disableSKU, type: 'input', col: '1' },
      { key: "countAlert", name: "Count alert", placeholder:"", value:"5", disabled: window.modalFormImageConfig.newProductVending.disableCountAlert, type: 'input', col: '1' },
      { key: "name", name: "Name", placeholder:"", value: "", disabled: window.modalFormImageConfig.newProductVending.disableName, type: 'input', col: '2' }, 
      { key: "price", name: "Price", placeholder:"", value: "", disabled: window.modalFormImageConfig.newProductVending.disablePrice, type: 'input', col: '2' }, 
      { key: "group", name: "Group", placeholder:"", value: "0", disabled: window.modalFormImageConfig.newProductVending.disableGroup, type: 'input', col: '2' }, 
      { key: "channel", name: "Channel", placeholder:"", value: "0", disabled: window.modalFormImageConfig.newProductVending.disableChannel, type: 'input', col: '2' }, 
      { key: "count", name: "Cerrent Value", placeholder:"", value: "0", disabled: window.modalFormImageConfig.newProductVending.disableCerrentValue, type: 'input', col: '2' }, 
      { key: "max", name: "Max Value", placeholder:"", value: "24", disabled: window.modalFormImageConfig.newProductVending.disableMaxValue, type: 'input', col: '2' }, 
    ]
    // console.log('modalForm =>', modalForm);
    //this.setState({ modalForm: this.state.modalForm, modalAddbranchShow: true })
    this.setState({ 
      tempModalForm: tempModalForm,
      modalForm: modalForm,
      modalFormSave: modalForm,
      modalFormTitleSave: modalFormTitle,
      modalFormTitle: modalFormTitle,
      modalAddbranchShow: true, 
      isButtonSelectFileEnable: true,
      isFilePondEnable: false
    })
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    let modalForm =this.state.modalForm
    //+-console.log("handleChange event  =>", event.target);
    // console.log("modalForm  =>", modalForm);
    for(let i=0; i<modalForm.length; i++) {
      if(modalForm[i].name === name) {
        modalForm[i].value = value
        break
      }
    }
    this.setState({
      modalForm: modalForm, 
      btnSaveEnable: true
    })
  }

  handleInputChangeMain = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    //+-console.log("handleChange event  =>", event.target);
    let  dataResponse = this.state.dataResponse
    if(name === 'lat' || name === 'lng'){
      if(isNaN(value)) return
    }
    if(name === 'approved'){
      if(value == 'True'){
        dataResponse[name] = true
      }else{
        dataResponse[name]  = false
      }
      this.setState({
        dataResponse: dataResponse,
        btnSaveEnable: true
      })
      return
    }
    dataResponse[name] = value
    this.setState({
      dataResponse: dataResponse,
      btnSaveEnable: true
    })
  }

  handleInputChangeCalibrate = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    //+-console.log("handleChange event  =>", event.target);
    let calibrate = this.state.dataResponse.calibrate
    let dataResponse = this.state.dataResponse
    if(isNaN(value)) return
    calibrate[name] = value
    dataResponse.calibrate = calibrate
    this.setState({
      dataResponse: dataResponse,
      btnCalibrateEnable: true
    })
  }

  changDeviceID = (dataCallback) => {
    console.log("data =>",dataCallback)
    let dataResponse = this.state.dataResponse
    dataResponse['deviceID'] = dataCallback
    console.log('dataResponse =>',dataResponse.deviceID)
    this.setState({
      dataResponse: dataResponse,
      btnSaveEnable: true
    })

  }
  
  gotoCampaign = (item) => {
    //console.log('detailBranch item =>', item);
    let location = this.state.location
    //console.log('location =>',location)
    let allFilter =  storeGlobal.getAllFilter();
    allFilter['buttonBack'] = true;
    storeGlobal.getAllFilter(allFilter);
    if(location === undefined){
      location = {
        pathname: '/branchman',
        state: {}
      }
    }

    this.setState({
      location: location,
      redirect: true 
    })
  }

  selectFileClick = (dataIn) => {
    //+-console.log("selectFileClick =>",dataIn)
    let THIS = this
    axios({
      method: 'get',
      //url: window.url + 'api/v1/collection/' + this.state.defaultFileUpload.organization + '/products?_sortNatural=-1',
      url: window.url + 'api/v1/collection/' + this.state.defaultFileUpload.organization + '/products',
      data: 'values',
      headers:{
        "Authorization": this.state.user.token
      }
    })
    .then(function (response) {
      //+-console.log("response -->",response.data);
      if(response.data.value.length){
        //sort product by name 
        let p = response.data.value
        p.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)); 

        let modalFormTitle = "Select products"
        let modalForm = []
        for(let i = 0; i < response.data.value.length; i++){
          let item = response.data.value[i] 
          let tempObj =  JSON.parse(JSON.stringify(item))
            tempObj.type = 'imageSelect'
            tempObj.value = tempObj.name
            tempObj.col = 1
            tempObj.cardSelect = false
            if((dataIn.value === tempObj.name) || (dataIn.sku === tempObj.sku) ){
              tempObj.cardSelect = true
            }
            modalForm.push(tempObj)
        }
        //+-console.log("modalForm =>",modalForm)
        THIS.setState({
          modalForm:modalForm,
          modalFormTitle: modalFormTitle
        },() =>{
          THIS.setState({modalAddbranchShow:true})  
        })
      }
    })
    .catch(function (error) {
      THIS.setState({
        alertShow: true,
        alertText: error.message
      })
      console.log('axios error =>',error.message);
    });
  }

  imageOnclicks = (dataIn) => {
    //+-console.log('dataIn.cardSelect =>',dataIn)
    //+-console.log('modalFormSave =>',this.state.modalFormSave)
    if(dataIn.cardSelect === true){
      this.modalClose()
      let dataEdit = this.state.modalFormSave
      //let dataEdit = this.state.modalForm
      for(let k=0; k < dataEdit.length; k++) {

        if(dataEdit[k].key === 'image'){
          dataEdit[k].value= dataIn.image
          dataEdit[k].path= dataIn.path 
        }
        if(dataEdit[k].key === 'name'){
          let name  = dataIn.value.split('.');
          dataEdit[k].value= name[0]
        }
        if(dataEdit[k].key === 'price'){
          dataEdit[k].value = dataIn.price
        }
        if(dataEdit[k].key === 'sku'){
          dataEdit[k].value = dataIn.sku
        }
      }
      //+-console.log('dataEdit =>',dataEdit)
      this.setState({ 
        modalFormSave: dataEdit,
        modalForm: dataEdit, 
        modalFormTitle: this.state.modalFormTitleSave,
        modalAddbranchShow: true
      })
    }
  }

  goto401 = () => {
    // console.log('detailBranch item =>', item);
    this.props.history.push('/branchman');
    this.props.history.push('/401');
  }

  saveLog = (dataIn) => {

    fetch('https://api.ipify.org/?format=json').then(response => {
      return response.json();
    }).then((res) => {
      console.log('res ->', res)
      let user = this.state.user
      let data = dataIn
      data.ip = res.ip
      let organization = this.state.user.OrganizationSelect
      console.log("organization =>", organization)
      // return
      let THIS = this
      axios({
        method: 'post',
        url: window.url + 'api/v1/collection/' + organization + '/log',
        data: data,
        headers:{
          "Authorization": user.token
        }
      })
      .then(function (response1) {
        console.log('response1 ->',organization,'=',response1)

      })
      .catch(function (error) {
        console.log(error);
        // alert("error: " + JSON.stringify(error) );
        THIS.setState({ alertText: error.message, alertShow: true})
        THIS.setState({loadingOverlay: false})
      });
    })
  }

  // changFormatDate = (dataIn) => {
  //   console.log("dataIn =>",dataIn)
  //   // let localDate = new Date(dataIn)
  //   // console.log("localDate =>",localDate)
  //   //let nweDate = Moment(new Date(dataIn)).format("DD/MM/YYYY HH:mm:ss")
  //   let d = String(dataIn).split('.')
  //   let d0 = d[0].replace(/[T]/g, " ")
  //   let d1 = d0.split(' ')
  //   let d2 = d1[0].split('-')
  //   let nweDate = d2[2]+'/'+d2[1]+'/'+d2[0]+' '+ d1[1]
  //   console.log("nweDate =>",nweDate)
  //   return(nweDate)
  // }

  changFormatDate = (dataIn) => {
    return(
      <div>
        <Moment format="DD/MM/YYYY HH:mm:ss">
          { dataIn }
        </Moment>
      </div>
    )
  }

  // changFormatDate = (dateIn) => {
  //   let tempArr = dateIn.split("/");
  //   let temparr2 = tempArr[2].split(" ");
  //   return (temparr2[0] + '-' + tempArr[1] + '-' + tempArr[0] + ' ' + temparr2[1])
  // }
  
  render() {
    // let modalClose = () => this.setState({ modalAddUserShow: false });
    //console.log('dataResponse ->',this.state.dataResponse);
    const pumpout = window.vendingSettingPage.pumpout
    const var_PH = window.vendingSettingPage.var_PH
    const var_ORP = window.vendingSettingPage.var_ORP
    const var_EC = window.vendingSettingPage.var_EC
    const PumpFlow = window.vendingSettingPage.PumpFlow
    const power = window.vendingSettingPage.power
    const paginationStyle = {
      display: 'table',
      margin: '0 auto',
    };
    if(this.state.redirect) return ( <Redirect to={this.state.location} /> )
    return (     
      <SiteWrapper user={this.state.user} props = {this.props}>
        
        <Page.Content title="Vending Setting">
          {/* <SelectBranchs getbranchs = {this.getbranchs}/> */}
           <Grid.Row cards={true}>
           {( (this.state.dataResponse.type == 'HVM2S') || (this.state.dataResponse.type == 'HVM8S') ) &&
              <Grid.Col sm={6} lg={2}>
                <StampCard
                  color={pumpout.color}
                  icon={pumpout.icon}
                  className="" 
                  header={
                    <a href="#">
                      {this.state.pumpout.duration == 1 ? "ON": "OFF"}
                      <small> {pumpout.header}</small>
                    </a>
                  }                 
                  footer={pumpout.footer}           
                />
              </Grid.Col>
            }
            {( (this.state.dataResponse.type == 'HVM2S') || (this.state.dataResponse.type == 'HVM8S') ) &&
            <Grid.Col sm={6} lg={2}>
              <StampCard
                color={var_PH.color}
                icon={var_PH.icon}
                className="" 
                header={
                  <a href="#">
                    {this.state.var_PH.duration.toFixed(2)}
                    <small> {var_PH.header}</small>
                  </a>
                }                 
                footer={var_PH.footer}           
              />
            </Grid.Col>
            }
            {( (this.state.dataResponse.type == 'HVM2S') || (this.state.dataResponse.type == 'HVM8S') ) &&
            <Grid.Col sm={6} lg={2}>
              <StampCard
                color={var_ORP.color}
                icon={var_ORP.icon}
                className="" 
                header={
                  <a href="#">
                    {this.state.var_ORP.duration.toFixed(2)}
                    <small> {var_ORP.header}</small>
                  </a>
                }                 
                footer={var_ORP.footer}           
              />
            </Grid.Col>
            }
            {( (this.state.dataResponse.type == 'HVM2S') || (this.state.dataResponse.type == 'HVM8S') ) &&
            <Grid.Col sm={6} lg={2}>
              <StampCard
                color={var_EC.color}
                icon={var_EC.icon}
                className="" 
                header={
                  <a href="#">
                    {this.state.var_EC.duration.toFixed(2)}
                    <small> {var_EC.header}</small>
                  </a>
                }                 
                footer={var_EC.footer}           
              />
            </Grid.Col>
            }
            {( (this.state.dataResponse.type == 'HVM2S') || (this.state.dataResponse.type == 'HVM8S') ) &&
            <Grid.Col sm={6} lg={2}>
              <StampCard
                color={PumpFlow.color}
                icon={PumpFlow.icon}
                className="" 
                header={
                  <a href="#">
                    {this.state.PumpFlow.duration.toFixed(2)}
                    <small> {PumpFlow.header}</small>
                  </a>
                }                 
                footer={PumpFlow.footer}           
              />
            </Grid.Col>
            }
            {( (this.state.dataResponse.type == 'HVM2S') || (this.state.dataResponse.type == 'HVM8S') ) &&
            <Grid.Col sm={6} lg={2}>
              <StampCard
                color={power.color}
                icon={power.icon}
                className="" 
                header={
                  <a href="#">
                    {this.state.power.duration.toFixed(2)}
                    <small> {power.header}</small>
                  </a>
                }                 
                footer={power.footer}           
              />
            </Grid.Col>
            }

            <Grid.Col lg={12}>
              <Card> 
                <Card.Header>
                  <Card.Title>Vending config Organization : {this.state.dataResponse.organization}</Card.Title>
                  <Card.Options>
                    <Button.List>
                      <Button 
                        color="gray"                           
                        onClick={ () => this.gotoCampaign(this) }
                      > 
                      <Icon prefix="fe" name="arrow-left" className="mr-2" />
                      Back 
                      </Button>  

                      { this.state.user.level==='write' && (!this.state.btnRestoreEnable ? 
                        <Button 
                          color="warning"  
                          disabled  
                          className="text-dark"         
                        >
                        Restore
                        </Button>
                        :( this.state.btnRestoreClick ? 
                        <Button 
                          color="warning"  
                          disabled           
                        ><PulseLoader color={'#FFFFFF'} /></Button>
                        :
                        <Button 
                          color="warning"                  
                          onClick={ () => this.restore() }
                        >
                        Restore 
                        </Button> 
                        )
                      )} 
                      
                      { this.state.user.level==='write' && (!this.state.btnBackupEnable ? 
                        <Button 
                          color="primary"  
                          disabled           
                        >
                        Backup
                        </Button>
                        :( this.state.btnBackupClick ? 
                        <Button 
                          color="primary"  
                          disabled           
                        ><PulseLoader color={'#FFFFFF'} /></Button>
                        :
                        <Button 
                          color="primary"                  
                          onClick={ () => this.backup() }
                        >
                        Backup 
                        </Button> 
                        )
                      )} 

                      { this.state.user.level==='write' && (!this.state.btnSaveEnable ? 
                        <Button 
                          color="success"                  
                          disabled
                          // onClick={ ()=> { this.alert({action:'deploy', id: this.state.dataResponse._id, fullname: this.state.dataResponse.branch, i: 'i', data: this.state.dataResponse }) } }
                        >Save
                        </Button>

                        :
                        ( this.state.btnSaveClick ? 
                        <Button 
                          color="success"  
                          disabled           
                        ><PulseLoader color={'#FFFFFF'} /></Button>
                        :
                        <Button 
                          color="success"                  
                          // onClick={ () => this.saveConfig() }
                          onClick={ ()=> { this.alert({action:'save', id: this.state.dataResponse._id, fullname: this.state.dataResponse.branch, i: 'i', data: this.state.dataResponse }) } }
                        >
                        Save
                        </Button> )
                      )} 

                      { this.state.user.level==='write' && (!this.state.btnSaveEnable ? 
                        <Button 
                          color="teal"                  
                          disabled
                          // onClick={ ()=> { this.alert({action:'deploy', id: this.state.dataResponse._id, fullname: this.state.dataResponse.branch, i: 'i', data: this.state.dataResponse }) } }
                        >Deploy </Button>

                        :
                        ( this.state.btnSaveClick ? 
                        <Button 
                          color="teal"  
                          disabled           
                        ><PulseLoader color={'#FFFFFF'} /></Button>
                        :
                        <Button 
                          color="teal"                  
                          // onClick={ () => this.saveConfig() }
                          onClick={ ()=> { this.alert({action:'deploy', id: this.state.dataResponse._id, fullname: this.state.dataResponse.branch, i: 'i', data: this.state.dataResponse }) } }
                        >
                        Deploy 
                        </Button> 
                        )
                      )}

                      { 
                        ( window.configProject.hypoType.indexOf(this.state.dataResponse.type) >= 0 ) && <Button 
                          color="danger"                           
                          onClick={ ()=> { this.alert({action:'restart', id: this.state.dataResponse._id, fullname: this.state.dataResponse.branch, i: 'i', data: this.state.dataResponse }) } }
                        > 
                        {/* <Icon prefix="fe" name="arrow-left" className="mr-2" /> */}
                        Restart 
                        </Button> 
                      }   


                      {!this.state.realtimeEnable ? 
                        <Button
                        color="info"
                        onClick={ () => { 
                          this.setState({
                            btnSaveEnable : false,
                            btnCalibrateEnable : false,
                            realtimeEnable : true
                            },() => {
                            this.refresh()
                          })
                        }}
                        >
                        Start realtime
                        </Button>

                        :

                        (this.state.isButtonLoading? 
                        <Button 
                          color="secondary" 
                          icon="rotate-cw" 
                          onClick={ () => { 
                            this.setState({
                              btnSaveEnable : false,
                              btnCalibrateEnable : false,
                              realtimeEnable : true
                              },() => {
                              this.refresh()
                            })
                          }}
                        />
                        :
                        <Button 
                          color="secondary" 
                          icon="rotate-cw" 
                          loading 
                        />
                        )
                      }

                      
                    </Button.List>            
                  </Card.Options>
                </Card.Header>
                <Card.Body>

                  <Grid.Row>
                    <Grid.Col lg={6}>
                      <Form.Group label="Vending Name">
                        <Form.Input
                          name="branch" 
                          placeholder="" 
                          value={this.state.dataResponse.branch || ""} 
                          onChange={this.handleInputChangeMain} 
                        />    
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col lg={6}>
                      <Form.Group label="Vending ID">
                        <Form.Input
                          disabled
                          name="branchID"
                          placeholder=""
                          value={this.state.dataResponse.branchID || ""}
                          onChange={this.handleInputChangeMain}
                        />
                      </Form.Group>
                    </Grid.Col>
                  </Grid.Row>

                  {( (this.state.dataResponse.type == 'HVM2S') || (this.state.dataResponse.type == 'HVM8S') ) &&
                  <Grid.Row>
                    <Grid.Col lg={6}>
                      <Form.Group label="Device ID">
                        <SingleSelect  value =  {this.state.dataResponse.deviceID} options = {this.state.device} dataChang = {this.changDeviceID}/>
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col lg={6}>
                      <Form.Group label="Version">
                        <Form.Input
                          disabled
                          name="version"
                          placeholder=""
                          value={this.state.dataResponse.version || ""}
                        />
                      </Form.Group>
                    </Grid.Col>
                  </Grid.Row>
                  }

                  <Grid.Row>
                    <Grid.Col lg={6}>
                    {this.state.dataResponse.type != undefined ? <Form.Group label="Type">
                        <Form.Input
                          disabled
                          name="type"
                          placeholder=""
                          value={this.state.dataResponse.type || ""}
                        />
                      </Form.Group>:<Form.Group label= "Type">
                      <Form.Select name= "type" onChange={this.handleInputChangeMain} value={this.state.dataResponse.type} >                
                        {window.configVending.typeItems.map( (item, i) => 
                          <option  key={i}> {item} </option>
                        )}
                      </Form.Select>
                    </Form.Group>
                    }

                    </Grid.Col>
                    <Grid.Col lg={6}>
                      <Form.Group label="Location">
                        <Form.Input
                          name="location"
                          placeholder=""
                          value={this.state.dataResponse.location || ""}
                          onChange={this.handleInputChangeMain}
                        />
                      </Form.Group>
                    </Grid.Col>
                  </Grid.Row>

                  <Grid.Row>
                    {/* <Grid.Col lg={6}>
                      <Form.Group label= "Project">
                      <Form.Select name= "project" onChange={this.handleInputChangeMain} value={this.state.dataResponse.project} >                
                        { this.state.projectItems.map( (item, i) => 
                          <option key={i}> {item} </option>
                        )}
                      </Form.Select>
                    </Form.Group>
                    </Grid.Col> */}
                    <Grid.Col lg={6}>
                      <Form.Group label="Project">
                        <Form.Input
                          disabled
                          name="project"
                          placeholder=""
                          value={this.state.dataResponse.project || ""}
                        />
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col lg={6}>
                      <Form.Group label="CentralURI">
                        <Form.Input
                          name="centralURI"
                          placeholder=""
                          value={this.state.dataResponse.centralURI || ""}
                          onChange={this.handleInputChangeMain}
                        />
                      </Form.Group>
                    </Grid.Col>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Col lg={6}>
                      <Form.Group label="Latitude">
                        <Form.Input
                          name="lat"
                          placeholder=""
                          value = {this.state.dataResponse.lat || ""}
                          onChange={this.handleInputChangeMain}
                        />
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col lg={6}>
                      <Form.Group label="Longitude">
                        <Form.Input
                          name="lng"
                          placeholder=""
                          // value={this.state.dataResponse.updateDate}
                          value = {this.state.dataResponse.lng || ""}
                          onChange={this.handleInputChangeMain}
                        />
                      </Form.Group>
                    </Grid.Col>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Col lg={6}>
                      <Form.Group label="Status">
                        <Form.Input
                          disabled
                          name="status"
                          placeholder=""
                          value={this.state.dataResponse.status || ""}
                        />
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col lg={6}>
                      <Form.Group label= "Approved">
                      <Form.Select name= "approved" onChange={this.handleInputChangeMain} value={this.state.dataResponse.approved?'True':'False'} >
                          <option >True </option>
                          <option >False </option>
                      </Form.Select>
                      </Form.Group>
                    </Grid.Col>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Col lg={6}>
                      <Form.Group label="Last Connect">
                        <input 
                          name="connect" 
                          disabled={ true } 
                          value={ this.state.dataResponse.connect ? moment(this.state.dataResponse.connect).format("DD/MM/YYYY HH:mm:ss") : "" } 
                          className="form-control" 
                        />
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col lg={6}>
                      <Form.Group label="Last Disconnect">
                        <input 
                          name="disconnect" 
                          disabled={ true } 
                          value={ this.state.dataResponse.disconnect ? moment(this.state.dataResponse.disconnect).format("DD/MM/YYYY HH:mm:ss") : "" } 
                          className="form-control" 
                        />
                      </Form.Group>
                    </Grid.Col>
                  </Grid.Row>

                </Card.Body>
                </Card>
              </Grid.Col>
            </Grid.Row>

            {( (this.state.dataResponse.type === '30S') || (this.state.dataResponse.type === '36S') || (this.state.dataResponse.type === '42S') ) &&
            <Grid.Row>
              <Grid.Col lg={12}>
                <Card>
                  <Card.Header>
                    <Card.Title>Bill stack status</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    {this.state.dataResponse.billStack && 
                      <Grid.Row>
                        <Grid.Col lg={2}>
                          <Form.Group label="20 THB">
                            <Form.Input
                              disabled
                              value = {this.state.dataResponse.billStack.b20}
                            />
                          </Form.Group>
                        </Grid.Col>
                        <Grid.Col lg={2}>
                          <Form.Group label="50 THB">
                            <Form.Input
                              disabled
                              value = {this.state.dataResponse.billStack.b50}
                            />
                          </Form.Group>
                        </Grid.Col>
                        <Grid.Col lg={2}>
                          <Form.Group label="100 THB">
                            <Form.Input
                              disabled
                              value = {this.state.dataResponse.billStack.b100}
                            />
                          </Form.Group>
                        </Grid.Col>
                        <Grid.Col lg={2}>
                          <Form.Group label="500 THB">
                            <Form.Input
                              disabled
                              value = {this.state.dataResponse.billStack.b500}
                            />
                          </Form.Group>
                        </Grid.Col>
                        <Grid.Col lg={2}>
                          <Form.Group label="1000 THB">
                            <Form.Input
                              disabled
                              value = {this.state.dataResponse.billStack.b1000}
                            />
                          </Form.Group>
                        </Grid.Col>
                        <Grid.Col lg={2}>
                          <Form.Group label="Total">
                            <Form.Input
                              disabled
                              value = {this.state.dataResponse.billStack.total}
                            />
                          </Form.Group>
                        </Grid.Col>
  
                      </Grid.Row>
                    }
                  </Card.Body>
                </Card>
              </Grid.Col>
            </Grid.Row>
            }

            {( ( (this.state.dataResponse.type == 'HVM2S') || (this.state.dataResponse.type == 'HVM8S') ) && (this.state.dataResponse.calibrate != undefined) ) &&
            <Grid.Row>
              <Grid.Col lg={12}>
                <Card>
                  <Card.Header>
                    <Card.Title>Calibrate configuration</Card.Title>
                    <Card.Options>
                      <Button.List>
                        { this.state.user.level==='write' && (!this.state.btnCalibrateEnable ? 
                          <Button 
                            color="green"                  
                            disabled
                            // onClick={ ()=> { this.alert({action:'deploy', id: this.state.dataResponse._id, fullname: this.state.dataResponse.branch, i: 'i', data: this.state.dataResponse }) } }
                          >Calibrate</Button>

                          :
                          ( this.state.btnCalibrateClick ? 
                          <Button 
                            color="green"  
                            disabled           
                          ><PulseLoader color={'#FFFFFF'} /></Button>
                          :
                          <Button 
                            color="green"                  
                            // onClick={ () => this.saveConfig() }
                            onClick={ ()=> { this.alert({action:'calibrate', id: this.state.dataResponse._id, fullname: this.state.dataResponse.branch, i: 'i', data: this.state.dataResponse }) } }
                          >Calibrate</Button> )
                        )} 
                      </Button.List>            
                    </Card.Options>
                  </Card.Header>
                  {/* {(this.state.dataResponse.calibrate != undefined) &&  */}
                  <Card.Body>
                    <Grid.Row>
                      <Grid.Col sm={6} lg={2}>
                        <Form.Group label={window.labelCalibrate.pcode}>
                          <Form.Input
                            disabled
                            name="pcode"
                            placeholder=""
                            value={this.state.dataResponse.calibrate.pcode || "0"}
                            onChange={this.handleInputChangeCalibrate}
                          />
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col sm={6} lg={2}>
                        <Form.Group label={window.labelCalibrate.var_setvolt}>
                          <Form.Input
                            name="var_setvolt" 
                            placeholder="" 
                            value={this.state.dataResponse.calibrate.var_setvolt || "0"} 
                            onChange={this.handleInputChangeCalibrate} 
                          />    
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col sm={6} lg={2}>
                        <Form.Group label={window.labelCalibrate.M_A}>
                          <Form.Input
                            name="M_A"
                            placeholder=""
                            value={this.state.dataResponse.calibrate.M_A || "0"}
                            onChange={this.handleInputChangeCalibrate}
                          />
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col sm={6} lg={2}>
                        <Form.Group label={window.labelCalibrate.M_PP}>
                          <Form.Input
                            name="M_PP" 
                            placeholder="" 
                            value={this.state.dataResponse.calibrate.M_PP || "0"} 
                            onChange={this.handleInputChangeCalibrate} 
                          />    
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col sm={6} lg={2}>
                        <Form.Group label={window.labelCalibrate.M_PN}>
                          <Form.Input
                            name="M_PN"
                            placeholder=""
                            value={this.state.dataResponse.calibrate.M_PN || "0"}
                            onChange={this.handleInputChangeCalibrate}
                          />
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col sm={6} lg={2}>
                        <Form.Group label={window.labelCalibrate.OF_PH}>
                          <Form.Input
                            name="OF_PH" 
                            placeholder="" 
                            value={this.state.dataResponse.calibrate.OF_PH || "0"} 
                            onChange={this.handleInputChangeCalibrate} 
                          />    
                        </Form.Group>
                      </Grid.Col>
                    </Grid.Row>

                    <Grid.Row>
                      <Grid.Col sm={6} lg={2}>
                        <Form.Group label={window.labelCalibrate.OF_ORP}>
                          <Form.Input
                            name="OF_ORP"
                            placeholder=""
                            value={this.state.dataResponse.calibrate.OF_ORP || "0"}
                            onChange={this.handleInputChangeCalibrate}
                          />
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col sm={6} lg={2}>
                        <Form.Group label={window.labelCalibrate.OF_EC}>
                          <Form.Input
                            name="OF_EC" 
                            placeholder="" 
                            value={this.state.dataResponse.calibrate.OF_EC || "0"} 
                            onChange={this.handleInputChangeCalibrate} 
                          />    
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col sm={6} lg={2}>
                        <Form.Group label={window.labelCalibrate.SysOn}>
                          <Form.Input
                            name="SysOn"
                            placeholder=""
                            value={this.state.dataResponse.calibrate.SysOn || "0"}
                            onChange={this.handleInputChangeCalibrate}
                          />
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col sm={6} lg={2}>
                        <Form.Group label={window.labelCalibrate.MixOn}>
                          <Form.Input
                            name="MixOn" 
                            placeholder="" 
                            value={this.state.dataResponse.calibrate.MixOn || "0"} 
                            onChange={this.handleInputChangeCalibrate} 
                          />    
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col sm={6} lg={2}>
                        <Form.Group label={window.labelCalibrate.T_Salt}>
                          <Form.Input
                            name="T_Salt"
                            placeholder=""
                            value={this.state.dataResponse.calibrate.T_Salt || "0"}
                            onChange={this.handleInputChangeCalibrate}
                          />
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col sm={6} lg={2}>
                        <Form.Group label={window.labelCalibrate.Set_EC}>
                          <Form.Input
                            name="Set_EC" 
                            placeholder="" 
                            value={this.state.dataResponse.calibrate.Set_EC || "0"} 
                            onChange={this.handleInputChangeCalibrate} 
                          />    
                        </Form.Group>
                      </Grid.Col>
                    </Grid.Row>

                    <Grid.Row>
                      <Grid.Col sm={6} lg={2}>
                        <Form.Group label={window.labelCalibrate.Set_HW}>
                          <Form.Input
                            name="Set_HW"
                            placeholder=""
                            value={this.state.dataResponse.calibrate.Set_HW || "0"}
                            onChange={this.handleInputChangeCalibrate}
                          />
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col sm={6} lg={2}>
                        <Form.Group label={window.labelCalibrate.Set_ID}>
                          <Form.Input
                            disabled
                            name="Set_ID" 
                            placeholder="" 
                            value={this.state.dataResponse.calibrate.Set_ID || "0"} 
                            onChange={this.handleInputChangeCalibrate} 
                          />    
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col sm={6} lg={2}>
                        <Form.Group label={window.labelCalibrate.M1_V}>
                          <Form.Input
                            name="M1_V"
                            placeholder=""
                            value={this.state.dataResponse.calibrate.M1_V || "0"}
                            onChange={this.handleInputChangeCalibrate}
                          />
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col sm={6} lg={2}>
                        <Form.Group label={window.labelCalibrate.M1_A}>
                          <Form.Input
                            name="M1_A" 
                            placeholder="" 
                            value={this.state.dataResponse.calibrate.M1_A || "0"} 
                            onChange={this.handleInputChangeCalibrate} 
                          />    
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col sm={6} lg={2}>
                        <Form.Group label={window.labelCalibrate.M1_PP}>
                          <Form.Input
                            name="M1_PP"
                            placeholder=""
                            value={this.state.dataResponse.calibrate.M1_PP || "0"}
                            onChange={this.handleInputChangeCalibrate}
                          />
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col sm={6} lg={2}>
                        <Form.Group label={window.labelCalibrate.M1_PN}>
                          <Form.Input
                            name="M1_PN" 
                            placeholder="" 
                            value={this.state.dataResponse.calibrate.M1_PN || "0"} 
                            onChange={this.handleInputChangeCalibrate} 
                          />    
                        </Form.Group>
                      </Grid.Col>
                    </Grid.Row>

                    <Grid.Row>
                      <Grid.Col sm={6} lg={2}>
                        <Form.Group label={window.labelCalibrate.M1_VL}>
                          <Form.Input
                            name="M1_VL"
                            placeholder=""
                            value={this.state.dataResponse.calibrate.M1_VL || "0"}
                            onChange={this.handleInputChangeCalibrate}
                          />
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col sm={6} lg={2}>
                        <Form.Group label={window.labelCalibrate.M1_VT}>
                          <Form.Input
                            name="M1_VT" 
                            placeholder="" 
                            value={this.state.dataResponse.calibrate.M1_VT || "0"} 
                            onChange={this.handleInputChangeCalibrate} 
                          />    
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col sm={6} lg={2}>
                        <Form.Group label={window.labelCalibrate.M1_EP}>
                          <Form.Input
                            name="M1_EP"
                            placeholder=""
                            value={this.state.dataResponse.calibrate.M1_EP || "0"}
                            onChange={this.handleInputChangeCalibrate}
                          />
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col sm={6} lg={2}>
                        <Form.Group label={window.labelCalibrate.M1_EN}>
                          <Form.Input
                            name="M1_EN" 
                            placeholder="" 
                            value={this.state.dataResponse.calibrate.M1_EN || "0"} 
                            onChange={this.handleInputChangeCalibrate} 
                          />    
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col sm={6} lg={2}>
                        <Form.Group label={window.labelCalibrate.M1_VF}>
                          <Form.Input
                            name="M1_VF"
                            placeholder=""
                            value={this.state.dataResponse.calibrate.M1_VF || "0"}
                            onChange={this.handleInputChangeCalibrate}
                          />
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col sm={6} lg={2}>
                        <Form.Group label={window.labelCalibrate.M2_V}>
                          <Form.Input
                            name="M2_V" 
                            placeholder="" 
                            value={this.state.dataResponse.calibrate.M2_V || "0"} 
                            onChange={this.handleInputChangeCalibrate} 
                          />    
                        </Form.Group>
                      </Grid.Col>
                    </Grid.Row>

                    <Grid.Row>
                      <Grid.Col sm={6} lg={2}>
                        <Form.Group label={window.labelCalibrate.M2_A}>
                          <Form.Input
                            name="M2_A"
                            placeholder=""
                            value={this.state.dataResponse.calibrate.M2_A || "0"}
                            onChange={this.handleInputChangeCalibrate}
                          />
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col sm={6} lg={2}>
                        <Form.Group label={window.labelCalibrate.M2_PP}>
                          <Form.Input
                            name="M2_PP" 
                            placeholder="" 
                            value={this.state.dataResponse.calibrate.M2_PP || "0"} 
                            onChange={this.handleInputChangeCalibrate} 
                          />    
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col sm={6} lg={2}>
                        <Form.Group label={window.labelCalibrate.M2_PN}>
                          <Form.Input
                            name="M2_PN"
                            placeholder=""
                            value={this.state.dataResponse.calibrate.M2_PN || "0"}
                            onChange={this.handleInputChangeCalibrate}
                          />
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col sm={6} lg={2}>
                        <Form.Group label={window.labelCalibrate.M2_VL}>
                          <Form.Input
                            name="M2_VL" 
                            placeholder="" 
                            value={this.state.dataResponse.calibrate.M2_VL || "0"} 
                            onChange={this.handleInputChangeCalibrate} 
                          />    
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col sm={6} lg={2}>
                        <Form.Group label={window.labelCalibrate.M2_VT}>
                          <Form.Input
                            name="M2_VT"
                            placeholder=""
                            value={this.state.dataResponse.calibrate.M2_VT || "0"}
                            onChange={this.handleInputChangeCalibrate}
                          />
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col sm={6} lg={2}>
                        <Form.Group label={window.labelCalibrate.M2_SN}>
                          <Form.Input
                            name="M2_SN" 
                            placeholder="" 
                            value={this.state.dataResponse.calibrate.M2_SN || "0"} 
                            onChange={this.handleInputChangeCalibrate} 
                          />    
                        </Form.Group>
                      </Grid.Col>
                    </Grid.Row>

                    <Grid.Row>
                      <Grid.Col sm={6} lg={2}>
                        <Form.Group label={window.labelCalibrate.M2_VF}>
                          <Form.Input
                            name="M2_VF"
                            placeholder=""
                            value={this.state.dataResponse.calibrate.M2_VF || "0"}
                            onChange={this.handleInputChangeCalibrate}
                          />
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col sm={6} lg={2}>
                        <Form.Group label=".">
                          <Form.Input
                            disabled
                            name="" 
                            placeholder="" 
                            value="" 
                          />    
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col sm={6} lg={2}>
                        <Form.Group label=".">
                          <Form.Input
                            disabled
                            name="" 
                            placeholder="" 
                            value="" 
                          />    
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col sm={6} lg={2}>
                        <Form.Group label=".">
                          <Form.Input
                            disabled
                            name="" 
                            placeholder="" 
                            value="" 
                          />    
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col sm={6} lg={2}>
                        <Form.Group label=".">
                          <Form.Input
                            disabled
                            name="" 
                            placeholder="" 
                            value="" 
                          />    
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col sm={6} lg={2}>
                        <Form.Group label=".">
                          <Form.Input
                            disabled
                            name="" 
                            placeholder="" 
                            value="" 
                          />    
                        </Form.Group>
                      </Grid.Col>
                    </Grid.Row>
                  
                  </Card.Body>
                  
                </Card>
              </Grid.Col>
            </Grid.Row>
            }

            {( ( (this.state.dataResponse.type == 'HVM2S') || (this.state.dataResponse.type == 'HVM8S') ) && (this.state.dataResponse.sensor != undefined) ) &&
            <Grid.Row>
              <Grid.Col lg={12}>
                <Card>
                  <Card.Header>
                    <Card.Title> Sensor monitor</Card.Title>
                  </Card.Header>
                  {/* {(this.state.dataResponse.calibrate != undefined) &&  */}
                  <Card.Body>
                    <Grid.Row>
                      <Grid.Col sm={6} lg={2}>
                        <Form.Group label={window.labelSensor.pumpout}>
                          <Form.Input
                            disabled
                            name="pumpout"
                            placeholder=""
                            value={this.state.dataResponse.sensor.pumpout || "false"}
                          />
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col sm={6} lg={2}>
                        <Form.Group label={window.labelSensor.volt_1}>
                          <Form.Input
                            name="volt_1" 
                            disabled
                            placeholder="" 
                            value={this.state.dataResponse.sensor.volt_1 || "0"} 
                          />    
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col sm={6} lg={2}>
                        <Form.Group label={window.labelSensor.volt_2}>
                          <Form.Input
                            disabled
                            name="volt_2"
                            placeholder=""
                            value={this.state.dataResponse.sensor.volt_2 || "0"}
                          />
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col sm={6} lg={2}>
                        <Form.Group label={window.labelSensor.current_1}>
                          <Form.Input
                            disabled
                            name="current_1" 
                            placeholder="" 
                            value={this.state.dataResponse.sensor.current_1 || "0"} 
                          />    
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col sm={6} lg={2}>
                        <Form.Group label={window.labelSensor.current_2}>
                          <Form.Input
                            disabled
                            name="current_2"
                            placeholder=""
                            value={this.state.dataResponse.sensor.current_2 || "0"}
                          />
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col sm={6} lg={2}>
                        <Form.Group label={window.labelSensor.watt_1}>
                          <Form.Input
                            disabled
                            name="watt_1" 
                            placeholder="" 
                            value={this.state.dataResponse.sensor.watt_1 || "0"} 
                          />    
                        </Form.Group>
                      </Grid.Col>
                    </Grid.Row>

                    <Grid.Row>
                      <Grid.Col sm={6} lg={2}>
                        <Form.Group label={window.labelSensor.watt_2}>
                          <Form.Input
                            disabled
                            name="watt_2"
                            placeholder=""
                            value={this.state.dataResponse.sensor.watt_2 || "0"}
                          />
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col sm={6} lg={2}>
                        <Form.Group label={window.labelSensor.var_PH}>
                          <Form.Input
                            disabled
                            name="var_PH"
                            placeholder=""
                            value={this.state.dataResponse.sensor.var_PH || "0"}
                          />
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col sm={6} lg={2}>
                        <Form.Group label={window.labelSensor.var_ORP}>
                          <Form.Input
                            disabled
                            name="var_ORP"
                            placeholder=""
                            value={this.state.dataResponse.sensor.var_ORP || "0"}
                          />
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col sm={6} lg={2}>
                        <Form.Group label={window.labelSensor.var_EC}>
                          <Form.Input
                            disabled
                            name="var_EC"
                            placeholder=""
                            value={this.state.dataResponse.sensor.var_EC || "0"}
                          />
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col sm={6} lg={2}>
                        <Form.Group label={window.labelSensor.f_valP}>
                          <Form.Input
                            disabled
                            name="f_valP"
                            placeholder=""
                            value={this.state.dataResponse.sensor.f_valP || "0"}
                          />
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col sm={6} lg={2}>
                        <Form.Group label={window.labelSensor.f_valN}>
                          <Form.Input
                            disabled
                            name="f_valN"
                            placeholder=""
                            value={this.state.dataResponse.sensor.f_valN || "0"}
                          />
                        </Form.Group>
                      </Grid.Col>
                    </Grid.Row>

                    <Grid.Row>
                      <Grid.Col sm={6} lg={2}>
                        <Form.Group label={window.labelSensor.PumpP_Lmin}>
                          <Form.Input
                            disabled
                            name="PumpP_Lmin"
                            placeholder=""
                            value={this.state.dataResponse.sensor.PumpP_Lmin || "0"}
                          />
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col sm={6} lg={2}>
                        <Form.Group label={window.labelSensor.PumpN_Lmin}>
                          <Form.Input
                            disabled
                            name="PumpN_Lmin"
                            placeholder=""
                            value={this.state.dataResponse.sensor.PumpN_Lmin || "0"}
                          />
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col sm={6} lg={2}>
                        <Form.Group label={window.labelSensor.StwaterTankMixHigh}>
                          <Form.Input
                            disabled
                            name="StwaterTankMixHigh"
                            placeholder=""
                            value={this.state.dataResponse.sensor.StwaterTankMixHigh || "false"}
                          />
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col sm={6} lg={2}>
                        <Form.Group label={window.labelSensor.StwaterTankMixLow}>
                          <Form.Input
                            disabled
                            name="StwaterTankMixLow"
                            placeholder=""
                            value={this.state.dataResponse.sensor.StwaterTankMixLow || "false"}
                          />
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col sm={6} lg={2}>
                        <Form.Group label={window.labelSensor.StwaterTankCellHigh}>
                          <Form.Input
                            disabled
                            name="StwaterTankCellHigh"
                            placeholder=""
                            value={this.state.dataResponse.sensor.StwaterTankCellHigh || "false"}
                          />
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col sm={6} lg={2}>
                        <Form.Group label={window.labelSensor.StwaterTankCellLow}>
                          <Form.Input
                            disabled
                            name="StwaterTankCellLow"
                            placeholder=""
                            value={this.state.dataResponse.sensor.StwaterTankCellLow || "0"}
                          />
                        </Form.Group>
                      </Grid.Col>
                    </Grid.Row>

                    <Grid.Row>
                      <Grid.Col sm={6} lg={2}>
                        <Form.Group label={window.labelSensor.SaltLv}>
                          <Form.Input
                            disabled
                            name="SaltLv"
                            placeholder=""
                            value={this.state.dataResponse.sensor.SaltLv || "0"}
                          />
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col sm={6} lg={2}>
                        <Form.Group label={window.labelSensor.StrSSID}>
                          <Form.Input
                            disabled
                            name="StrSSID"
                            placeholder=""
                            value={this.state.dataResponse.sensor.StrSSID || "0"}
                          />
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col sm={6} lg={2}>
                        <Form.Group label={window.labelSensor.StrIP}>
                          <Form.Input
                            disabled
                            name="StrIP"
                            placeholder=""
                            value={this.state.dataResponse.sensor.StrIP || "0"}
                          />
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col sm={6} lg={2}>
                        <Form.Group label={window.labelSensor.add_Ventilate}>
                          <Form.Input
                            disabled
                            name="add_Ventilate"
                            placeholder=""
                            value={this.state.dataResponse.sensor.add_Ventilate || "0"}
                          />
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col sm={6} lg={2}>
                        <Form.Group label={window.labelSensor.add_SnWaterInlet}>
                          <Form.Input
                            disabled
                            name="add_SnWaterInlet"
                            placeholder=""
                            value={this.state.dataResponse.sensor.add_SnWaterInlet || "0"}
                          />
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col sm={6} lg={2}>
                        <Form.Group label={window.labelSensor.add_PumpIntestTank}>
                          <Form.Input
                            disabled
                            name="add_PumpIntestTank"
                            placeholder=""
                            value={this.state.dataResponse.sensor.add_PumpIntestTank || "0"}
                          />
                        </Form.Group>
                      </Grid.Col>
                    </Grid.Row>


                    <Grid.Row>
                      <Grid.Col sm={6} lg={2}>
                        <Form.Group label={window.labelSensor.add_PumpOuttestTank}>
                          <Form.Input
                            disabled
                            name="add_PumpOuttestTank"
                            placeholder=""
                            value={this.state.dataResponse.sensor.add_PumpOuttestTank || "0"}
                          />
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col sm={6} lg={2}>
                        <Form.Group label={window.labelSensor.add_PumpMixSalt}>
                          <Form.Input
                            disabled
                            name="add_PumpMixSalt"
                            placeholder=""
                            value={this.state.dataResponse.sensor.add_PumpMixSalt || "0"}
                          />
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col sm={6} lg={2}>
                        <Form.Group label={window.labelSensor.add_PumpTranfer}>
                          <Form.Input
                            disabled
                            name="add_PumpTranfer"
                            placeholder=""
                            value={this.state.dataResponse.sensor.add_PumpTranfer || "0"}
                          />
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col sm={6} lg={2}>
                        <Form.Group label={window.labelSensor.add_MtFeedSalt}>
                          <Form.Input
                            disabled
                            name="add_MtFeedSalt"
                            placeholder=""
                            value={this.state.dataResponse.sensor.add_MtFeedSalt || "0"}
                          />
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col sm={6} lg={2}>
                        <Form.Group label=".">
                          <Form.Input
                            disabled
                            name=""
                            placeholder=""
                            value=""
                          />
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col sm={6} lg={2}>
                        <Form.Group label=".">
                          <Form.Input
                            disabled
                            name=""
                            placeholder=""
                            value=""
                          />
                        </Form.Group>
                      </Grid.Col>
                    </Grid.Row>

                  </Card.Body>
                </Card>
              </Grid.Col>
            </Grid.Row>
            }
            


            <Grid.Row>
              <Grid.Col lg={12}>
                <Card>
                  <Card.Header>
                    <Card.Title>Product Setting</Card.Title>
                    { this.state.user.level==='write' && <Card.Options>
                      <Button 
                        color="green"                  
                        onClick={ () => this.add() }
                      > Add Product </Button>                
                    </Card.Options>
                    }
                  </Card.Header>
                  {/* <Card.Body> */}
                    {/* <Table className="card-table table-vcenter"> */}
                    <Table
                      responsive
                      className="card-table table-vcenter text-nowrap table-hover "
                    >
                      <Table.Header>
                        <Table.Row>
                        {this.state.header.map((item, i) =>
                          this.state.user.level==='write' ? 
                            <Table.ColHeader key={i} className = {item.class} style={{ textTransform: 'none'}}>{item.name}</Table.ColHeader>
                          : item.name.toUpperCase()!=='ACTION' && <Table.ColHeader key={i} className = {item.class} style={{ textTransform: 'none'}}>{item.name}</Table.ColHeader>
                        )}
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        { this.state.dataShow.length ? this.state.dataShow.map( (item, i) =>
                          <Table.Row key={ i }>
                            <Table.Col className="align-middle table-sticky-left">
                              { i+1}
                            </Table.Col>
                            <Table.Col className="text-center align-middle">
                              <img 
                                alt=""
                                src={item.path}
                                //src={"/products/"+item.image}
                                // "/products/คาราบาวแดง.png"
                                className="h-8"
                              />
                            </Table.Col>
                            <Table.Col className="align-middle">
                              {item.name}
                            </Table.Col>
                            <Table.Col className="text-center align-middle">
                              { item.price }
                            </Table.Col>
                            <Table.Col className="text-center align-middle">
                              { item.channel }
                            </Table.Col>
                            <Table.Col className="text-center align-middle">
                              { item.count }
                            </Table.Col>
                            <Table.Col className="text-center align-middle">
                              { item.max }
                            </Table.Col>
                            <Table.Col className="text-center align-middle">
                              { item.status }
                            </Table.Col>
                            { this.state.user.level==='write' && <Table.Col className="text-center align-middle table-sticky-right">
                              <Button.List>
                                <Button size="sm" color="info"
                                  onClick={ () => this.edit(item) }
                                  >
                                  Edit
                                </Button>
                                <Button size="sm" color="danger" 
                                  onClick={ ()=> { this.alert({action:'delete', id: item._id, name: item.name, i: i }) } } >
                                  Delete
                                </Button>  
                              </Button.List>
                            </Table.Col>
                            }
                          </Table.Row>
                        ) : <Table.Row > 
                            <Table.Col colSpan="12"	alignContent="center">
                              <Text className="my-4" size="" center="true" color="primary" >ไม่พบข้อมูล : Data not found.</Text>
                            </Table.Col>
                          </Table.Row>
                        }

                        <Table.Row > 
                        <Table.Col colSpan="12"	alignContent="center">
                          <div style={paginationStyle}>
                            <Pagination>
                              { this.state.paginationItem.map( number =>
                                <Pagination.Item key={number} active={number === this.state.paginationActive} onClick={ this.paginationActive.bind(this, number) }>
                                  {number} 
                                </Pagination.Item>
                              )}
                            </Pagination>
                          </div>      
                        </Table.Col>
                      </Table.Row>
                      </Table.Body>
                    </Table>
                  {/* </Card.Body> */}
                </Card>
              </Grid.Col>
            </Grid.Row>
          </Page.Content>
        
        <ModalFormLarge
          show={this.state.modalAddbranchShow}
          onHide={this.modalClose}
          onSave={this.getForm}
          onInputChange={this.handleInputChange}
          title={this.state.modalFormTitle}
          worning={this.state.modalWorning}
          form={this.state.modalForm}
          selectFileClick = {this.selectFileClick}
          imageOnclicks = {this.imageOnclicks}
          defaultFileUpload = {this.state.defaultFileUpload}
          isButtonSelectFileEnable={this.state.isButtonSelectFileEnable}
          isFilePondEnable={this.state.isFilePondEnable}
        />

        <ModalFormSmall
        show={this.state.modalSmallShow}
        onHide={this.modalSmallClose}
        onSave={this.saveBackup}
        onInputChange={this.handleInputChange}
        title={this.state.modalFormTitle}
        worning={this.state.modalWorning}
        form={this.state.modalForm}
        />

        {this.state.alert}

        <SweetAlert
        warning
        show={this.state.alertShow}
        title="Oops!"
        onConfirm={() => this.setState({ alertShow: false })}
      >
        {this.state.alertText}
      </SweetAlert>
       
      </SiteWrapper>   
      
    )
    
  }
}

export default branchSetting;