import React, { Component } from 'react'
import axios from 'axios'
import {CSVLink} from "react-csv";
import { Button } from "tabler-react";
import SweetAlert from 'react-bootstrap-sweetalert'
import * as XLSX from "xlsx";

class ExportToExcel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      headerShow:[],
      dataToDownload: [],
      fileName: "test.csv",
      isButtonExportLoading:false,
      alertShow: false,
      alertText: '',
      wscols : [{wch:12},{wch:15},{wch:15},{wch:15},{wch:15},{wch:15},{wch:15},{wch:15},{wch:15},{wch:15},{wch:15},{wch:15},{wch:15},{wch:15},{wch:15}]
    }
  }


  // componentDidMount() {
  //   console.log("parameters =>",this.props.parameters)
  //   console.log("isButtonDisabled =>",this.props.isButtonDisabled)
  // }

//  ExportToExcel = ({ apiData, fileName }) => {
//     const fileType =
//       "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
//     const fileExtension = ".xlsx";
  
//     const exportToCSV = (apiData, fileName) => {
//       const ws = XLSX.utils.json_to_sheet(apiData);
//       const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
//       const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
//       const data = new Blob([excelBuffer], { type: fileType });
//       FileSaver.saveAs(data, fileName + fileExtension);
//     };
  
//     return (
//       <button onClick={(e) => exportToCSV(apiData, fileName)}>Export</button>
//     );
//   };

  // exportToExcel = (excelData, fileName, wsCols) => {
  //        const ws = XLSX.utils.json_to_sheet(Heading, {
  //          header: ["identifier", "new_players", "bet", "win", "margin", "players_played", "play_sessions", "operator_total", "company_total"],
  //          skipHeader: true,
  //          origin: 0 //ok
  //        });
     
  //        ws.A1.s = {
  //          font: {sz: 14, bold: true, color: '#FF00FF'}
  //        };
     
  //        ws["!cols"] = wsCols;
  //        XLSX.utils.sheet_add_json(ws, excelData, {
  //          header: ["identifier", "new_players", "bet", "win", "margin", "players_played", "play_sessions", "operator_total", "company_total"],
  //          skipHeader: true,
  //          origin: -1 //ok
  //        });
  //        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
     
  //        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  //        const data = new Blob([excelBuffer], { type: fileType });
  //        FileSaver.saveAs(data, fileName + fileExtension);   
  // };

  exportCSV = () => {
    //console.log("this.props.parameters =>",this.props.parameters)
    let THIS = this
    let values = this.props.parameters.values
    values["headerShow"] = this.props.parameters.headerShow
    if(this.props.parameters.books !== undefined){
      values["books"] = this.props.parameters.books
    }
    THIS.setState({isButtonExportLoading:true})
    axios({
      method: this.props.parameters.method,
      // url: 'http://13.251.52.58:3001/api/v1/report/topup',//url: 'http://13.251.52.58:3001/api/v1/report/stock',
      url: this.props.parameters.urls,
      data: values,
      headers:{
        "Authorization":this.props.parameters.token
      }
      //withCredentials: true
    })
    .then(function (response) {
      //console.log("response =>",response.data)
      if(response.data.value){
        let dataToDownload = response.data.value 
        if((response.data.value.resultCount !== undefined) && (response.data.value.resultCount >= 0)){
          if(response.data.value.result !== undefined){
            dataToDownload = response.data.value.result;
          }      
        }

        if(response.data.headerCsv !== undefined ){
          let headerCsv = response.data.headerCsv 
          //console.log(headerCsv)
          dataToDownload = headerCsv.concat( response.data.value);
        }

        if(response.data.total){
          dataToDownload.push(response.data.total)
        }
        if(response.data.foottersummary){
          dataToDownload.push(response.data.foottersummary)
        }
        if(response.data.sumRabbit){
          dataToDownload.push(response.data.sumRabbit)
        }
        if(response.data.sumLinepay){
          dataToDownload.push(response.data.sumLinepay)
        }
        if(response.data.sumCash){
          dataToDownload.push(response.data.sumCash)
        }
        if(response.data.sum){
          dataToDownload.push(response.data.sum)  
        }
        let d = new Date().toISOString().replace(/[-T:.]/g, "_")
            d = d.replace(/[Z]/g, "")
        let fileName = ""
        if(THIS.props.parameters.nameOfReport !== undefined){
          fileName = THIS.props.parameters.nameOfReport+d 
        }else{
          fileName = "Report"+d
        }
        fileName = fileName.split(' ').join('_')

        THIS.setState({ dataToDownload: dataToDownload ,fileName:fileName,isButtonExportLoading:false}, () => {
          // click the CSVLink component to trigger the CSV download
          //THIS.csvLink.link.click()

        //   const fileType =
        //   "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        //   const fileExtension = ".xlsx";
        //   const ws = XLSX.utils.aoa_to_sheet(dataToDownload);
        //     var wscols = [
        //       {wch:6},
        //       {wch:15},
        //       {wch:15},
        //       {wch:15},
        //       {wch:15},
        //       {wch:15},
        //       {wch:20}
        //   ];
        
        // //ws['!cols'] = wscols;
        //   ws['!cols'] = THIS.props.parameters.books[0].wscols;
        //   const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        //   const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        //   const data = new Blob([excelBuffer], { type: fileType });
        //   FileSaver.saveAs(data, fileName + fileExtension);


          // var ws_data = [
          //   ["Column 1"],
          //   [1]
          // ];


          const fileExtension = ".xlsx";
          if(response.data && response.data.dataMulltiSheet !== undefined){
            let wb = XLSX.utils.book_new();
            let dataMulltiSheet = response.data.dataMulltiSheet;
            //console.log("dataMulltiSheet =>",dataMulltiSheet)
            //console.log("dataToDownload =>",dataToDownload)
            if(dataMulltiSheet.length){
              for(let i = 0; i !== THIS.props.parameters.books.length; ++i){
                //console.log("i =>",i)
                //console.log("dataMulltiSheet[",i,"] =>",dataMulltiSheet[i])
                let ws = XLSX.utils.aoa_to_sheet(dataMulltiSheet[i]);
                ws['!cols'] = THIS.props.parameters.books[i].wscols;
                XLSX.utils.book_append_sheet(wb, ws, THIS.props.parameters.books[i].sheetName);
                if(i === (THIS.props.parameters.books.length-1)){
                  XLSX.writeFile(wb, fileName + fileExtension);
                }
              }
            }
          }else{
            if(dataToDownload.length){
              //console.log("dataToDownload =>",dataToDownload)
              let wb = XLSX.utils.book_new();  
              let ws = XLSX.utils.aoa_to_sheet(dataToDownload);
              ws['!cols'] = THIS.state.wscols;
              XLSX.utils.book_append_sheet(wb, ws, "data");
              XLSX.writeFile(wb, fileName + fileExtension);
            }
          }


          // const fileExtension = ".xlsx";
          // var wb = XLSX.utils.book_new();
          // var ws = XLSX.utils.aoa_to_sheet(dataToDownload);
          // ws['!cols'] = THIS.props.parameters.books[0].wscols;
      
          // var ws2 = XLSX.utils.aoa_to_sheet(dataToDownload);
          
          // XLSX.utils.book_append_sheet(wb, ws, THIS.props.parameters.books[0].sheetName);
          // XLSX.utils.book_append_sheet(wb, ws2, THIS.props.parameters.books[1].sheetName);
          
          // XLSX.writeFile(wb, fileName + fileExtension);

          // //var wb = XLSX.utils.book_new();
          // for(let i = 0; i < THIS.props.parameters.books.lenght; i++){
          //   //const wb = XLSX.utils.book_new();
          //   var ws = XLSX.utils.aoa_to_sheet(dataToDownload);
          //   ws['!cols'] = THIS.props.parameters.books[0].wscols;
          //   var wb = XLSX.utils.book_new();
          //   XLSX.utils.book_append_sheet(wb, ws, THIS.props.parameters.books[i].sheetName);
          //   //if(i === (THIS.props.parameters.books.lenght-1)){
          //     XLSX.writeFile(wb, fileName + fileExtension);
          //   //}
          // }
          
          
        })
      }else{
        THIS.setState({
          isButtonExportLoading:false
        })
      }
    })
    .catch(function (error) {
      THIS.setState({
        isButtonExportLoading:false,
        alertShow: true,
        alertText: error.message
      })
      console.log('axios error =>',error.message);
    });
  }

  render() {
    //console.log("render =>",this.state)
      return( 
      <div>
        <CSVLink
          headers = {this.state.headerShow}
          data={this.state.dataToDownload}
          filename= {this.state.fileName}
          //className="hidden"
          ref={(r) => this.csvLink = r}
          target="_blank" 
          />
          {                
            ( this.state.isButtonExportLoading ? <Button 
                color="green" 
                loading
                >Export CSV
              </Button> : <Button 
                color="green"
                onClick={this.exportCSV}
                disabled={this.props.isButtonDisabled}
                >Export CSV
              </Button>
            )
          }
          <SweetAlert
            warning
            show={this.state.alertShow}
            title="Oops!"
            onConfirm={() => this.setState({ alertShow: false })}
          >
            {this.state.alertText}
          </SweetAlert>
      </div>
      )
  }
}

ExportToExcel.defaultProps = {
  parameters:{
    urls:"http://13.251.52.58:3001/api/v1/report/topup",
    method:"post",
    values:{},
    token:"",
    query:"",
    headerShow:[],
    headers:[],
    nameOfReport:"Report"
  },
  isButtonDisabled:true
}

export default ExportToExcel;