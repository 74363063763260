import React,{Component} from 'react'
import { Modal } from  'react-bootstrap';
import { Button, Form, Grid, Text, StoreCard, Card, Icon, Avatar, Profile } from "tabler-react";
import Select from 'react-select';
import ImageUploader from 'react-images-upload';
import DatePicker from "react-datepicker";
import { Tree } from 'antd';
import "antd/dist/antd.css";

const { TreeNode } = Tree;

class ModalFormLarge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // form: {

      // }, 
      isButtonDisabled: true,
      pictures: [],
      
      //tree
      expandedKeys: [],
      autoExpandParent: true,
      checkedKeys: [],
      selectedKeys: [],

    };
    // this.handleClick = this.handleClick.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.onDrop = this.onDrop.bind(this);
    //console.log('ModalAddUser props =>', props)
  }

	onDrop(pictureFiles, pictureDataURLs) {
		this.setState({
            pictures: this.state.pictures.concat(pictureFiles),
        });
	}

  enableButonSave(){
    if(this.state.isButtonDisabled == true){
      console.log('isButtonDisabled ->',this.state.isButtonDisabled)
      this.setState({
        isButtonDisabled: false
      })
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    // console.log("handleChange  =>", name, value, this.state.form);
    this.enableButonSave()
    if(this.props.onInputChange){
      this.props.onInputChange(event)
    }
  }

  onSave = () => {
    // first set the isButtonDisabled to true
    this.setState({
      isButtonDisabled: true
    });
    if(this.props.onSave()){
      this.props.onSave() 
    }
    // then do your thing
  }

  handleChangeDate = (dataIn) => {
    // let start = new Date('2019-01-29T08:08:09.000Z') //test
    console.log("date",dataIn);
    let event = { target: {type: dataIn.type, value: dataIn.value, name: dataIn.name} }
    this.enableButonSave()
    if(this.props.onInputChange){
      this.props.onInputChange(event)
    }
  }

  // inputRender = (input) => {
  //   if(input.type=='input') {
  //     return <Form.Group label={input.name}>
  //       <Form.Input
  //         name={input.name}
  //         placeholder={input.placeholder}
  //         onChange={this.handleInputChange}
  //         value={input.value}
  //         disabled={input.disabled} 
  //       />
  //     </Form.Group>
  //   } else {
  //     return <Form.Group label={input.name}>
  //       <Form.Select name={input.name} onChange={this.handleInputChange} disabled={input.disabled} >                
  //         { input.items.map( (item, i) => 
  //           <option> {item} </option>
  //         )}
  //       </Form.Select>
  //     </Form.Group>
  //   }
  // }

  inputRender1 = (input,index) => {
    //console.log('input.col ->',input.col)
    // if(input.col == '1'){
      switch(input.type){
        case 'input':
            return <Form.Group label={input.name} key={index}>
              <Form.Input
                name={input.name}
                placeholder={input.placeholder}
                onChange={this.handleInputChange}
                value={input.value}
                disabled={input.disabled} 
              />
            </Form.Group>
        break
        case 'select':
          return <Form.Group label={input.name} key={index}>
            <Form.Select name={input.name} onChange={this.handleInputChange} disabled={input.disabled} >   
              <option> {input.value} </option>             
              { input.items.map( (item, i) => 
                <option key={i}> {item} </option>
              )}
            </Form.Select>
          </Form.Group>
        break
        case 'image':
            // return <StoreCard
            //           title={input.value}
            //           subtitle=""
            //           // imgUrl="https://tabler.github.io/tabler/demo/products/apple-iphone7-special.jpg"
            //           imgUrl={"/products/"+input.value}
            //         />
          return(
            <Card key={index}>
              <Card.Body>
                <div className="mb-4 text-center">
                  <img src={input.path+input.value} alt="" 
                      style={{
                      flex: 1,
                      alignSelf: 'stretch',
                      width: "50%",
                      height: "50%"
                    }}
                  />
                </div>
                <Card.Title>{input.value}</Card.Title>
                <div className="mt-5 d-flex align-items-center">
                  <div className="ml-auto">
                    <Button color="primary">
                      <Icon prefix="fe" name="plus" 
                      onClick={() => this.props.onSave()}
                    />
                      Add Image
                    </Button>
                  </div>
                </div>
              </Card.Body>
            </Card>
            // <ImageUploader
            //     	withIcon={true}
            //     	buttonText='Choose images'
            //     	onChange={this.onDrop}
            //     	imgExtension={['.jpg', '.gif', '.png', '.gif']}
            //     	maxFileSize={10242880}
            // />
          )
        break
        case 'date':
            return <Form.Group label={input.name} key={index}>
            <DatePicker
            selected={this.state.startDate}
            selectsStart
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dateFormat="dd/MM/yyyy"
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            // onChange={this.handleChangeStart.bind(this)}
            className="form-control"
            />
          </Form.Group>
        break
        case 'textarea':
          return <Form.Group label={input.name} key={index}>
            <Form.Textarea
              //defaultValue=""
              name={input.name}
              placeholder={input.placeholder}
              rows={input.rows}
              onChange={this.handleInputChange} 
              disabled={input.disabled}
              value={input.value}
            />
          </Form.Group>
          break
        case 'select-multi':
          // console.log('input =>', input)
          let defaultValue = []
          let options = []
          input.items.map( (item, i) => {
            options.push({ value: item, label: item })
          })
          input.value.map( (item, i) => {
            defaultValue.push({ value: item, label: item })
          })
    
          return <Form.Group label={input.name} key={index}>
            <Select
              closeMenuOnSelect={false}
              //components={makeAnimated()}
              isMulti
              defaultValue={defaultValue}
              options={options}
              onChange = {this.changValue} // {this.props.handleInputChange}
              name={input.name} 
            />
          </Form.Group>
          break
        case 'date-picker':
          return  <Form.Group label={input.name} key={index}><DatePicker
            selected={input.value}
            showTimeSelect
            timeFormat="HH:mm"
            dateFormat="dd/MM/yyyy HH:mm"
            onChange={ (date) => this.changeDate(date, input.name) }
            className="form-control"
          /></Form.Group>
          break
        case 'checkbox':
          return  <Form.Group key={index}>
              { input.value ?
                <Form.Checkbox
                  checked
                  label={input.name}
                  name={input.name}
                  value={input.value}
                  onChange={this.handleInputChange} 
                />  
                :
                <Form.Checkbox
                  label={input.name}
                  name={input.name}
                  value={input.value}
                  onChange={this.handleInputChange} 
                />    
              }
               
            </Form.Group>
          break
        case 'tree':        
          return  <Form.Group label={input.name} key={index}>
            <Tree
              checkable
              onExpand={input.tree.onExpand}
              expandedKeys={input.tree.expandedKeys}
              autoExpandParent={input.tree.autoExpandParent}
              onCheck={ (c) => { this.enableButonSave(); input.tree.onCheck(c)} } //{input.tree.onCheck}
              checkedKeys={input.tree.checkedKeys}
              onSelect={input.tree.onSelect}
              selectedKeys={input.tree.selectedKeys}
            >
              {this.renderTreeNodes(input.tree.treeData)}
            </Tree>
          </Form.Group>
          break
        case 'avatar':
          return  <Form.Group label={input.name} key={index}>
            <Card>
              <Card.Body className="mx-auto">             
                <Avatar size="xxl" imageURL={input.value} />
              </Card.Body>
            </Card>
          </Form.Group>
          break
        case 'radios':
          return  <Form.Group label={input.name} key={index}>
            { input.items.map( (radio, indexs) =>
              <Form.Radio
                checked={input.value==radio.value? true : false}
                key={indexs}
                isInline={radio.isInline}
                label={radio.label}
                name={radio.name}
                value={radio.value}
                onChange={this.handleInputChange}
              />
            )}
          </Form.Group>
          break
          default:
          break
      }
    // }
  }

  renderTreeNodes = data =>
    data.map(item => {
      if (item.children) {
        return (
          <TreeNode title={item.title} key={item.key} dataRef={item}>
            {this.renderTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return <TreeNode key={item.key} {...item} />;
    });

  render() {
    // console.log('this.props.form =>', this.props.form)
    return (
      <Modal
        {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Form onSubmit={(event) => console.log(event.target.name + 'clicked')}>
        <Modal.Header >
          <Modal.Title >
            { this.props.title }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body> 
          <Grid.Row>
            <Grid.Col lg ={6}>   
              { this.props.form.map( (input, i) => 
                (input.col==1) && this.inputRender1(input,i)
              )}
            </Grid.Col>     
            <Grid.Col lg={6}>   
              { this.props.form.map( (input, i) => 
                (input.col==2) && this.inputRender1(input,i)
              )}
            </Grid.Col>         
          </Grid.Row>
        </Modal.Body>
        <Modal.Footer >
          <div className="mr-auto ml-auto">
            <Text color="orange" >{this.props.worning}</Text>
          </div>          
          <Button type="button" onClick={ this.props.onHide } >Close</Button>
          {this.props.onSave && 
            <Button 
              color="success" 
              type="button" 
              onClick={ () => {
                this.onSave()  
                } 
              } 
              disabled={this.state.isButtonDisabled}
              >
              Save
            </Button>
          }
        </Modal.Footer>
        </Form>
      </Modal>
    );
  }

}

ModalFormLarge.defaultProps = {
  title: 'ModalFormLarge',
  worning: "",
  form: [],
  
}

export default ModalFormLarge