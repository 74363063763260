// @flow
  import React,{Component} from 'react'
  import { Page, Grid, Card, Text, Table, Button, Form } from "tabler-react";
  import SiteWrapper from "../components/SiteWrapper.react";
  import axios from 'axios';
  //import ModalAddUser from "../components/ModalAddUser";
  //import ModalFormLarge from "../components/ModalFormLarge";
  import ModalFormLarge from "../components/ModalFormImage";
  //import ModalUpload from "../components/ModalUpload";
  //import ModalFormLarge from "../components/ModalFormSmall";
  import SweetAlert from 'react-bootstrap-sweetalert'
  import Pagination from 'react-bootstrap/Pagination'
  import storeGlobal from "../components/globalStore";
  //import SelectBranchs from "../components/SelectBranchs";
  import SelectBranchs from "../components/SelectBranchsStateLess";
  //import LoadingOverlay from 'react-loading-overlay'
  // import SyncLoader from 'react-spinners/SyncLoader'
  import { Redirect } from "react-router-dom";
  import Moment from 'react-moment';
  import ShortUniqueId from 'short-unique-id';
  
  class prescription extends Component{
    constructor(props) {
      super(props);
      this.state = {
        user: storeGlobal.getusers(),

        modalWorning:'',
        modalFormTitle:"",
        //header:['IMAGE','NAME','PRICE','STATUS','ACTION'],
        header:[
          {"name":"ITEM", "class":"text-center table-sticky-left"},
          {"name":"ORDER ID", "class":"text-center"},
          {"name":"STUDENT/EMPLOYEE ID", "class":"text-center"},
          {"name":"NAME", "class":"text-center"},
          {"name":"LAST NAME", "class":"text-center"},
          {"name":"EXPIRATION DATE", "class":"text-center"},
          {"name":"STATUS", "class":"text-center"},
          {"name":"PRESCRIBER", "class":"text-center"},
          {"name":"ACTION", "class":"text-center table-sticky-right"}
        ],
        tempModalForm:{},
        modalFormSave:[],
        modalForm: [
          { key: "branchID", name: "ID", placeholder:"", value: "", disabled: false, type: 'image', col: '2' }, 
          { key: "branchID", name: "ID", placeholder:"", value: "", disabled: false, type: 'input', col: '2' }, 
          { key: "branch", name: "Name", placeholder:"", value: "", disabled: false, type: 'input',col: '2' }, 
          { key: "location", name: "Location", placeholder:"", value: "", disabled: false, type: 'input',col: '2' }, 
          { key: "project", name: "Project", placeholder:"", value: "", disabled: false, type: 'select',col: '2', items: [
            'p1', 'p2', 'p3'
          ] }, 
        ],
        products:[],
        usersRes: [],

        alert: null,
        paginationItem: [1],
        paginationActive: 1,
        countPerPage:10,
        organizItems:[],
        projectItems:[],
        dataResponse:{products:[]},
        dataShow:[],

        btDeployDisnabled: true,

        modalUploadShow: false,
        defaultFileUpload:{
          organization:'',
          uri:window.url + 'upload',
          type:'products',
          filetype:['image/png', 'image/jpeg']
        },
        fileUpload: [{fileName:'a.zip', path:'/upload/a.zip'}, {fileName:'b.zip', path:'/upload/b.zip'}],
        lastFileUpload:"",
        alertShow: false,
        alertText: '',

        redirect: false,
        location: {},

        searchText: '',
        searchButton: true,
        deployText: 'Synchronize',
        syncText: 'Success',
        isButtonSelectFileEnable: true,
        isFilePondEnable: true,

        optionOrganizations: null,
        optionProjects: null,
        optionBranchs: null,
        typeSelectFilter:"useSelectDateOrgProj"
      }
    }

    findPrescriber = () =>{     
      let users = this.state.user
      if(!(Object.entries(users).length === 0)){
        let THIS = this     
        let organization = users.OrganizationSelect
        axios({
          method: 'get',
          url: window.url + 'api/v1/users/' + organization,
          data: 'values',
          headers:{
            "Authorization": users.token
          }
        })
        .then(function (response) {
          //console.log("response =>",response)
          let PrescriberOpt = [{label:"All",value:"All"}] 
          for(let i = 0; i < response.data.value.length; i++){
            if(response.data.value[i].role === 'prescriber'){
              PrescriberOpt.push({label:response.data.value[i].username,value:response.data.value[i].username})    
            }
          }
          THIS.setState({
            labelSelectName4: "Prescriber",
            Collections4: PrescriberOpt  
          })
        })
        .catch(function (error) {
          THIS.setState({
            alertShow: true,
            alertText: error.message
          })
          console.log('axios error =>',error.message);
        });
      }
    }

    componentDidMount() {
      let users = storeGlobal.getusers();
      if(users && users.username){
        let start = new Date()
        start.setHours(0); start.setMinutes(0); start.setSeconds(0)
        let end = new Date()
        end.setHours(23); end.setMinutes(59); end.setSeconds(59);
  
        let organization = []
        for(var i = 0; i < this.state.user.organization.length; i++){
          organization.push({label:this.state.user.organization[i],value:this.state.user.organization[i]})  
        }
  
        let branchID = [{label:"All",value:"All"}]
        for(var j = 0; j < this.state.user.branch.length; j++){
          branchID.push({label:this.state.user.branch[j].branchID,value:this.state.user.branch[j].branchID});
        }
  
        let project = [{label:"All",value:"All"}]
        for(let i = 0; i < this.state.user.project.length; i++){
          if(organization[0].value === this.state.user.project[i].organization){
            project.push({label:this.state.user.project[i].projectName,value:this.state.user.project[i].projectName})    
          }
        }

        let personnelOpt = [
          {label:"Prescriber",value:"prescriber"},
          {label:"Student",value:"student"},
          {label:"Employee",value:"employee"}
        ]

        let PrescriberOpt = [{label:"All",value:"All"}]
  
        let dataCallback = {
          BranchsSelect: this.state.user.branch, 
          ProjectSelect: this.state.user.ProjectSelect,
          BranchidSelect: this.state.user.branchID,
          OrganizationSelect: this.state.user.OrganizationSelect,
          startDate:start,
          endDate:end
        }
        // console.log('dataCallback1 =>', dataCallback)
        let allFillter = storeGlobal.getAllFilter();
        // console.log('allFillter =>', allFillter);

        if (JSON.stringify(allFillter) !== '{}') {
          if (allFillter.buttonBack === true) {
            allFillter.buttonBack = false;
            dataCallback = JSON.parse(JSON.stringify(allFillter));
            storeGlobal.setAllFilter(allFillter);
          }
        }
  
        this.setState({
          OrganizationSelect: users.OrganizationSelect,
          optionOrganizations : organization,
          optionProjects:project,
          optionBranchs:branchID,
          labelSelectName: "Select personnel",
          Collections: personnelOpt,
          labelSelectName4: "Prescriber",
          Collections4: PrescriberOpt,
          studenId: null
        },() => {
          this.getbranchs(dataCallback)
          this.findPrescriber()
        }
        )
      }
    }

    getbranchs = (dataCallback) => {
      // console.log('dataCallback -->', dataCallback)
      storeGlobal.setAllFilter(dataCallback);
      let users = this.state.user
      if(!(Object.entries(users).length === 0)){
        let THIS = this
        let organization = dataCallback.OrganizationSelect
        let ProjectSelect = dataCallback.ProjectSelect
        let CollectionsSelect = dataCallback.CollectionsSelect
        let CollectionsSelect4 = dataCallback.CollectionsSelect4
        let Filter = dataCallback.Filter

        let defaultFileUpload = this.state.defaultFileUpload
        defaultFileUpload['organization'] = organization 
        this.setState({defaultFileUpload:defaultFileUpload})
        //let id = this.props.location.state.id
        let query = {
          date:{ 
          '$gte': dataCallback.startDate,
          '$lt': dataCallback.endDate 
          }
        }
        if(ProjectSelect !== null){
          query['project'] = ProjectSelect
        }
        if(CollectionsSelect === "prescriber"){
          if(CollectionsSelect4 !== "All"){
            query['createBy'] = CollectionsSelect4  
          }
          this.setState({
            studenId: null
          })
        }else if(CollectionsSelect === "student" || CollectionsSelect === "employee"){
          if(Filter !== ""){
            query['studentId'] = Filter  
          }
          this.setState({
            labelSelectName4: null
          })
        }
        let keyChangeIsoDate = ['date']
        let values = {
          query,
          keyChangeIsoDate,
          "sortNatural": "-1",
          "countSkip":"0",
          "countLimit": "0",
          "projection":{}
        }

        axios({
          method: 'post',
          url: window.url + 'api/v1/collection/' + organization + '/order/query',
          data: values,
          headers:{
            "Authorization": this.state.user.token
          }
        })
        .then(function (response) {
          //console.log("response -->",response.data.value);
          THIS.setState({
            dataResponse: response.data.value.result,
            usersRes: response.data.value.result,
          })

          let products = response.data.value.result
          THIS.paginationItemCal(products,1)
        })
        .catch(function (error) {
          THIS.setState({
            alertShow: true,
            alertText: error.message
          })
          console.log('axios error =>',error.message);
        });
      }
    }
  
    handleClick(e) {
      console.log('this ->', e)
    }
  
    hideAlert() {
      this.setState(state => ({
        alert: null
      }));
    }
    
    alert(e) {
      // console.log(e)
      switch(e.action) {
        case 'delete':
          this.setState( state => ({
            alert: (
              <SweetAlert
                warning
                showCancel
                confirmBtnText="Yes, delete it!"
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="default"
                title="Are you sure?"
                onConfirm={ ()=> {
                    this.deleteDb(e.item,(fn) =>{ 
                      if(fn) console.log('delete error ->',fn) 
                    })
                  } 
                } //{this.deleteFile}
                onCancel={ this.hideAlert.bind(this) }
                >
                Delete "{e.item.orderId}"
              </SweetAlert>
            )
          }));
          break
          default:
          break
      }
      
    } 

    CollectionsChang = (dataCallback) => {
      //console.log("dataCallback =>",dataCallback)
      if(dataCallback.value === "prescriber"){
        this.setState({
          studenId: null,
          labelSelectName4: "Prescriber",
        })
      }else{
        this.setState({
          labelSelectName4: null,
          studenId: "Studen/employee Id"
        })
      }
    }
    
    
    deleteDb = (dataIn,fn) => {
      //const uuidv1 = require('uuid/v1');
      //console.log('uuidv1 ->',uuidv1());
      //+-console.log("deleteDb this.state.user =>",this.state.user)
      if(this.state.user){
        console.log('deleteDb dataIn ->', dataIn);  
        let organization  = this.state.user.OrganizationSelect
        let collection = 'order'
        let id = dataIn._id
        let THIS = this
        let values =  { user: this.state.user }
        //+-console.log('deleteDb values ->', values); 
        //fn(null)
        THIS.hideAlert()
        axios({
          method: 'delete',
          url: window.url + 'api/v1/collection/' + organization + '/' + collection + '/' + id,
          // url: 'http://13.251.52.58:3001/api/v1/users/' + this.state.user.organization,
          data: values,
          headers:{
            "Authorization": this.state.user.token
          }
        })
        .then(function (response) {
          //console.log("deleteDb response =>", response.data); // {oganization:'ppetech',value:{n: 1, ok: 1}}
          let dataSave = {
            "type" : 'warning', //information //warning // error
            "date" : new Date(),
            "surce" : 'PrescriptionMan',
            "category" : 'webApp',
            "even" : 'Delete prescription',
            "user" :  THIS.state.user.username,
            "ip" : "",
            "discription" : 'Delete prescription =>'+ dataIn.name
          }
          THIS.saveLog(dataSave)

          let products = THIS.state.dataResponse
          products = products.filter(person => person._id !== dataIn._id)
          THIS.setState({ 
            dataResponse: products
          },() => {        
            let paginationActive = THIS.state.paginationActive
            THIS.paginationItemCal(products,paginationActive)
          })
          //THIS.hideAlert()
          fn(null)
        })
        .catch(function (error) {
          console.log(error);
          THIS.setState({modalWorning: "Delete error!"})
          //THIS.hideAlert()
          fn(error)
        });
      }else{
        fn({msg:"no user"})  
      }
    }

    insertDb = (dataIn,fn) => {
      //const uuidv1 = require('uuid/v1');
      //console.log('uuidv1 ->',uuidv1());
      //console.log('insertDb dataIn ->', dataIn);  
      //+-console.log("insertDb this.state.user =>",this.state.user)
      if(this.state.user){
        let organization  = this.state.user.OrganizationSelect
        let collection = 'order'
        let THIS = this
        let values = dataIn
            values.createBy = this.state.user.username
            values.updateBy = this.state.user.username
        //console.log('insertDb values ->', values); 
        //fn(null)
        axios({
          method: 'post',
          url: window.url + 'api/v1/collection/' + organization + '/' + collection,
          // url: 'http://13.251.52.58:3001/api/v1/users/' + this.state.user.organization,
          data: values,
          headers:{
            "Authorization": this.state.user.token
          }
        })
        .then(function (response) {
          //+-console.log("insert response =>", response.data); // {oganization:'ppetech',value:[obj]}
          let dataSave = {
            "type" : 'information', //information //warning // error
            "date" : new Date(),
            "surce" : 'PrescriptionMan',
            "category" : 'webApp',
            "even" : 'New Prescription',
            "user" :  THIS.state.user.username,
            "ip" : "",
            "discription" : 'Add new prescription =>'+ values.name
          }
          THIS.saveLog(dataSave)

          let products = THIS.state.dataResponse
          products.push(response.data.value[0])
          //--------------------------cerren last page
          THIS.setState({ 
            dataResponse: products,
          },() => {        
            let page = THIS.state.countPerPage
            let pageCount = Math.ceil(products.length / page)
            //console.log('paginationActive ->',pageCount)
            THIS.paginationItemCal(products,pageCount)
          })
          fn(null)
        })
        .catch(function (error) {
          console.log(error);
          THIS.setState({modalWorning: "insert error!"})
          fn(error)
        });
      }else{
        fn({msg:"no user"})  
      }
    }

    
    updateDb = (dataIn,fn) => {
      //const uuidv1 = require('uuid/v1');
      //console.log('uuidv1 ->',uuidv1());
      //console.log('updateDb dataIn ->', dataIn); 
      //+-console.log("insertDb this.state.user =>",this.state.user)
      if(this.state.user){ 
        let organization  = this.state.user.OrganizationSelect
        let collection = 'order'
        let id = dataIn._id
        let THIS = this
        let values = JSON.parse(JSON.stringify(dataIn));
            values.createBy = this.state.user.username
            values.updateBy = this.state.user.username
        delete values._id
        //console.log('updateDb values ->', values);
        //console.log('updateDb dataIn2 ->', dataIn); 
        //fn(null)
        axios({
          method: 'put',
          url: window.url + 'api/v1/collection/' + organization + '/' + collection + '/' + id,
          // url: 'http://13.251.52.58:3001/api/v1/users/' + this.state.user.organization,
          data: values,
          headers:{
            "Authorization": this.state.user.token
          }
        })
        .then(function (response) {
          //+-console.log("updateDb response =>", response.data); // {n: 1, nModified: 1, ok: 1}
          let dataSave = {
            "type" : 'information', //information //warning // error
            "date" : new Date(),
            "surce" : 'PrescriptionMan',
            "category" : 'webApp',
            "even" : 'Edit Prescription',
            "user" :  THIS.state.user.username,
            "ip" : "",
            "discription" : 'Edit Prescription =>'+ values.name
          }
          THIS.saveLog(dataSave)

          let products = THIS.state.dataResponse
          let newDataIn =[dataIn]
          var newProducts = products.map(obj => newDataIn.find(o => o._id === obj._id) || obj);
          //console.log('res ->',newProducts)
          THIS.setState({ 
            dataResponse: newProducts
          },() => {        
            let paginationActive = THIS.state.paginationActive
            THIS.paginationItemCal(newProducts,paginationActive)
          })
          fn(null)
        })
        .catch(function (error) {
          console.log(error);
          THIS.setState({modalWorning: "updateDb error!"})
          fn(error)
        });
      }else{
        fn({msg:"no user"})  
      }
    }
    
    paginationActive(e) {
      let dataShow = this.state.dataResponse
      this.paginationItemCal(dataShow,e)
    }
    
  
    paginationItemCal(dataIn,paginationActive) {
      //console.log("pageCount ->",dataIn.length,"paginationActive ->",paginationActive)
      let page = this.state.countPerPage
      let pageCount = Math.ceil(dataIn.length / page)
      let tmppaginationActive = this.state.paginationActive 
      let paginationItem = []

      switch (paginationActive){
        case '>':
            tmppaginationActive++;
        break;
        case '<':
            tmppaginationActive--;
        break;
        case '>|':
            tmppaginationActive = pageCount;
        break;
        case '|<':
            tmppaginationActive  = 1;
        break;
        default:
            tmppaginationActive = paginationActive*1
        break;
      }

      if(tmppaginationActive > pageCount){
        tmppaginationActive = pageCount;
      }

      if(pageCount < 6){
        for(let i = 1; i <= pageCount; i++){
            paginationItem.push(i)
        }
      }else{
        if(tmppaginationActive < 4){
          for(let i = 1; i <= pageCount; i++){
            if(i < 6){
              paginationItem.push(i)
            }else if(i === 6){
              paginationItem.push(">")
            }else if(i === 7){
              paginationItem.push(">|")
            }else{
              break;
            }  
          }
        }else if(tmppaginationActive > (pageCount-3)){
          for(let i = 1; i < 8; i++){
            if(i > 2){
              paginationItem.push((pageCount - (7-i)))
            }else if(i === 2){
              paginationItem.push("<")
            }else if(i === 1){
              paginationItem.push("|<")
            }else{
              break;
            }  
          }  
        }else{
          for(let i = 1; i < 10; i++){
            switch(i){
              case 1:
                paginationItem.push("|<")
              break;
              case 2:
                paginationItem.push("<")
              break;
              case 3:
                  paginationItem.push(tmppaginationActive-2)
              break;
              case 4:
                  paginationItem.push(tmppaginationActive-1)
              break;
              case 5:
                paginationItem.push(tmppaginationActive)
              break;
              case 6:
                  paginationItem.push(tmppaginationActive+1)
              break;
              case 7:
                paginationItem.push(tmppaginationActive+2)
              break;
              case 8:
                  paginationItem.push(">")
              break;
              case 9:
                  paginationItem.push(">|")
              break;
              default: 
              break
            }
          }  
        }
      }

      let dataShow = []
      if(dataIn.length){
        for(let i = page * (tmppaginationActive -1); ((i < page*tmppaginationActive) &&  (i < dataIn.length)); i++){
          dataIn[i].index = i+1
          dataShow.push(dataIn[i])
        }
      }

      this.setState({
        dataShow: dataShow,
        paginationItem: paginationItem,
        paginationActive: tmppaginationActive
      })
    }
    
    modalClose = (dataIn) => {
      //+-console.log("modalClose =>",dataIn)
      if(this.state.modalAddbranchShow === true){
        this.setState({ modalWorning: "", modalAddbranchShow: false })
      }
    };

    // modaUploadlClose = (dataIn) => {
    //   console.log("modaUploadlClose =>",dataIn.from)
    //   if(this.state.modalUploadShow === true){
    //     this.setState({modalUploadShow: false})
    //   }
    //   if(this.state.lastFileUpload !== ""){
    //     //console.log('props -->', this.props)
    //     let users = this.state.user
    //     if(!(Object.entries(users).length === 0)){
    //       let THIS = this
    //       let organization = users.organization[0]
    //       //let id = this.props.location.state.id
    //       axios({
    //         method: 'get',
    //         url: window.url + 'api/v1/collection/' + organization + '/' + 'products',
    //         data: 'values',
    //         headers:{
    //           "Authorization": this.state.user.token
    //         }
    //       })
    //       .then(function (response) {
    //         console.log("response -->",response.data);
    //         THIS.setState({dataResponse:response.data.value})
    //         let products = response.data.value
    //         let paginationActive = THIS.state.paginationActive
    //         THIS.paginationItemCal(products,paginationActive)

    //         // check status for enable deploy button
    //         for(let i=0; i<products.length; i++) {
    //           if(products[i].status != 'deploy' && products[i].status != 'sync') {
    //             THIS.setState({
    //               btDeployDisnabled: false
    //             })
    //             break
    //           }
    //         }

    //       })
    //       .catch(function (error) {
    //         console.log(error);
    //         alert("error: " + JSON.stringify(error) );
    //       });
    //     }  

    //     // this.setState({modalWorning: ""})
    //     if(this.state.modalAddbranchShow === false){
    //       this.setState({modalAddbranchShow: true })
    //     }
    //   }else{
    //     // this.setState({modalWorning: ""})
    //     if(this.state.modalAddbranchShow === false){
    //       this.setState({modalAddbranchShow: true })
    //     }
    //   }
    // };

    uploadFileClick = (dataIn) => {
      //+-console.log("uploadFileClick =>",dataIn.from)
      // let modalForm = {
      //   name: '',
      //   fileName: '',
      //   deployDate: '',
      //   status: '',
      //   // role: props.roleAll[0] || '',
      //   branchID: [],
      //   // organization: props.organizationAll[0] || '',
      // }
      // this.setState({ modalWorning: "", modalUploadShow: false })
      this.setState({modalUploadShow:true})
    }

    // dataCallback = (dataIn) => {
    //   console.log("dataCallback =>",dataIn)
    //   this.setState({lastFileUpload:dataIn.name})
    // }

    selectFileClick = (dataIn) => {
      //+-console.log("selectFileClick =>",dataIn)
      let THIS = this
      axios({
        method: 'get',
        url: window.url + 'api/v1/collection/' + this.state.defaultFileUpload.organization + '/upload?_sortNatural=-1'+'&type=products',
        data: 'values',
        headers:{
          "Authorization": this.state.user.token
        }
      })
      .then(function (response) {
        //+-console.log("response -->",response.data);
        if(response.data.value.length){

          //sort product by name 
          let p = response.data.value
          p.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));

          let modalFormTitle = "Select products"
          let modalForm = []
          for(let i = 0; i < response.data.value.length; i++){
            let item = response.data.value[i] 
            let tempObj =  JSON.parse(JSON.stringify(item))
              tempObj.type = 'imageSelect'
              //tempObj.path = tempObj.path
              tempObj.value = tempObj.image
              tempObj.col = 1
              tempObj.cardSelect = false
              if(dataIn.value === tempObj.fileName){
                tempObj.cardSelect = true
              }
              modalForm.push(tempObj)
          }
          //+-console.log("modalForm =>",modalForm)
          THIS.setState({
            modalForm:modalForm,
            modalFormTitle : modalFormTitle
          },() =>{
            THIS.setState({modalAddbranchShow:true})  
          })
        }
      })
      .catch(function (error) {
        THIS.setState({
          alertShow: true,
          alertText: error.message
        })
        console.log('axios error =>',error.message);
      });
    }
   
  
    getForm = () => {
      //const uuidv1 = require('uuid/v1');
      //console.log('uuidv1 ->',uuidv1());
      let modalForm = this.state.modalForm
      //console.log('getForm modalForm =>', modalForm);    
      let dataEdit = this.state.tempModalForm
      //let dataEdit = this.state.modalForm
      for(let k=0; k<modalForm.length; k++) {
        if(modalForm[k].value === '') {
          return this.setState({modalWorning: "Input is blank!"})
        }
        dataEdit[modalForm[k].key] = modalForm[k].value
        if(modalForm[k].key === 'image'){
          dataEdit['path'] = modalForm[k].path 
          dataEdit['image'] = modalForm[k].value
        }
      }
      //console.log("dataEdit =>",dataEdit)
      // if(dataEdit.status === 'New' && this.state.modalFormTitle === 'New product'){
      //   this.insertDb(dataEdit,(calback) =>{
      //     if(calback) console.log('insertDb error ->',calback)
      //     this.modalClose()
      //   })
      // }else{
      //   dataEdit.status = 'Modify'
      //   this.updateDb(dataEdit,(calback) =>{
      //     if(calback) console.log('updateDb error ->',calback)
      //     this.modalClose()
      //   })
      // }

      this.setState({
        btDeployDisnabled: false
      })

    }

    edit = (item) => {    
      //+-console.log('edit item =>',item);
      // item.status = 'Modify'
      let tempModalForm =  JSON.parse(JSON.stringify(item))
      let modalFormTitle = "Modify product"
      let modalForm = [
        { key: "image", name: "image", placeholder:"", value: item.image, disabled: false, type: 'image', path:item.path, col: '1' }, 
        { key: "sku", name: "SKU", placeholder:"", value: item.sku, disabled: true, type: 'input', col: '2' },
        { key: "name", name: "Name", placeholder:"", value: item.name, disabled: false, type: 'input', col: '2' }, 
        { key: "price", name: "Price", placeholder:"", value: item.price, disabled: false, type: 'input', col: '2' }, 
        // { key: "group", name: "Group", placeholder:"", value: item.group, disabled: false, type: 'input', col: '2' }, 
        // { key: "channel", name: "Channel", placeholder:"", value: item.channel, disabled: true, type: 'input', col: '2' }, 
        // { key: "count", name: "Cerrent Value", placeholder:"", value: item.count, disabled: true, type: 'input', col: '2' }, 
        // { key: "max", name: "Max Value", placeholder:"", value: item.max, disabled: true, type: 'input', col: '2' }, 
        // { key: "max", name: "Max Value", placeholder:"", value: item.max, disabled: true, type: 'input', col: '2' }, 
      ]
      // console.log('modalForm =>', modalForm);
      this.setState({ 
        tempModalForm: tempModalForm,
        modalForm: modalForm,
        modalFormSave: modalForm,
        modalFormTitle: modalFormTitle,
        modalFormTitleSave: modalFormTitle,
        modalAddbranchShow: true,
        isButtonSelectFileEnable: false,
        isFilePondEnable: true
      })
      
    }

    add = () => {    
      //console.log('addBranch item =>');
      let tempModalForm = {
        name : "",
        lastName : "",
        studentId : "",
        order : "",
        pxList : [],
        expireDate: new Date(),
        status : "New"
      }
      let result = new Date();
      let days = 7;
      result.setDate(result.getDate() + days);
      tempModalForm.expireDate = result
      let modalFormTitle = "New prescription"
      let modalForm = [
        { key: "order", name: "Order", placeholder:"", value: "", disabled: false, type: 'input', col: '1' }, 
        { key: "studentId", name: "Student ID", placeholder:"", value: "", disabled: false, type: 'input', col: '2' },
        { key: "name", name: "Name", placeholder:"", value: "", disabled: false, type: 'input', col: '1' }, 
        { key: "lastName", name: "Last name", placeholder:"", value: "", disabled: false, type: 'input', col: '2' }, 
        // { key: "group", name: "Group", placeholder:"", value: "", disabled: false, type: 'input', col: '2' }, 
        // { key: "channel", name: "Channel", placeholder:"", value: "", disabled: false, type: 'input', col: '2' }, 
        // { key: "count", name: "Cerrent Value", placeholder:"", value: "", disabled: false, type: 'input', col: '2' }, 
        // { key: "max", name: "Max Value", placeholder:"", value: "", disabled: false, type: 'input', col: '2' }, 
      ]
      // console.log('modalForm =>', modalForm);
      this.setState({ 
        tempModalForm: tempModalForm,
        modalForm: modalForm,
        modalFormSave: modalForm,
        modalFormTitle: modalFormTitle,
        modalFormTitleSave: modalFormTitle,
        modalAddbranchShow: true,
        isButtonSelectFileEnable: false,
        isFilePondEnable: true
      })
    }
  
    handleInputChange = (event) => {
      const target = event.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const name = target.name;
      let modalForm = this.state.modalForm
      //console.log("handleChange event  =>", event.target);
      // console.log("modalForm  =>", modalForm);
      for(let i=0; i<modalForm.length; i++) {
        if(modalForm[i].name === name) {
          modalForm[i].value = value
          break
        }
      }
  
      this.setState({modalForm: modalForm})
    }

    imageOnclicks = (dataIn) => {
      //+-console.log('dataIn.cardSelect =>',dataIn)
      //+-console.log('tempModalForm =>',this.state.modalFormSave)
      if(dataIn.cardSelect === true){
        this.modalClose()
        let dataEdit = this.state.modalFormSave
        //let dataEdit = this.state.modalForm
        for(let k=0; k < dataEdit.length; k++) {
          if(dataEdit[k].key === 'image'){
            dataEdit[k].value= dataIn.fileName
            dataEdit[k].path= dataIn.path 
          }
        }
        //+-console.log('dataEdit =>',dataEdit)
        this.setState({ 
          modalFormSave: dataEdit,
          modalForm: dataEdit, 
          modalFormTitle: this.state.modalFormTitleSave,
          modalAddbranchShow: true
        })
      }
    }
    
    deploy = () => {      
      let dataResponse = this.state.dataResponse
      let deployDatas = [] //
      for(let i=0; i<dataResponse.length; i++) {
        if(dataResponse[i].status !== this.state.deployText && dataResponse[i].status !== this.state.syncText) {
          dataResponse[i].status = this.state.deployText
          deployDatas.push({
            _id: dataResponse[i]._id,
            status: dataResponse[i].status,
            updateBy: this.state.user.username,
          })
        }
      } 
      // console.log('deploy deployDatas =>', deployDatas); 
      let paginationActive = this.state.paginationActive
      this.paginationItemCal(dataResponse, paginationActive)
      // console.log('deploy deployDatas =>', deployDatas); 
      let THIS = this
      // let t = (new Date()).getTime()

      THIS.setState({
        btDeployDisnabled: true
      })

      // Get all branchID
      axios({
        method: 'get',
        url: window.url + 'api/v1/collection/' + THIS.state.user.OrganizationSelect + '/branch?_show=branchID', //&_t=' + t , //ต้องดู organization ที่ filter
        data: {},
        headers:{
          "Authorization": THIS.state.user.token
        }
      })
      .then(function (response) {        
        let branchs = response.data.value
        for(let i=0; i<branchs.length; i++) {
          branchs[i].type = 'productImage'
          branchs[i].updateBy = THIS.state.user.username
          branchs[i].status = THIS.state.deployText
          branchs[i].deployTime = (new Date()).getTime()
          delete branchs[i]._id
        }
        // console.log("Get branchs =>", branchs); 
        // Insert to deploy collections
        axios({
          method: 'post',
          url: window.url + 'api/v1/collection/' + THIS.state.user.OrganizationSelect + '/deploy/many', 
          data: branchs,
          headers:{
            "Authorization": THIS.state.user.token
          }
        })
        .then(function (response) {
          //+-console.log("Insert deploy response =>", response); 
          axios({
            method: 'patch',
            url: window.url + 'api/v1/collection/' + THIS.state.user.OrganizationSelect + '/products',
            data: deployDatas,
            headers:{
              "Authorization": THIS.state.user.token
            }
          })
          .then(function (response) {
            let dataSave = {
              "type" : 'information', //information //warning // error
              "date" : new Date(),
              "surce" : 'productMan',
              "category" : 'webApp',
              "even" : 'Deploy products',
              "user" :  THIS.state.user.username,
              "ip" : "",
              "discription" : 'Deploy products'
            }
            THIS.saveLog(dataSave)

            // let userAdd = response.data.value[0]
            //+-console.log("patch products response =>", response.data.value); // {n: 1, nModified: 1, ok: 1}
            // THIS.setState({
            //   btDeployDisnabled: true
            // })
          })
          .catch(function (error) {
            console.log('error ->', error);
            THIS.setState({modalWorning: "Update error!"})
            THIS.setState({
              btDeployDisnabled: false
            })
          });
        })
        .catch(function (error) {
          console.log('error ->', error);
          THIS.setState({modalWorning: "Insert deploy error!"})
          THIS.setState({
            btDeployDisnabled: false
          })
        });
      })
      .catch(function (error) {
        console.log('error ->', error);
        THIS.setState({modalWorning: "Get branchID error!"})
        THIS.setState({
          btDeployDisnabled: false
        })
      });
  
    }

    saveLog = (dataIn) => {

      fetch('https://api.ipify.org/?format=json').then(response => {
        return response.json();
      }).then((res) => {
        console.log('res ->', res)
        let user = this.state.user
        let data = dataIn
        data.ip = res.ip
        let organization = this.state.user.OrganizationSelect
        console.log("organization =>", organization)
        // return
        let THIS = this
        axios({
          method: 'post',
          url: window.url + 'api/v1/collection/' + organization + '/log',
          data: data,
          headers:{
            "Authorization": user.token
          }
        })
        .then(function (response1) {
          console.log('response1 ->',organization,'=',response1)
  
        })
        .catch(function (error) {
          console.log(error);
          // alert("error: " + JSON.stringify(error) );
          THIS.setState({ alertText: error.message, alertShow: true})
          THIS.setState({loadingOverlay: false})
        });
      })
    }

    onSearch = () => {
      //+-console.log('onSearch =>', this.state.searchText, this.state.usersRes);
      let search = this.state.searchText;    
      let res = this.state.usersRes.filter(obj => Object.keys(obj).some(key => { 
        key==='price'? obj[key]=obj[key]+'' : obj[key]=obj[key]
        return key!=='_id' && typeof(obj[key])==='string' && key!=='updateBy' && key!=='updateDate' &&  obj[key].includes(search) 
      }))
      this.setState({searchButton: false, branchs: res}, () => {
        let products = res
        let paginationActive = this.state.paginationActive
        this.paginationItemCal(products, paginationActive)
      })
    }
  
    onSearchClear = () => {
      //+-console.log('onSearchClear =>', this.state.searchText);
      this.setState({searchText: '', searchButton: true, branchs: this.state.usersRes}, () => {
        let products = this.state.usersRes
        let paginationActive = this.state.paginationActive
        this.paginationItemCal(products, paginationActive)
      })          
    }
  
    handleKeyPress = (e) => {
      if (e.key === 'Enter') {
        this.onSearch()
      }
    }

    detailPrescription = (item) => {
      // console.log('detailBranch item =>', item);
      let organizationDefault = {label:this.state.user.OrganizationSelect, value:this.state.user.OrganizationSelect}
      let ProjectDefault = [{label:"All", value:"All"}]
      let location = {
        pathname: '/detailprescription',
        state: { 
          id: item._id, 
          organization: organizationDefault,
          project: ProjectDefault
        }
      }

      this.setState({
        location: location,
        redirect: true  
      })
      
      // this.state.location = location
      // this.setState(state => ({
      //   redirect: true
      // }));
    }

    addPrescription = () => {
      // console.log('detailBranch item =>', item);
      let organizationDefault = {label:this.state.user.OrganizationSelect, value:this.state.user.OrganizationSelect}
      let ProjectDefault = [{label:"All", value:"All"}]
      // let orderId = "RX"
      // //console.log("this.state.dataResponse =>",this.state.dataResponse)
      // if(this.state.dataResponse.length){
      //   console.log("this.state.dataResponse[0]",this.state.dataResponse[0].orderId)
      //   let str = this.state.dataResponse[0].orderId.replace(/\D/g, "")
      //   let countUp = (str * 1) + 1
      //   countUp = ('0000000' + countUp).slice(-7)
      //   orderId = orderId + countUp
      // }else{
      //   orderId = orderId + '0000001'
      // }
      // console.log("orderId =>",orderId)


      let users = this.state.user
      if(JSON.stringify(users) !== '{}'){
        let organization = users.OrganizationSelect
        let collection = 'order'
        let THIS = this
        let query = '/?_sortNatural=-1&_limit=1'
        axios({
          method: 'get',
          url: window.url + 'api/v1/collection/' + organization + '/' + collection + query,
          data: 'values',
          headers:{
            "Authorization": users.token
          }
        })
        .then(function (response) {
          console.log("response =>",response.data)
          // let orderId = "RX"   //use old
          // //console.log("this.state.dataResponse =>",this.state.dataResponse)
          // if(response.data.value.length){
          //   console.log("response.data.value[0]",response.data.value[0].orderId)
          //   let str = response.data.value[0].orderId.replace(/\D/g, "")
          //   let countUp = (str * 1) + 1
          //   countUp = ('0000000' + countUp).slice(-7)
          //   orderId = orderId + countUp
          // }else{
          //   orderId = orderId + '0000001'
          // }

          const { randomUUID } = new ShortUniqueId({ length: 4 });
          let str = randomUUID();
          // console.log('str =>',str);
          const d = new Date();
          let time = d.getTime();
          // console.log('time =>',time); //1732695742922
          let timeToStr = time.toString().substring(4, 10);
          // console.log('timeToStr =>',timeToStr);
          let idx = 3;
          let orderId = str.slice(0,2) + timeToStr.slice(0, idx) + str.slice(2,4) + timeToStr.slice(idx);

          console.log("orderId =>",orderId);
          
          let location = {
            pathname: '/detailprescription',
            state: { 
              id: null, 
              orderId: orderId,
              organization: organizationDefault,
              project: ProjectDefault
            }
          }

          THIS.setState({
            location: location,
            redirect: true  
          })
          
          // this.state.location = location
          // this.setState(state => ({
          //   redirect: true
          // }));
        })
        .catch(function (error) {
          console.log(error);
        })
      }
    }

    //=========================================================================== render()
    render() {
      // let modalClose = () => this.setState({ modalAddUserShow: false });
      //console.log('dataResponse ->',this.state.dataResponse);
      const paginationStyle = {
        display: 'table',
        margin: '0 auto',
      };

      if(this.state.redirect) return ( <Redirect to={this.state.location} /> )
  
      return (     
        <SiteWrapper user={this.state.user} props = {this.props}>
          <>  
          <Page.Content title="prescription"
            options={ this.state.usersRes.length &&
              <Form.Group label="">
                <Form.InputGroup>
                  <Form.Input 
                    placeholder="Search for..." 
                    onChange={e => this.setState({searchText: e.target.value, searchButton: true}) } 
                    value={this.state.searchText} 
                    onKeyPress={this.handleKeyPress}
                  />
                  <Form.InputGroupAppend>
                    { this.state.searchButton? 
                      <Button
                        color="primary"
                        icon="search"
                        onClick={this.onSearch}
                      /> 
                      : <Button
                        color="default"
                        icon="x"
                        onClick={this.onSearchClear}
                      /> 
                    }
                  </Form.InputGroupAppend>
                </Form.InputGroup>
              </Form.Group>            
            }
          >
            { (this.state.user.organization.length && this.state.optionBranchs && this.state.optionProjects && this.state.optionOrganizations) &&
              <SelectBranchs 
                typeSelectFilter = {this.state.typeSelectFilter}
                getbranchs = {this.getbranchs} 
                CollectionsChang = {this.CollectionsChang}
                optionOrganizations = {this.state.optionOrganizations}
                optionProjects = {this.state.optionProjects}
                optionBranchs = {this.state.optionBranchs}
                btLoading={this.state.btLoading}
                labelSelectName = {this.state.labelSelectName}
                Collections = {this.state.Collections}
                labelSelectName4 = {this.state.labelSelectName4}
                Collections4 = {this.state.Collections4}
                labelInputName = {this.state.studenId}
              />
            }
            <Grid.Row>
              <Grid.Col lg={12}>
                <Card>
                  <Card.Header>
                    <Card.Title>prescription list</Card.Title>
                    { this.state.user.level==='write' && <Card.Options>
                      <Button.List>
                        <Button 
                          color="green"                  
                          onClick={ () => this.addPrescription() }
                        > 
                          New prescription
                        </Button> 
                      </Button.List>               
                    </Card.Options>
                    }
                  </Card.Header>
                  <Table
                    responsive
                    className="card-table table-vcenter text-nowrap table-hover "
                  >
                      <Table.Header>
                        <Table.Row>
                          {this.state.header.map( (item, index) =>
                            this.state.user.level==='write' ? 
                            <Table.ColHeader key={index} className = {item.class} style={{ textTransform: 'none'}}>{item.name}</Table.ColHeader>
                            : item.name.toUpperCase()!=='ACTION' && <Table.ColHeader key={index} className = {item.class} style={{ textTransform: 'none'}}>{item.name}</Table.ColHeader>
                          )}
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        { this.state.dataShow.length ? this.state.dataShow.map( (item, i) =>
                          <Table.Row key={ i }>
                            <Table.Col className="text-center align-middle table-sticky-left">
                              {/* { i+1} */}
                              { item.index }
                            </Table.Col>
                            <Table.Col className="text-center align-middle">
                              {item.orderId}
                            </Table.Col>
                            <Table.Col className="text-center align-middle">
                              {item.studentId}
                            </Table.Col>
                            <Table.Col className="text-center align-middle">
                              { item.name }
                            </Table.Col>
                            <Table.Col className="text-center align-middle">
                              { item.lastName }
                            </Table.Col>
                            <Table.Col className="text-center align-middle">
                              <Moment format="DD/MM/YYYY HH:mm">
                                { item.expireDate }
                              </Moment>
                            </Table.Col>
                            <Table.Col className="text-center align-middle">
                              { item.status }
                            </Table.Col>
                            <Table.Col className="text-center align-middle">
                              { item.createBy }
                            </Table.Col>
                            { this.state.user.level==='write' && <Table.Col className="text-center align-middle table-sticky-right">
                              <Button.List>
                                <Button size="sm" color="info"
                                  onClick={ () => this.detailPrescription(item) }
                                  >
                                  Edit
                                </Button>
                                <Button size="sm" color="danger" 
                                  onClick={ ()=> { this.alert({action:'delete', id: item._id, item: item, i: i }) } } >
                                  Delete
                                </Button> 
                              </Button.List> 
                            </Table.Col>
                            }
                          </Table.Row>
                        ) : <Table.Row > 
                            <Table.Col colSpan="12"	alignContent="center">
                              <Text className="my-4" size="" center="true" color="primary" >ไม่พบข้อมูล : Data not found.</Text>
                            </Table.Col>
                          </Table.Row>
                        }
                      <Table.Row > 
                        <Table.Col colSpan="12"	alignContent="center">
                          <div style={paginationStyle}>
                            <Pagination>
                              { this.state.paginationItem.map( number =>
                                <Pagination.Item key={number} active={number === this.state.paginationActive} onClick={ this.paginationActive.bind(this, number) }>
                                  {number} 
                                </Pagination.Item>
                              )}
                            </Pagination>
                          </div>      
                        </Table.Col>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </Card>
              </Grid.Col>
            </Grid.Row>
          </Page.Content>
          <ModalFormLarge
            show={this.state.modalAddbranchShow}
            onHide={this.modalClose}
            onSave={this.getForm}
            uploadFileClick = {this.uploadFileClick}
            selectFileClick = {this.selectFileClick}
            onInputChange={this.handleInputChange}
            title={this.state.modalFormTitle}
            worning={this.state.modalWorning}
            form={this.state.modalForm}
            imageOnclicks = {this.imageOnclicks}
            defaultFileUpload = {this.state.defaultFileUpload}
            isButtonSelectFileEnable={this.state.isButtonSelectFileEnable}
            isFilePondEnable={this.state.isFilePondEnable}
          />

          {this.state.alert}

          <SweetAlert
            warning
            show={this.state.alertShow}
            title="Oops!"
            onConfirm={() => this.setState({ alertShow: false })}
          >
            {this.state.alertText}
          </SweetAlert>
          </>
        </SiteWrapper>   
      )
    }
  }
  
  export default prescription;