
import React, { Component } from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import storeGlobal from "./components/globalStore";
import DetailDashboard from "./components/DetailDashboard";
import DetailReports from "./components/DetailReports";
import Logout from "./components/Logout";
import axios from 'axios';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import {
  Error400,
  Error401,
  Error403,
  Error404,
  Error500,
  Error503,
  HomePage,
  LoginPage,
  Dashboard,

  UserMan,
  RoleMan,
  BranchMan,
  ProjectMan,
  DeployLog,
  UpdateSoftware,
  JournalLog,

  StockReport,
  SalesReport,
  TopUpReport,
  beatProductReports,
  beatBranchReports,
  beatTopupReports,
  alertReports,
  rawDataReports,
  HypoReports,
  
  BranchSetting,
  Productman,
  CampaignMan,
  DetailCampaign,
  SettelmentCashReports,
  SettelmenTransactionReports,
  TransactionsAll,
  ProjectSetting,

  // TransactionsCashSalesReport,
  // TransactionsLinepaySalesReport,
  // TransactionsRabbitSalesReport,
  // TransactionstopUpLinepayReport,
  // TransactionstopUpRabbitReport,

  SalesbyProductReportAll,
  SalesbyProductReportCash,
  SalesbyProductReportRabbit,
  SalesbyProductReportLinepay,
  ProductRefillReport,
  SummaryProductRefillReport,
  OrganizationMan,
  OrganizationSetting,
  UserLogReports,
  BranchMonitoring,
  PermissionMan,
  TransactionsAllHypo,

  PrescriptionMan,
  DetailPrescription,
  StudentMan,
  RefillProduct,
  PrescriptionAllReport,
  TransactionsAllMedicine
  
} from "./pages";

//import NameForm from './NameForm';
import "tabler-react/dist/Tabler.css";
import "./c3jscustom.css";
import "./App.css";

const ProtectedRoute = ({ component: Comp, users, path, ...rest }) => {
  //+-console.log("ProtectedRoute users -->",users);
  //+-console.log("ProtectedRoute users -->",path);
  let loggedIn = false;
  if(users && users.username){
    var menuShow = users.permission.menu;
    for(var i = 0; i < menuShow.length; i++){
      if(menuShow[i].subItems.length){
        for(var j = 0; j < menuShow[i].subItems.length; j++){
          if(menuShow[i].subItems[j].to == path){
            storeGlobal.setusers(users);
            loggedIn = true;  
          } 
        }
      }else{
        if(menuShow[i].to == path){
          loggedIn = true;   
          storeGlobal.setusers(users);
        }
      }
    }
  }

  return (
    <Route
      path={path}
      {...rest}
      render={(props) => {
        return loggedIn ? (
          <Comp {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: {
                prevLocation: path,
                error: "You need to login first!",
              },
            }}
          />
        );
      }}
    />
  );
};

class App extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  constructor(props) {
    super(props);

    const { cookies } = props;
    // this.state = {
    //   user: cookies.get('user'),
    //   permission: cookies.get('permission'),
    //   token: cookies.get('token')
    // };
    this.state = {
      users : storeGlobal.getusers(),
      redirect : false
    };
    //console.log(" cookies.get users ->",this.state.users)
  }

  // componentWillMount(){
  //   console.log('this.state.user =>',this.state.user)
  //   const { cookies } = this.props
  //   let user = cookies.get('user')
  //   let permission = cookies.get('permission')
  //   let token = cookies.get('token')
  //   let homePage = cookies.get('homePage')
  //   console.log("componentWillMount homePage =>",homePage)
  //   console.log("componentWillMount permission =>",permission)
  //   console.log("componentWillMount user =>",user)
  //   if((user !== undefined) && (permission !== undefined) && (homePage !== undefined)){
  //     if((permission.home !== undefined) && (homePage !== undefined)){
  //       permission.home = homePage
  //     }else{
  //       permission.home = 'dashboard'
  //     }
  //     let users = user
  //     users.permission = permission
  //     users.token = token
  //     console.log("componentWillMount =>",users)
  //     if(this.state.users == undefined && users.username){
  //       this.setState({users:users});  
  //     }
  //   }
  // }



  componentWillMount(){
    //console.log('this.state.user =>',this.state.users)
    const { cookies } = this.props
    let user = cookies.get('user')
    let homePage = cookies.get('homePage')
    // console.log("componentWillMount homePage =>",homePage)
    // console.log("componentWillMount user =>",user)
    if(user !== undefined){
      let urls =  window.url + "api/v1/users/refresh"
      let values = {
        userID : user.userID,
        username :user.username,
        role : user.role,
        BranchidSelect : user.BranchidSelect,
        ProjectSelect : user.ProjectSelect,
        OrganizationSelect : user.OrganizationSelect
      }
      let THIS = this

      axios({
        method: 'post',
        // url: 'http://13.251.52.58:3001/api/v1/report/topup',//url: 'http://13.251.52.58:3001/api/v1/report/stock',
        url: urls,
        data: values,
        headers:{
          "Authorization":user.token
        }
        //withCredentials: true
      })
      .then(function (response) {
        //console.log('componentWillMount response =>',response)
        if(response.data.user){
          let users = response.data.user
          if(homePage !== undefined){
            users.permission.home = homePage
          }else{
            users.permission.home = 'dashboard'
          }
          users.token = user.token
          users.BranchidSelect = user.BranchidSelect
          users.ProjectSelect = user.ProjectSelect
          users.OrganizationSelect = user.OrganizationSelect

          if(user.OrganizationSelect === undefined) users.OrganizationSelect = users.organization[0]
          if(user.ProjectSelect === undefined) users.ProjectSelect = users.project[0].projectName
          if(user.BranchidSelect === undefined) users.BranchidSelect = users.branchID
          console.log("response componentWillMount username =>",users)

          let location = {
            pathname: '/'+users.permission.home,
            state: {}
          }
          storeGlobal.setusers(users);
          THIS.setState({
            users:users,
            location: location,
            redirect: true 
          })        
        }
      })
      .catch(function (error) {
        // THIS.setState({
        //   alertShow: true,
        //   alertText: error.message
        // })
        console.log('axios error =>',error.message);
        if (error.response) {
          console.log(error.response.status);
          if(error.response.status===401) {
            let location = {
              pathname: '/logout',
              state: {}
            }
            THIS.setState({
              location: location,
              redirect: true 
            })  
          }
        }

      });
    }else{
      console.log('componentWillMount no users =>',user)
      // let query = this.useQuery();
      // const {filter} = useHistory();
      // console.log("query =>",filter)
      const queryParams = new URLSearchParams(window.location.search);
      const id = queryParams.get('id');
      const name = queryParams.get('name');
      const type = queryParams.get('type');
      console.log(id, name, type); // 55 test null

      console.log("this.props",this.props)
      let location = {
        pathname: '/login',
        state: {}
      }
      
      this.setState({
        location: location,
        redirect: true 
      })
    }
  }

  // useQuery(){
  //   return new URLSearchParams(useLocation().search);
  // }

  // componentDidMount() {
  //   console.log('componentDidMount this.state.users =>',this.state.users)
  //   if(this.state.users == undefined){
  //     let users = storeGlobal.getusers();
  //     if(users && users.username){
  //       console.log('componentDidMount users =>',users)
  //       this.state.users = users;
  //       this.setState({users:users});  
  //     }
  //   }
  // }

  componentWillUpdate(){
    //console.log("componentWillUpdate user => ",this.state.users)
    if(JSON.stringify(this.state.users) === '{}'){
      //console.log('.=.=.=.=.=')
      let users = storeGlobal.getusers();
      if(users && users.username){
        //console.log('render users =>',users)
        this.state.users = users
        // this.setState({
        //   users : users
        // })
      }
    }
  }

//dfasdgfghjjk42
  render() {
    //console.log("App.js this.state2 -->",this.state)
    if(this.state.redirect === true){
      //console.log('redirect =>',this.state.location)
      this.setState({
        redirect : false
      })
      return ( 
        <div>
          <Router>
            <Route>
              <Redirect to={this.state.location} /> 
            </Route>
          </Router>
        </div>
      )
    }
    //console.log("=================")

    return (
      <div>
          <Router>
            <Switch>
              <Route exact path="/" component={props => <HomePage {...props} />} />
              {/* <Route exact path="/" component={HomePage} /> */}
              <Route exact path="/400" component={Error400} />
              <Route exact path="/401" component={Error401} />
              <Route exact path="/403" component={Error403} />
              <Route exact path="/404" component={Error404} />
              <Route exact path="/500" component={Error500} />
              <Route exact path="/503" component={Error503} /> 
              <Route exact path="/login" component={LoginPage} />
              {/* <Route exact path="/logout" component={Logout} /> */}
              <Route exact path="/logout" component={props => <Logout {...props} />} />
              <Route exact path="/detaildashboard" component={DetailDashboard} />
              <Route exact path="/detailcampaign" component={DetailCampaign} />
              <Route exact path="/branchsetting" component={BranchSetting} />
              <Route exact path="/projectSetting" component={ProjectSetting} />
              <Route exact path="/detailreports" component={DetailReports} />
              <Route exact path="/detailprescription" component={DetailPrescription} />
              <Route exact path="/refillproduct" component={RefillProduct} />

              {/* <Route exact path="/productrefillreport"  component={ProductRefillReport} />
              <Route exact path="/summaryproductrefillreport"  component={SummaryProductRefillReport} /> */}

              <ProtectedRoute path="/productrefillreport" users={this.state.users} component={ProductRefillReport} />
              <ProtectedRoute path="/summaryproductrefillreport" users={this.state.users} component={SummaryProductRefillReport} />
              <ProtectedRoute path="/dashboard" users={this.state.users} component={Dashboard} />
              <ProtectedRoute path="/stockreport" users={this.state.users} component={StockReport} />
              <ProtectedRoute path="/salesreport" users={this.state.users} component={SalesReport} />
              <ProtectedRoute path="/topupreport" users={this.state.users} component={TopUpReport} />
              <ProtectedRoute path="/mostsaleproductreport" users={this.state.users} component={beatProductReports} />
              <ProtectedRoute path="/mostsalebranchreport" users={this.state.users} component={beatBranchReports} />
              <ProtectedRoute path="/mosttopupbranchreport" users={this.state.users} component={beatTopupReports} />
              <ProtectedRoute path="/notificationreport" users={this.state.users} component={alertReports} />
              <ProtectedRoute path="/rawdatareports" users={this.state.users} component={rawDataReports} />

              <ProtectedRoute path="/hyporeports" users={this.state.users} component={HypoReports} />

              <ProtectedRoute path="/userman" users={this.state.users} component={UserMan} />
              <ProtectedRoute path="/roleman" users={this.state.users} component={RoleMan} />
              <ProtectedRoute path="/branchman" users={this.state.users} component={BranchMan} />
              <ProtectedRoute path="/projectman" users={this.state.users} component={ProjectMan} />
              <ProtectedRoute path="/deploy" users={this.state.users} component={DeployLog} />
              <ProtectedRoute path="/updatesoftware" users={this.state.users} component={UpdateSoftware} />
              <ProtectedRoute path="/journallog" users={this.state.users} component={JournalLog} />

              <ProtectedRoute path="/productman" users={this.state.users} component={Productman} />
              <ProtectedRoute path="/campaignman" users={this.state.users} component={CampaignMan} />
              <ProtectedRoute path="/settelmentcashreports" users={this.state.users} component={SettelmentCashReports} />
              <ProtectedRoute path="/settelmenttransaction" users={this.state.users} component={SettelmenTransactionReports} />
              
              <ProtectedRoute path="/transactionsall" users={this.state.users} component={TransactionsAll} />
              {/* <ProtectedRoute path="/transactionscashsalesreport" users={this.state.users} component={TransactionsCashSalesReport} />
              <ProtectedRoute path="/transactionslinepaysalesreport" users={this.state.users} component={TransactionsLinepaySalesReport} />
              <ProtectedRoute path="/transactionsrabbitsalesreport" users={this.state.users} component={TransactionsRabbitSalesReport} />
              <ProtectedRoute path="/transactionstopuplinepayreport" users={this.state.users} component={TransactionstopUpLinepayReport} />
              <ProtectedRoute path="/transactionstopuprabbitreport" users={this.state.users} component={TransactionstopUpRabbitReport} /> */}
              
              <ProtectedRoute path="/salesbyproductall" users={this.state.users} component={SalesbyProductReportAll} />
              <ProtectedRoute path="/salesbyproductcash" users={this.state.users} component={SalesbyProductReportCash} />
              <ProtectedRoute path="/salesbyproductrabbit" users={this.state.users} component={SalesbyProductReportRabbit} />
              <ProtectedRoute path="/salesbyproductlinepay" users={this.state.users} component={SalesbyProductReportLinepay} />
              <ProtectedRoute path="/productrefillreport" users={this.state.users} component={ProductRefillReport} />

              <ProtectedRoute path="/organizationman" users={this.state.users} component={OrganizationMan} />
              <ProtectedRoute path="/organizationsetting" users={this.state.users} component={OrganizationSetting} />

              <ProtectedRoute path="/userlogreports" users={this.state.users} component={UserLogReports} />
              
              <ProtectedRoute path="/branchmonitoring" users={this.state.users} component={BranchMonitoring} />

              <ProtectedRoute path="/permissionman" users={this.state.users} component={PermissionMan} />

              <ProtectedRoute path="/transactionsallhypo" users={this.state.users} component={TransactionsAllHypo} />

              <ProtectedRoute path="/prescriptionman" users={this.state.users} component={PrescriptionMan} />

              <ProtectedRoute exact path="/studentman" users={this.state.users} component={StudentMan} />

              <ProtectedRoute exact path="/prescriptionallreport" users={this.state.users} component={PrescriptionAllReport} />

              <ProtectedRoute exact path="/transactionsallmedicine" users={this.state.users} component={TransactionsAllMedicine} />

              
              <Route component={Error404} />
            </Switch>
          </Router>
      </div>
    );
  }
}


export default withCookies(App);
