import React,{Component} from 'react'
import {
  Page,
  // Avatar,
  Form,
  // Icon,
  // Grid,
  Card,
  Text,
  Table,
  // Alert,
  // Progress,
  // colors,
  // Dropdown,
  Button,
  // StampCard,
  // StatsCard,
  // ProgressCard,
  // Badge,
} from "tabler-react";
import SiteWrapper from "../components/SiteWrapper.react";
import axios from 'axios';
import ModalFormLarge from "../components/ModalFormLarge";
import SweetAlert from 'react-bootstrap-sweetalert'
import Pagination from 'react-bootstrap/Pagination'
import storeGlobal from "../components/globalStore";
// import SelectBranchsLess from "../components/SelectBranchsLess";
import SelectBranchs from "../components/SelectBranchsStateLess";
//import LoadingOverlay from 'react-loading-overlay'
// import SyncLoader from 'react-spinners/SyncLoader'
import { Redirect } from "react-router-dom";
import Moment from 'react-moment';

class RoleMan extends Component{
  constructor(props) {
    super(props);
    this.state = {
      // user: {
      //   token: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJfaWQiOiI1YzVhNTgzZWYyZjg2NTE0ZjlkNTcxMzgiLCJzdWIiOiJsb29rbXVuIiwiaWF0IjoxNTQ5OTQyOTkzMDcwfQ.7_UIjuQVHYavxFQNIl2TxzlZs9fMrFo6tydWy_dDAtk",
      //   organization: "ppetech",
      // },
      user: storeGlobal.getusers(),
      branchs: [],
      usersRes: [],
      branchsPage: [],
      branchEdit: null,

      modalAddProjectShow: false,
      modalShow: false,
      modalEditProjectShow: false,
      modalTitle: 'Add role',
      modalWorning:'',
      modalForm: [
        { key: "name", name: "Name", placeholder:"", value: "", disabled: false, type: 'input', col:1 },
        { key: "description", name: "Description", placeholder:"", value: "", disabled: false, type: 'textarea', row: 3, col:1 }, 
        // { key: "branchID", name: "Branchs", placeholder:"", value: [], disabled: false, type: 'select-multi' , items: [
        //     'p1', 'p2', 'p3',
        // ] }, 
        // { key: "project", name: "Project", placeholder:"", value: "", disabled: false, type: 'select', items: [
        //   'p1', 'p2', 'p3',
        // ] }, 
      ],  

      alert: null,
      paginationItem: [],
      paginationActive: 1,
      OrganizationSelect: '',
      branchsPageLimit: window.roleManPage.tableList, 

      redirect: false,
      location: {},
      home:[],

      treeData: [],
      expandedKeys: [],
      autoExpandParent: true,
      checkedKeys: [],
      selectedKeys: [],
      currentRole: [],
      permissionDefault: [],

      searchText: '',
      searchButton: true,

      optionOrganizations: null,
      optionProjects: null,
      optionBranchs: null,
      typeSelectFilter:"useOrganization"

      // SelectBranchsLess
      // SelectBranchsLess : {
      //   title: 'Select filter...',
      //   worning: "",
      //   organizations: [], //[ { value: 'organizations', label: 'Organizations' }, ],
      //   projects: [],//[ { value: 'projects', label: 'Projects' }, ],
      //   branchs: [],//[ { value: 'branchs', label: 'Branchs' }, ],
      // }
      
    };


    // this.handleClick = this.handleClick.bind(this);

  }
    
  // componentDidMount() {
  //   this.getbranchs(null)
  //   this.getPermissionDefault(null)
  // }

  componentDidMount() {
    let users = storeGlobal.getusers();
    if(users && users.username){
      //console.log('this.state.user --->', this.state.user)
      let organization = []
      for(var i = 0; i < this.state.user.organization.length; i++){
        organization.push({label:this.state.user.organization[i],value:this.state.user.organization[i]})  
      }

      let branchID = [{label:"All",value:"All"}]
      for(var j = 0; j < this.state.user.branch.length; j++){
        branchID.push({label:this.state.user.branch[j].branchID,value:this.state.user.branch[j].branchID});
      }

      let project = [{label:"All",value:"All"}]
      for(let i = 0; i < this.state.user.project.length; i++){
        if(organization[0].value === this.state.user.project[i].organization){
          project.push({label:this.state.user.project[i].projectName,value:this.state.user.project[i].projectName})    
        }
      }

      // let values = {
      //   organization: this.state.user.OrganizationSelect,
      //   branchID: this.state.user.branchID,
      //   startDate: (new Date()).setHours(0,0,0,0),
      //   endDate: new Date()
      // }


      let dataCallback = {
        BranchsSelect: this.state.user.branch, 
        ProjectSelect: this.state.user.ProjectSelect,
        BranchidSelect: this.state.user.branchID,
        OrganizationSelect: '_',
        // startDate: values.startDate,
        // endDate: values.endDate,
      }

      this.setState({
        OrganizationSelect: '_',
        optionOrganizations : organization,
        optionProjects:project,
        optionBranchs:branchID
      },() =>{
        this.getbranchs(dataCallback)
        this.getPermissionDefault(null)
      })
    }
  }

  componentWillMount() {
    let users = storeGlobal.getusers();
    if(users && users.username){
      this.setState({
        OrganizationSelect: users.OrganizationSelect
      })
    }
  }

  
  handleClick(e) {
    console.log('this ->', e)
  }

  hideAlert() {
		this.setState(state => ({
			alert: null
		}));
  }
  
  alert(e) {
    // console.log(e)
    switch(e.action) {
      case 'delete':
        this.setState( state => ({
          alert: (
            <SweetAlert
              warning
              showCancel
              confirmBtnText="Yes, delete it!"
              confirmBtnBsStyle="danger"
              cancelBtnBsStyle="default"
              title="Are you sure?"
              onConfirm={()=> {this.deleteBranch(e)} } //{this.deleteFile}
              onCancel={ this.hideAlert.bind(this) }
              >
              Delete "{e.branch}"
            </SweetAlert>
          )
        }));
        break
        case 'warning':
          this.setState( state => ({
            alert: (
              <SweetAlert
                warning
                confirmBtnText="close"
                confirmBtnBsStyle="defallt"
                title="Permission denied to delete a organizations!"
                onConfirm={this.hideAlert.bind(this)} //{this.deleteFile}
                >
              </SweetAlert>
            )
          }));
        break
        default:
        break
    }
		
  } 

  deleteBranch(branch) {
    console.log('deleteBranch =>', branch)
    if(JSON.stringify(this.state.user) !== '{}'){
      if(this.state.user.role !== 'sadmin'){
        this.alert({action:'warning'}) 
        return
      }
    }
    let THIS = this
    axios({
      method: 'delete',
      // url: window.url + 'api/v1/collection/' + this.state.user.organization + '/project/' + branch.id,
      url: window.url + 'api/v1/collection/' + this.state.OrganizationSelect + '/organizations/' + branch.id,
      data: { user: this.state.user },
      headers:{
        "Authorization": this.state.user.token
      }
    })
    .then(function (response) {
      console.log('deleteUser response.data.value =>', response.data.value);  
      let dataSave = {
        "type" : 'warning', //information //warning // error
        "date" : new Date(),
        "surce" : 'organizationMan',
        "category" : 'webApp',
        "even" : 'Delete organization',
        "user" :  THIS.state.user.username,
        "ip" : "",
        "discription" : 'Delete organization => ' + branch.branch
      }
      THIS.saveLog(dataSave)

      let branchs = THIS.state.branchs 
      console.log('organization =>',branchs)
      branchs = branchs.filter(person => person._id !== branch.id)

      let user = THIS.state.user
      user.organization = user.organization.filter(person => person !== branch.branch)
      
      THIS.setState({ 
        branchs: branchs,
        user : user
      },() => {        
        THIS.paginationItemCal()
        // console.log('THIS.state.paginationItem.length =>', THIS.state.paginationItem.length)
        if(THIS.state.paginationItem.length === 1) THIS.paginationActive(1)
      })
      THIS.hideAlert()

      let branchsPage = THIS.state.branchsPage 
      branchsPage = branchsPage.filter(person => person._id !== branch.id)
      // console.log('branchsPage =>', branchsPage); 
      //THIS.state.branchsPage = branchsPage
      THIS.setState({branchsPage: branchsPage})
      if(!branchsPage.length) THIS.paginationActive(1)
      
    })
    .catch(function (error) {
      console.log(error);
      THIS.hideAlert()
    });
    
  }
  
  paginationActive(page) {
		this.setState(state => ({
			paginationActive: page
    }));

    this.branchsPage(page)
  }

  branchsPage(page) {
		let branchsPage = []
    let start = (page-1) * this.state.branchsPageLimit
    let end = start + this.state.branchsPageLimit
    if(end >= this.state.branchs.length ) {
      end = this.state.branchs.length
    }

    for(let i=start; i<end; i++) {
      // console.log('i -->', i)
      this.state.branchs[i].index = i+1
      branchsPage.push(this.state.branchs[i])
    }
    this.setState({branchsPage: branchsPage})
  }

  paginationItemCal() {
		let n = 1
    let paginationItem = []
    for(let i=0; i<this.state.branchs.length; i+=this.state.branchsPageLimit) {
      // console.log('n -->', n, THIS.state.paginationItem)
      paginationItem.push(n++)
    }

    this.setState({paginationItem: paginationItem})
  }
  
  getbranchs = (dataCallback) => {
    //+-console.log("getbranchs ->",dataCallback);
    this.setState({OrganizationSelect: dataCallback.OrganizationSelect});
    let users = this.state.user
    if(users && users.username){
      let THIS = this
      axios({
        method: 'get',
        // url: window.url + 'api/v1/collection/' + this.state.user.organization + '/project', 
        url: window.url + 'api/v1/collection/' + dataCallback.OrganizationSelect + '/organizations',
        // url: 'http://13.251.52.58:3001/api/v1/users/' + dataCallback.OrganizationSelect,
        data: 'values',
        headers:{
          "Authorization": this.state.user.token
        }
      })
      .then(function (response) {
        //console.log('response.data.value =>', response.data);  
        // return
        if(response.data.value){
        let reosultOrganization = response.data.value
          let p = reosultOrganization
          p.sort((a,b) => ((a.id*1) > (b.id*1)) ? 1 : (((b.id*1) > (a.id*1)) ? -1 : 0)); 

          THIS.setState({
            branchs: reosultOrganization,
            usersRes: reosultOrganization,
          },() => {
            
            THIS.paginationItemCal()
            THIS.branchsPage(1)        

          });
        }
      }) 
      .catch(function (error) {
        console.error(error);
        // alert("error: " + JSON.stringify(error) );
      });
    }
  }

  getPermissionDefault = (dataCallback) => {
    //+-console.log("getPermissionDefault..",dataCallback);
    // this.setState({OrganizationSelect: dataCallback.OrganizationSelect});
    if(JSON.stringify(this.state.user) !== '{}'){
      let users = this.state.user
      let urls =  window.url + 'api/v1/collection/_/permissionDefaults'
      if(users.role !== "sadmin"){
        urls =  window.url + 'api/v1/collection/'+ users.OrganizationSelect +'/permissionDefault'
      }

      let THIS = this
      axios({
        method: 'get',
        url:urls, 
        data: 'values',
        headers:{
          "Authorization": users.token
        }
      })
      .then(function (response) {
        //console.log('getPermissionDefault response -->', response)
        let permissionDefault =[]
        response.data.value.forEach(currentValue => {
          permissionDefault = permissionDefault.concat(currentValue.menu)
        })

        //console.log('permissionDefault =>',permissionDefault)

        THIS.setState({
          permissionDefault: permissionDefault
        })
      }) 
      .catch(function (error) {
        console.error(error);
        // alert("error: " + JSON.stringify(error) );
      });
    }
  }
  
  modalClose = () => {
    // this.setState({modalWorning: ""})
    this.setState({ modalWorning: "", modalAddProjectShow: false })
    this.setState({ modalWorning: "", modalShow: false })
    // console.log('this.state.modalForm =>', this.state.modalForm)
  };

  getForm = () => {
    //console.log('getForm modalForm =>', this.state.modalForm);   
    let data = {}
    let modalForm = this.state.modalForm

    if(JSON.stringify(this.state.user) !== '{}'){
      if(this.state.user.role !== 'sadmin'){
        return this.setState({modalWorning: "Permission denied to create or edit a organizations!"})
      }
    }

    // console.log('1 getForm data =>', data);
    for(let i=0; i<this.state.branchs.length; i++) {
      if(this.state.branchs[i].name === data.name) return this.setState({modalWorning: "Name is duplicate!"}) 
    }

    for(let i=0; i<this.state.modalForm.length; i++) {

      if(modalForm[i].value === '') { 
        // console.log('modalForm[i].value == ""', modalForm[i]);
        return this.setState({modalWorning: "Input is blank!"})
      }

      // if(modalForm[i].type === 'tree') {
      //   if(!modalForm[i].tree.checkedKeys.length) {
      //     return this.setState({modalWorning: "Permission is blank!"})
      //   }
      //   modalForm[i].value = modalForm[i].tree.checkedKeys
         
      //   // check tree to menu
      //   let permission = modalForm[i].value
      //   let permissionDefault = JSON.parse(JSON.stringify(this.state.permissionDefault))
      //   // for(let i=0; i<permissionDefault.length; i++) {  
      //   for(let i=permissionDefault.length-1; i>=0; i--) {  
      //     let subItems1 = permissionDefault[i].subItems
      //     if(subItems1) {
      //       if(!subItems1.length) {              
      //         if(permission.indexOf(permissionDefault[i].value) < 0) {
      //           // console.log('!subItems1.length delete permissionDefault[i] =>', i, permissionDefault[i]);
      //           // delete permissionDefault[i]
      //           permissionDefault.splice(i,1)
      //         }
      //       } else {
      //         // for(let j=0; j<subItems1.length; j++) {  
      //         for(let j=subItems1.length-1; j>=0; j--) { 
      //           let subItems2 = permissionDefault[i].subItems[j].subItems
      //           if(subItems2) {
      //             if(!subItems2.length) {                    
      //               if(permission.indexOf(subItems1[j].value) < 0) {
      //                 // console.log('delete subItems1[j] =>', subItems1[j]);
      //                 // delete subItems1[j]
      //                 subItems1.splice(j,1)
      //               }
      //             } else {
      //               // for(let k=0; k<subItems2.length; k++) { 
      //               for(let k=subItems2.length-1; k>=0; k--) { 
      //                 if(permission.indexOf(subItems2[k].value) < 0) {
      //                   // console.log('delete subItems2[k] =>', subItems2[k]);
      //                   // delete subItems2[k]
      //                   subItems2.splice(k,1)
      //                 }
      //               }
      //             }
      //           } else {
      //             // console.log('subItems1[j].value =>', j, subItems1[j].value);
      //             if(permission.indexOf(subItems1[j].value) < 0) {
      //               //+-console.log('==== delete subItems1[j] 1 =>', subItems1[j]);
      //               // delete subItems1[j]
      //               subItems1.splice(j,1)
      //             }
      //           }    
      //           // console.log('--- subItems1 =>', j, subItems1);  
      //           // if(typeof subItems1[j] === 'undefined') { //.length
      //           //   subItems1.splice(j,1)
      //           // } else if(!subItems1[j].length) { //.length
      //           //   subItems1.splice(j,1)
      //           // } 
      //         }
      //         // console.log('--- permissionDefault[i] =>', i, permissionDefault[i]);
      //         if(permissionDefault[i]) {
      //           if(typeof permissionDefault[i].subItems === 'undefined') {
      //             permissionDefault.splice(i,1)
      //           } else if(!permissionDefault[i].subItems.length) {
      //             permissionDefault.splice(i,1)
      //           }
      //         }   
      //       }
            
      //     } else {
      //       if(permission.indexOf(permissionDefault[i].value) < 0) {
      //         // console.log('delete permissionDefault[i] =>', i, permissionDefault[i]);
      //         // delete permissionDefault[i]
      //         permissionDefault.splice(i,1)
      //       }
      //     }  
      //   }


      //   data.menu = permissionDefault
      //   // end check tree to menu
      // }

      if(modalForm[i].type === 'tree') {
        if(!modalForm[i].tree.checkedKeys.length) {
          return this.setState({modalWorning: "Permission is blank!"})
        }
        modalForm[i].value = modalForm[i].tree.checkedKeys
         
        // check tree to menu
        let permission = modalForm[i].value
        let permissionDefault = JSON.parse(JSON.stringify(this.state.permissionDefault))
        let permissionNew = []

        for(let j = 0; j < permission.length; j++ ){
          //console.log("permission[",j,"] => ",permission[j])
          for(let k = 0; k < permissionDefault.length; k++){
            //console.log("permissionDefault[",k,"] => ",permissionDefault[k])
            if(permissionDefault[k].subItems.length){
              for(let l = 0; l < permissionDefault[k].subItems.length; l++){
                if(permission[j] === permissionDefault[k].subItems[l].value){
                  let mainMenu =  permissionDefault[k].value
                  if(permissionNew.findIndex(x => x.value === mainMenu) === -1) {
                    let tmpObj = JSON.parse(JSON.stringify(permissionDefault[k]))
                    delete tmpObj.subItems
                    let tmpSubItem = permissionDefault[k].subItems[l]
                    tmpObj.subItems = [tmpSubItem]
                    permissionNew.push(tmpObj)
                    //console.log("tmpObj first =>",tmpObj)
                  }else{
                    let tmpSubItem = permissionDefault[k].subItems[l]
                    let tmpObj = permissionNew.find(x => x.value === mainMenu)
                    tmpObj.subItems.push(tmpSubItem)
                    //console.log("tmpObj =>",tmpObj)
                  }
                }
              }
            }else{
              if(permission[j] === permissionDefault[k].value){
                permissionNew.push(permissionDefault[k])
              }
            }
          }
        }
        //console.log("permissionNew =>",permissionNew)
        //console.log("permissionDefault =>",permissionDefault)

        data.menu = permissionNew
        // end check tree to menu
      }

      data[modalForm[i].key] = modalForm[i].value

    }

    if(this.state.modalTitle === 'Modify Organizations') {
      data.flag = 'edit'
      data.createBy = this.state.user.username
      data.updateBy = this.state.user.username
      this.getFormEdit(data)
      return
    }

    if(this.state.branchs.length){
      let p = this.state.branchs
      p.sort((a,b) => (a.id < b.id) ? 1 : ((b.id < a.id) ? -1 : 0)); 
      console.log('p =>',p)
      let count = ((p[0].id * 1) + 1)
      data.id = ('000' + count.toString()).slice(-3)
      console.log("data.projectID =>",data.id) 
    }else{
      data.id = '001'  
    }
    data.createBy = this.state.user.username
    data.updateBy = this.state.user.username
    data.flag = 'add'
    // data.organization = this.state.user.organization[0]
    //+-console.log('getForm data =>', data);
    
    let THIS = this
    axios({
      method: 'post',
      url: window.url + 'api/v1/collection/' + this.state.OrganizationSelect +'/organizations',
      // url: 'http://13.251.52.58:3001/api/v1/users/' + this.state.user.organization,
      data: data,
      headers:{
        "Authorization": this.state.user.token
      }
    })
    .then(function (response) {
      //console.log("Add organizations response =>", response.data.value); // {n: 1, nModified: 1, ok: 1}
      if(response.data.value){
        let permissionDefault = {
          'menu' : data.menu
         }
         //console.log('permissionDefault =>',permissionDefault)
        axios({
          method: 'post',
          url: window.url + 'api/v1/collection/' + data.name +'/permissionDefault',
          // url: 'http://13.251.52.58:3001/api/v1/users/' + this.state.user.organization,
          data: permissionDefault,
          headers:{
            "Authorization": THIS.state.user.token
          }
        })
        .then(function (response1) {
          //console.log("Add project response1 =>", response1.data.value); // {n: 1, nModified: 1, ok: 1}
          let branchAdd = response.data.value[0]
          let branchs = THIS.state.branchs
          branchs.unshift(branchAdd)
          let user = THIS.state.user
          if(JSON.stringify(user) !== '{}'){
            user.organization.push(branchAdd.name)
          }
          THIS.setState({
            user: user,
            branchs: branchs,
            modalForm: modalForm
          },() => {
            THIS.paginationItemCal()
            THIS.branchsPage(1)        
          });

          let dataSave = {
            "type" : 'information', //information //warning // error
            "date" : new Date(),
            "surce" : 'OrganizationMan',
            "category" : 'webApp',
            "even" : 'New organization',
            "user" :  THIS.state.user.username,
            "ip" : "",
            "discription" : 'Add new organization =>'+ data.name
          }
          THIS.saveLog(dataSave)

          THIS.modalClose()
        })
        .catch(function (error) {
          console.log(error);
          THIS.setState({modalWorning: "Add new organization error!"})
        });
      }
    })
    .catch(function (error) {
      console.log(error);
      THIS.setState({modalWorning: "Add new organization error!"})
    });

  }

  getFormEdit = (data) => {
    // console.log('getForm data =>', data);
    // console.log('this.state.branchEdit =>', this.state.branchEdit);
    // console.log('this.state.OrganizationSelect =>', this.state.OrganizationSelect);
    // return
    let THIS = this
    axios({
      method: 'put',
      url: window.url + 'api/v1/collection/' + this.state.OrganizationSelect + '/organizations/' + this.state.branchEdit._id,
      data: data,
      headers:{
        "Authorization": this.state.user.token
      }
    })
    .then(function (response) {
      //console.log("Edit api response =>", response.data.value); // {n: 1, nModified: 1, ok: 1}
      if(response.data.value){
        let permissionDefault = {
          'menu' : data.menu
         }
        axios({
          method: 'get',
          url: window.url + 'api/v1/collection/' + data.name +'/permissionDefault',
          // url: 'http://13.251.52.58:3001/api/v1/users/' + this.state.user.organization,
          data: 'value',
          headers:{
            "Authorization": THIS.state.user.token
          }
        })
        .then(function (response1) {
          //console.log("Add project response1 =>", response1.data.value); // {n: 1, nModified: 1, ok: 1}

          if(response1.data.value.length){
            let id = response1.data.value[0]._id
            axios({
              method: 'put',
              url: window.url + 'api/v1/collection/' + data.name +'/permissionDefault/' + id,
              // url: 'http://13.251.52.58:3001/api/v1/users/' + this.state.user.organization,
              data: permissionDefault,
              headers:{
                "Authorization": THIS.state.user.token
              }
            })
            .then(function (response2) {
              //console.log("Add project response2 =>", response2.data.value); // {n: 1, nModified: 1, ok: 1}
              for (var k in data) {
                THIS.state.branchEdit[k] = data[k]
              }
              THIS.state.branchEdit.updateDate = new Date()
              for(let i=0; i<THIS.state.branchsPage.length; i++) {
                if(THIS.state.branchsPage[i]._id === THIS.state.branchEdit._id) {
                  THIS.state.branchsPage[i] = JSON.parse(JSON.stringify(THIS.state.branchEdit))
                }
              }
              for(let i=0; i<THIS.state.branchs.length; i++) {
                if(THIS.state.branchs[i]._id === THIS.state.branchEdit._id) {
                  THIS.state.branchs[i] = JSON.parse(JSON.stringify(THIS.state.branchEdit))
                }
              }
              THIS.setState({branchsPage: THIS.state.branchsPage, branchs: THIS.state.branchs})
              // THIS.state.branchPage.unshift(userAdd)
              let dataSave = {
                "type" : 'warning', //information //warning // error
                "date" : new Date(),
                "surce" : 'organizationMan',
                "category" : 'webApp',
                "even" : 'Edit organization',
                "user" :  THIS.state.user.username,
                "ip" : "",
                "discription" : 'Edit organization =>'+ data.name
              }
              THIS.saveLog(dataSave)

              THIS.modalClose()
              THIS.state.branchEdit = null
            })
            .catch(function (error) {
              console.log(error);
              THIS.setState({modalWorning: "Edit organizationMan error!"})
              THIS.state.branchEdit = null
            });
          }else{
            //console.log('permissionDefault =>',permissionDefault)
            axios({
              method: 'post',
              url: window.url + 'api/v1/collection/' + data.name +'/permissionDefault',
              // url: 'http://13.251.52.58:3001/api/v1/users/' + this.state.user.organization,
              data: permissionDefault,
              headers:{
                "Authorization": THIS.state.user.token
              }
            })
            .then(function (response2) {
              //console.log("Add project response2 =>", response2.data.value); // {n: 1, nModified: 1, ok: 1}
              for (var k in data) {
                THIS.state.branchEdit[k] = data[k]
              }
              THIS.state.branchEdit.updateDate = new Date()
              for(let i=0; i<THIS.state.branchsPage.length; i++) {
                if(THIS.state.branchsPage[i]._id === THIS.state.branchEdit._id) {
                  THIS.state.branchsPage[i] = JSON.parse(JSON.stringify(THIS.state.branchEdit))
                }
              }
              for(let i=0; i<THIS.state.branchs.length; i++) {
                if(THIS.state.branchs[i]._id === THIS.state.branchEdit._id) {
                  THIS.state.branchs[i] = JSON.parse(JSON.stringify(THIS.state.branchEdit))
                }
              }
              THIS.setState({branchsPage: THIS.state.branchsPage, branchs: THIS.state.branchs})
              // THIS.state.branchPage.unshift(userAdd)
              let dataSave = {
                "type" : 'warning', //information //warning // error
                "date" : new Date(),
                "surce" : 'organizationMan',
                "category" : 'webApp',
                "even" : 'Edit organization',
                "user" :  THIS.state.user.username,
                "ip" : "",
                "discription" : 'Edit organization =>'+ data.name
              }
              THIS.saveLog(dataSave)

              THIS.modalClose()
              THIS.state.branchEdit = null
            })
            .catch(function (error) {
              console.log(error);
              THIS.setState({modalWorning: "Edit organization error!"})
              THIS.state.branchEdit = null
            });
          }
        })
        .catch(function (error) {
          console.log(error);
          THIS.setState({modalWorning: "Edit organization error!"})
          THIS.state.branchEdit = null
        });
      }
    })
    .catch(function (error) {
      console.log(error);
      THIS.setState({modalWorning: "Edit organization error!"})
      THIS.state.branchEdit = null
    });

  }

  addBranch = () => {
    //+-console.log('addBranch item =>');
    let projectItems = []
    for(let i=0; i<this.state.user.project.length; i++) {      
      projectItems.push(this.state.user.project[i].projectName)
    }

    let treeData =[]
    let permissionDefault = this.state.permissionDefault
    for(let i=0; i<permissionDefault.length; i++) {  
      let obj={
        title: permissionDefault[i].value,
        key: permissionDefault[i].value,
        children: [],
      }
      let subItems1 = permissionDefault[i].subItems
      if(subItems1) {
        for(let j=0; j<subItems1.length; j++) {  
          let obj1={
            title: subItems1[j].value,
            key: subItems1[j].value,
            children: [],
          }
          let subItems2 = permissionDefault[i].subItems[j].subItems
          if(subItems2) {
            for(let k=0; k<subItems2.length; k++) {  
              let obj2={
                title: subItems2[j].value,
                key: subItems2[j].value,
                children: [],
              }
              obj1.children.push(obj2)
            }
          }  
          obj.children.push(obj1)
        }
      }    
      treeData.push(obj)
    }
    let checkedKeys = []
    let modalForm = [
      { key: "name", name: "Name", placeholder:"", value: "", disabled: false, type: 'input', col: 1 }, 
      { key: "description", name: "Description", placeholder:"Enter Description..", value: "", disabled: false, type: 'textarea', rows: 3, col: 1 },
      { key: "permission", name: "Permission", placeholder:"", value: true, disabled: false, type: 'tree', col: 2, 
        tree: {
          treeData: treeData,
          expandedKeys: this.state.expandedKeys,
          autoExpandParent: this.state.autoExpandParent,
          checkedKeys: checkedKeys,
          selectedKeys: this.state.selectedKeys,
          onExpand: this.onExpand,
          onCheck: this.onCheck,
          onSelect: this.onSelect,
        } 
      }
    ]
    // console.log('modalForm =>', modalForm);
    let modalTitle = 'New Organization'
    this.setState({ 
      modalForm: modalForm, 
      modalAddProjectShow: true,
      modalShow: true, 
      modalTitle: modalTitle,
    })
  }

  editBranch = (item) => {
    //+-console.log('editBranch item =>', item);
    //this.state.branchEdit = item
    // return
    let treeData =[]
    let permissionDefault = this.state.permissionDefault
    for(let i=0; i<permissionDefault.length; i++) {  
      let obj={
        title: permissionDefault[i].value,
        key: permissionDefault[i].value,
        children: [],
      }
      let subItems1 = permissionDefault[i].subItems
      if(subItems1) {
        for(let j=0; j<subItems1.length; j++) {  
          let obj1={
            title: subItems1[j].value,
            key: subItems1[j].value,
            children: [],
          }
          let subItems2 = permissionDefault[i].subItems[j].subItems
          if(subItems2) {
            for(let k=0; k<subItems2.length; k++) {  
              let obj2={
                title: subItems2[j].value,
                key: subItems2[j].value,
                children: [],
              }
              obj1.children.push(obj2)
            }
          }  
          obj.children.push(obj1)
        }
      }    
      treeData.push(obj)
    }

    // home list
    let items = ['Dashboard']
    //console.log('items1 =>',items)
    if(item.permission !== undefined){
      items = JSON.parse(JSON.stringify(item.permission))
    }
    //console.log('items2 =>',items)
    // let permissionDefault = this.state.permissionDefault
    for(let i=permissionDefault.length-1; i>=0; i--) { 
      let indexOfitems = items.indexOf(permissionDefault[i].value)
      if( indexOfitems >= 0) {
        if(permissionDefault[i].subItems.length) { 
          items.splice(indexOfitems,1)
        }        
      }         
    }

    let modalForm = [
      { key: "name", name: "Name", placeholder:"", value: item.name, disabled: true, type: 'input', col: 1 }, 
      { key: "description", name: "Description", placeholder:"Enter Description..", value: item.description, disabled: false, type: 'textarea', rows: 3, col: 1 },
      // { key: "routeman", name: "Route man", placeholder:"", value: item.routeman, disabled: false, type: 'checkbox', col: 1 }, 
      { key: "permission", name: "Permission", placeholder:"", value: true, disabled: false, type: 'tree', col: 2, 
        tree: {
          treeData: treeData,
          expandedKeys: this.state.expandedKeys,
          autoExpandParent: this.state.autoExpandParent,
          checkedKeys: item.permission,
          selectedKeys: this.state.selectedKeys,
          onExpand: this.onExpand,
          onCheck: this.onCheck,
          onSelect: this.onSelect,
        } 
      }, 
    ]

    let modalTitle = 'Modify Organizations'
    this.setState({ 
      modalForm: modalForm, 
      modalEditProjectShow: true,      
      branchEdit: item, 
      modalTitle: modalTitle,
      modalShow: true,
    }, () => {      
      
    })
  }

  saveLog = (dataIn) => {

    fetch('https://api.ipify.org/?format=json').then(response => {
      return response.json();
    }).then((res) => {
      console.log('res ->', res)
      let user = this.state.user
      let data = dataIn
      data.ip = res.ip
      let organization = this.state.user.OrganizationSelect
      console.log("organization =>", organization)
      // return
      let THIS = this
      axios({
        method: 'post',
        url: window.url + 'api/v1/collection/' + organization + '/log',
        data: data,
        headers:{
          "Authorization": user.token
        }
      })
      .then(function (response1) {
        console.log('response1 ->',organization,'=',response1)

      })
      .catch(function (error) {
        console.log(error);
        // alert("error: " + JSON.stringify(error) );
        THIS.setState({ alertText: error.message, alertShow: true})
        THIS.setState({loadingOverlay: false})
      });
    })
  }

  statusOnline = (item) => {
    let connect = new Date(item.connect)
    let disconnect = new Date(item.disconnect)
    if(connect > disconnect) return <Text color={'green'}>Online</Text>
    return <Text color={'red'}>Offline</Text>
  }

  // modal funtions ====================================================================
  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    //+-console.log("handleChange RoleMan =>", name, value);
    let modalForm = this.state.modalForm
    // console.log("modalForm  =>", modalForm);
    for(let i=0; i<modalForm.length; i++) {
      if(modalForm[i].name === name) {
        modalForm[i].value = value
        break
      }
    }
    //+-console.log("handleChange modalForm =>", modalForm);
    this.setState({modalForm: modalForm})

  }
  // tree
  onExpand = expandedKeys => {
    //console.log('onExpand', expandedKeys);
    // if not set autoExpandParent to false, if children expanded, parent can not collapse.
    // or, you can remove all expanded children keys.
    this.setState({
      expandedKeys,
      autoExpandParent: false,
    });
    let modalForm = this.state.modalForm
    for(let i=0; i<modalForm.length; i++) {
      if(modalForm[i].type === 'tree') {
        modalForm[i].tree.expandedKeys = expandedKeys
        modalForm[i].tree.autoExpandParent = false
        break
      }
    }
    this.setState({modalForm: modalForm})
  };

  onCheck = checkedKeys => {
    //console.log('onCheck', checkedKeys);
    this.setState({ checkedKeys });
    let modalForm = this.state.modalForm 
    let home = this.state.home
    for(let i=0; i<modalForm.length; i++) {
      if(modalForm[i].type === 'tree') {
        modalForm[i].tree.checkedKeys = checkedKeys
        home = checkedKeys
        //break
      }

      if(modalForm[i].key === 'home') {
        //console.log("home")
        let items = JSON.parse(JSON.stringify(checkedKeys))
        let permissionDefault = this.state.permissionDefault

        for(let i=permissionDefault.length-1; i>=0; i--) { 
          let indexOfitems = items.indexOf(permissionDefault[i].value)
          //console.log("indexOfitems =>",indexOfitems)
          if( indexOfitems >= 0) {
            if(permissionDefault[i].subItems.length) { 
              items.splice(indexOfitems,1)
            }
          }         
        }

        home = items
        modalForm[i].items = home
        modalForm[i].value = modalForm[i].items[0]
      }
    }
    this.setState({
      modalForm: modalForm,
      home: home
    })
  };

  onSelect = (selectedKeys, info) => {
    //console.log('onSelect info', info);
    //console.log('onSelect selectedKeys', selectedKeys);
    this.setState({ selectedKeys });
    let modalForm = this.state.modalForm 
    for(let i=0; i<modalForm.length; i++) {
      if(modalForm[i].type === 'tree') {
        modalForm[i].tree.selectedKeys = selectedKeys
        break
      }
    }
    this.setState({modalForm: modalForm})
  };
  // modal funtions end ====================================================================

  // SelectBranchsLess
  // onChangeSelectBranchsLess = () => {
  //   console.log("onChangeSelectBranchsLess");
  // }

  // onGoPressSelectBranchsLess = () => {
  //   console.log("onGoPressSelectBranchsLess");
  // }
 
  onSearch = () => {
    // console.log('onSearch =>', this.state.searchText, this.state.usersRes);
    let search = this.state.searchText;    
    let res = this.state.usersRes.filter(obj => Object.keys(obj).some(key => { 
      let out = key!=='_id' && typeof(obj[key])=='string' && obj[key].includes(search) 
      // console.log('onSearch out =>', out);
      return out
    }))
    this.setState({searchButton: false, branchs: res}, () => {
      this.paginationItemCal()
      // this.usersPage(1)
      this.branchsPage(1)
    })
  }

  onSearchClear = () => {
    //+-console.log('onSearchClear =>', this.state.searchText);
    this.setState({searchText: '', searchButton: true, branchs: this.state.usersRes}, () => {
      this.paginationItemCal()
      // this.usersPage(1)
      this.branchsPage(1)
    })
        
  }

  handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.onSearch()
    }
  }

  //=========================================================================== render()
  render() {
    // let modalClose = () => this.setState({ modalAddProjectShow: false });
    
    const paginationStyle = {
      display: 'table',
      margin: '0 auto',
    };
    // let users = storeGlobal.getusers();
    // let props = storeGlobal.getprops();

    if(this.state.redirect) return ( <Redirect to={this.state.location} /> )

    return (   
      // <LoadingOverlay
      //   active={true}
      //   spinner={<SyncLoader />}
      // >  

      // </LoadingOverlay>   

      <SiteWrapper user={this.state.user} props = {this.props}>
        
        <Page.Content title="Organizations manager"
          options={ this.state.usersRes.length &&
            <Form.Group label="">
              <Form.InputGroup>
                <Form.Input 
                  placeholder="Search for..." 
                  onChange={e => this.setState({searchText: e.target.value, searchButton: true}) } 
                  value={this.state.searchText} 
                  onKeyPress={this.handleKeyPress}
                />
                <Form.InputGroupAppend>
                  { this.state.searchButton? 
                    <Button
                      color="primary"
                      icon="search"
                      onClick={this.onSearch}
                    /> 
                    : <Button
                      color="default"
                      icon="x"
                      onClick={this.onSearchClear}
                    /> 
                  }
                </Form.InputGroupAppend>
              </Form.InputGroup>
            </Form.Group>            
          }
        >
          {/* { ((this.state.user.organization.length > 1) && this.state.optionBranchs && this.state.optionProjects && this.state.optionOrganizations) &&
            <SelectBranchs 
              typeSelectFilter = {this.state.typeSelectFilter}
              getbranchs = {this.getbranchs} 
              optionOrganizations = {this.state.optionOrganizations}
              optionProjects = {this.state.optionProjects}
              optionBranchs = {this.state.optionBranchs}
              btLoading={this.state.btLoading}
            />
          } */}
         
          <Card>
            <Card.Header>
              <Card.Title>Organizations</Card.Title>
              { this.state.user.level==='write' && <Card.Options>
                <Button 
                  color="green"                  
                  onClick={ () => this.addBranch() }
                > New Organization </Button>                
              </Card.Options>
              }
            </Card.Header>
            <Table
              responsive
              className="card-table table-vcenter text-nowrap table-hover "
              // headerItems= { this.state.headerItems }
              // bodyItems={ this.state.bodyItems }              
            >
              <Table.Header>
                <Table.Row>
                  {/* <Table.ColHeader className="d-none">_id</Table.ColHeader> */}
                  <Table.ColHeader className="table-sticky-left">Item</Table.ColHeader>
                  <Table.ColHeader>ID</Table.ColHeader> 
                  <Table.ColHeader>NAME</Table.ColHeader>    
                  <Table.ColHeader>create By</Table.ColHeader>
                  <Table.ColHeader>update By</Table.ColHeader>
                  <Table.ColHeader>update date</Table.ColHeader>
                  { this.state.user.level==='write' && <Table.ColHeader className="text-center table-sticky-right">Action</Table.ColHeader> }
                </Table.Row>
              </Table.Header>
              <Table.Body>
                { this.state.branchsPage.length ? this.state.branchsPage.map( (item, i) =>
                  <Table.Row key={ i }>
                    <Table.Col className="table-sticky-left"> { ((this.state.paginationActive-1) * this.state.branchsPageLimit) + (i+1) }</Table.Col>
                    <Table.Col>{ item.id }</Table.Col>
                    <Table.Col>{ item.name }</Table.Col>
                    <Table.Col>{ item.createBy }</Table.Col> 
                    <Table.Col>{ item.updateBy }</Table.Col> 
                    {/* <Table.Col>{ item.createDate }</Table.Col> */}
                    <Table.Col>
                      <Moment format="DD/MM/YYYY HH:mm">
                        { item.updateDate }
                      </Moment>
                    </Table.Col>
                    
                    { this.state.user.level==='write' && <Table.Col className="text-center table-sticky-right">
                      <Button.List>                        
                          <Button size="sm" color="info"
                            onClick={ () => this.editBranch(item) }
                            >
                            Edit
                          </Button>
                        {/* <Button size="sm" color="info"
                          onClick={ () => this.detailProject(item) }
                          >
                            Detail
                          </Button> */}
                        <Button size="sm" color="danger" 
                          onClick={ ()=> { this.alert({action:'delete', id: item._id, branch: item.name, i: i }) } } >
                          Delete
                        </Button>                        
                      </Button.List>
                    </Table.Col> 
                    }          
                  </Table.Row>
                ): <Table.Row > 
                    <Table.Col colSpan="12"	alignContent="center">
                      <Text className="my-4" size="" center="true" color="primary" >ไม่พบข้อมูล : Data not found.</Text>
                    </Table.Col>
                  </Table.Row>
                }
  
                <Table.Row > 
                  <Table.Col colSpan="12"	alignContent="center">
                    <div style={paginationStyle}>
                      <Pagination>
                        { this.state.paginationItem.map( number =>
                          <Pagination.Item key={number} active={number === this.state.paginationActive} onClick={ this.paginationActive.bind(this, number) }>
                            {number} 
                          </Pagination.Item>
                        )}
                      </Pagination>
                    </div>      
                  </Table.Col>
                </Table.Row>

              </Table.Body>
            </Table>
          </Card>

        </Page.Content>
        
        <ModalFormLarge
          show={this.state.modalShow}
          onHide={this.modalClose}
          onSave={this.getForm}
          onInputChange={this.handleInputChange}
          title={this.state.modalTitle}      
          worning={this.state.modalWorning}
          form={this.state.modalForm}
        />

        {this.state.alert}
       
      </SiteWrapper>   
      
    )
    
  }
}

export default RoleMan;