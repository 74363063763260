// @flow
import React,{Component} from 'react'
import {  Page,  Icon,  Grid,  Card,  Text,  Table,  Button,  Form } from "tabler-react";
import SiteWrapper from "../components/SiteWrapper.react";
import axios from 'axios';
//import ModalAddUser from "../components/ModalAddUser";
import ModalFormLarge from "../components/ModalFormImage";
//import ModalFormLarge from "../components/ModalFormSmall";
import SweetAlert from 'react-bootstrap-sweetalert'
import Pagination from 'react-bootstrap/Pagination'
import storeGlobal from "../components/globalStore";
import SingleSelect from "../components/SingleSelect";
// import AnimatedMulti from "../components/MultiSelect";
import DatePicker from "react-datepicker";
import Select from 'react-select';
//import SelectBranchs from "../components/SelectBranchs";
//import LoadingOverlay from 'react-loading-overlay'
// import SyncLoader from 'react-spinners/SyncLoader'
import { Redirect } from "react-router-dom";

class DetailPrescription extends Component{
  constructor(props) {
    super(props);
    this.state = {
      user: storeGlobal.getusers(),

      modalWorning:'',
      modalFormTitle:"",
      header:[
        {"name":"ITEM", "class":"text-center table-sticky-left"},
        {"name":"IMAGE", "class":"text-center"},
        {"name":"NAME", "class":"text-left"},
        {"name":"PRICE", "class":"text-center"},
        {"name":"AMOUNT", "class":"text-center"},
        {"name":"AMOUNT PAID", "class":"text-center"},
        {"name":"STATUS", "class":"text-center"},
        {"name":"ACTION", "class":"text-center table-sticky-right"}
      ],
      tempModalForm:{},
      modalForm: [
        { key: "branchID", name: "ID", placeholder:"", value: "", disabled: false, type: 'image', col: '2' }, 
        { key: "branchID", name: "ID", placeholder:"", value: "", disabled: false, type: 'input', col: '2' }, 
        { key: "branch", name: "Name", placeholder:"", value: "", disabled: false, type: 'input',col: '2' }, 
        { key: "location", name: "Location", placeholder:"", value: "", disabled: false, type: 'input',col: '2' }, 
        { key: "project", name: "Project", placeholder:"", value: "", disabled: false, type: 'select',col: '2', items: [
          'p1', 'p2', 'p3'
        ] }, 
      ],

      alert: null,
      paginationItem: [1],
      paginationActive: 1,
      countPerPage:48,
      organizItems:[],
      projectItems:[],
      dataResponse:{
        activeDate: new Date(),
        expireDate: new Date(),
        products:[],
        branchID:[],
        branchID1:[]
      },
      dataShow:[],
      products:[],

      Organization:[],
      Project: [],
      Branchs: [],
      OrganizationSelect:null,
      ProjectSelect:null,
      BranchidSelect:[],
      BranchsSelect:[],
      branchIDOptionsDefault:[],

      redirect: false,
      location: {},

      defaultFileUpload:{
        organization:'',
        uri:window.url + 'upload',
        type:'products',
        filetype:['image/png', 'image/jpeg']
      },

      isButtonLoading: false,
      isButtonEnable: false,
      isButtonBackEnable: true,
      isButtonAdd: false,
      alertShow: false,
      alertText: '',
      isButtonSelectFileEnable: true,
      isFilePondEnable: true,
      flgMode: "new",
      dataCopied: ""
    };

    // this.handleClick = this.handleClick.bind(this);

  }

  refresh = (dataIn) => {
  //console.log('props -->', this.props.location.state)
  let users = this.state.user
  //+-console.log("componentDidMount users =>",users);
  if(!Object.entries(users).length) return this.goto401()
  if(this.props.location.state === undefined) return this.goto401()
  
  if(!(Object.entries(users).length === 0)){
      let THIS = this
      
      let organization = users.OrganizationSelect
      let collection = 'order'
      let id = this.props.location.state.id
      let defaultFileUpload = this.state.defaultFileUpload
      defaultFileUpload['organization'] = organization 
      this.setState({defaultFileUpload:defaultFileUpload})
      if(id !== null && id !== undefined){
        axios({
          method: 'get',
          url: window.url + 'api/v1/collection/' + organization + '/' + collection+ '/' + id,
          data: 'values',
          headers:{
            "Authorization": this.state.user.token
          }
        })
        .then(function (response) {
          //+-console.log("response -->",response.data);
          let dataResponse =  response.data.value
          dataResponse.activeDate = new Date(dataResponse.activeDate)
          dataResponse.expireDate = new Date(dataResponse.expireDate)
          // console.log('id dataResponse =>',dataResponse)
          THIS.setState({
            dataResponse: dataResponse,
            isButtonRefreshLoading: true,
            flgMode :"edit",
            isButtonEnable: false,
            isButtonAdd : true
          })
          
          //sort product by name 
          let p = dataResponse.products
          p.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
          
          let products = dataResponse.products
          THIS.paginationItemCal(products,1)
        })
        .catch(function (error) {
          THIS.setState({
            alertShow: true,
            alertText: error.message
          })
          console.log('axios error =>',error.message);
        });
      }else{
        if(this.props.location.state.orderId === undefined) return this.goto401()
        let dataResponse =  {}
        var today = new Date();
        var tomorrow = new Date();
        tomorrow.setDate(today.getDate()+1);
        dataResponse.activeDate = today
        dataResponse.expireDate = tomorrow
        
        dataResponse.date = new Date()
        dataResponse.status = 'New'
        dataResponse.orderId = this.props.location.state.orderId
        dataResponse.products = []
        // console.log('dataResponse =>',dataResponse)
        this.setState({
          dataResponse: dataResponse,
          isButtonRefreshLoading: true,
          isButtonAdd : false
        })
      }

      let THIS2 = this
      let organization2 = users.OrganizationSelect
      let collection2 = 'products'
      //let id = this.props.location.state.id
      axios({
        method: 'get',
        url: window.url + 'api/v1/collection/' + organization2 + '/' + collection2,
        data: 'values',
        headers:{
          "Authorization": this.state.user.token
        }
      })
      .then(function (response) {
        // console.log("response -->",response.data);

        //sort product by name 
        let p = response.data.value
        p.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));

        THIS2.setState({
          products:response.data.value,
          isButtonRefreshLoading: true
        })
      })
      .catch(function (error) {
        THIS.setState({
          alertShow: true,
          alertText: error.message
        })
        console.log('axios error =>',error.message);
      });


      let Organization = [];
      for(let i = 0; i < users.organization.length; i++){
        Organization.push({label:users.organization[i],value:users.organization[i]})
      }

      //let Project = []
      let Project = [{label:"All",value:"All"}];
      for(let i = 0; i < users.project.length; i++){
        Project.push({label:users.project[i].projectName,value:users.project[i].projectName})
      }

      let BranchidSelect = []
      for(let i = 0; i < users.BranchidSelect.length; i++){
        BranchidSelect.push({label:users.BranchidSelect[i],value:users.BranchidSelect[i]})
      }

      let Branchs = this.branchIDOptions()


      this.setState({
        Organization: Organization,
        OrganizationSelect: users.OrganizationSelect,
        Project: Project,
        ProjectSelect: users.ProjectSelect,
        Branchs: Branchs,
        BranchidSelect: BranchidSelect
      })
    }
  }

  componentDidMount() {
    this.refresh()
  }

  // componentWillMount(){
  //   this.state.dataResponse.activeDate = new Date()
  //   this.state.dataResponse.expireDate = new Date()
  // }

  handleClick(e) {
    console.log('this ->', e)
  }

  hideAlert() {
    this.setState(state => ({
      alert: null
    }));
  }
  
  alert(e) {
    // console.log(e)
    switch(e.action) {
      case 'delete':
        this.setState( state => ({
          alert: (
            <SweetAlert
              warning
              showCancel
              confirmBtnText="Yes, delete it!"
              confirmBtnBsStyle="danger"
              cancelBtnBsStyle="default"
              title="Are you sure?"
              onConfirm={ ()=> {
                  this.deleteDb(e,(fn) =>{ 
                    if(fn) console.log('delete error ->',fn) 
                    //console.log("dataResponse ->",this.state.dataResponse)
                  })
                } 
              } //{this.deleteFile}
              onCancel={ this.hideAlert.bind(this) }
              >
              Delete "{e.name}"
            </SweetAlert>
          )
        }));
        break
        case 'warning':
        this.setState( state => ({
          alert: (
            <SweetAlert
              warning
              title= {e.title}
              //onConfirm={(value)=> {e.value = value; this.resetPwd(e)} }
              //onCancel={this.hideAlert.bind(this)}
              onConfirm={this.hideAlert.bind(this)}
              >
            </SweetAlert>
          )
        }));
        break
        default:
        break
    }
    
  } 
  
  deleteDb = (dataIn,fn) => {
    //const uuidv1 = require('uuid/v1');
    //console.log('uuidv1 ->',uuidv1());
    console.log('deleteDb dataIn ->', dataIn); 
    //console.log('deleteDb dataResponse ->', dataResponse); 
    //let organization  = 'server'
    let users = this.state.user
    let organization = users.OrganizationSelect
    let collection = 'order'
    let dataResponse = this.state.dataResponse
    let id = dataResponse._id
    let THIS = this
    let dataSave = dataResponse.products
    dataResponse.status = 'Delete product'
    dataResponse.createBy = this.state.user.username
    dataResponse.updateBy = this.state.user.username
    for(let i = 0; i < dataSave.length; i++){
      if(dataSave[i]._id === dataIn.id){
        dataSave.splice(i,1)
        i--
      }
    }
    let values = {
      products:dataSave,
      status:dataResponse.status,
      createBy:dataResponse.createBy,
      updateBy:dataResponse.updateBy
    }
    //console.log('deleteDb values ->', values); 
    //fn(null)
    THIS.hideAlert()
    axios({
      method: 'put',
      url: window.url + 'api/v1/collection/' + organization + '/' + collection + '/' + id,
      // url: 'http://13.251.52.58:3001/api/v1/users/' + this.state.user.organization,
      data: values,
      headers:{
        "Authorization": this.state.user.token
      }
    })
    .then(function (response) {
      //+-console.log("deleteDb response =>", response.data); // {oganization:'server',value:{n: 1, ok: 1}}
      let dataSave = {
        "type" : 'warning', //information //warning // error
        "date" : new Date(),
        "surce" : 'detailCampaign',
        "category" : 'webApp',
        "even" : 'Delete product',
        "user" :  THIS.state.user.username,
        "ip" : "",
        "discription" : 'Delete product =>'+dataIn.name + ' in Campaign'
      }
      THIS.saveLog(dataSave)
      let products = dataResponse.products
      products = products.filter(person => person._id !== dataIn.id)
      dataResponse.products = products
      THIS.setState({ 
        dataResponse: dataResponse,
        isButtonEnable: true
      },() => {        
        let paginationActive = THIS.state.paginationActive
        THIS.paginationItemCal(products,paginationActive)
      })
      //THIS.hideAlert()
      fn(null)
    })
    .catch(function (error) {
      console.log(error);
      THIS.setState({modalWorning: "insert error!"})
      //THIS.hideAlert()
      fn(error)
    });
  }

  // delete(dataIn, fn) {
  //   console.log('delete =>', dataIn)
  //   let dataSave = this.state.dataResponse.products
  //   for(var i = 0; i < dataSave.length; i++){
  //     if(dataSave[i]._id == dataIn.id){
  //       dataSave.splice(i,1)
  //       i--
  //     }
  //   }
  //   let THIS = this
  //   let id = this.state.dataResponse._id
  //   let values = {
  //     products:dataSave,
  //     status:'deleteProduct',
  //     createBy:this.state.user.username,
  //     updateBy:this.state.user.username
  //   }
  //   axios({
  //     method: 'put',
  //     url: window.url + 'api/v1/collection/' + this.state.user.organization + '/' + 'branch'+ '/' + id,
  //     // url: 'http://13.251.52.58:3001/api/v1/users/' + this.state.user.organization,
  //     data: values,
  //     headers:{
  //       "Authorization": this.state.user.token
  //     }
  //   })
  //   .then(function (response) {
  //     console.log("Add branch response =>", response.data.value); // {n: 1, nModified: 1, ok: 1}
  //     //THIS.setState({dataResponse:response.data.value})
  //     // let branchAdd = response.data.value[0]
  //     // THIS.state.branchsPage.unshift(branchAdd)
  //     // THIS.setState({usersPage: THIS.state.branchsPage})
  //     let products = THIS.state.dataResponse.products
  //     let paginationActive = THIS.state.paginationActive
  //     THIS.paginationItemCal(products,paginationActive)
  //     THIS.hideAlert()
  //   })
  //   .catch(function (error) {
  //     console.log(error);
  //     THIS.hideAlert()
  //   });    
  // }

  insertDb = (dataIn,fn) => {
    //const uuidv1 = require('uuid/v1');
    //console.log('uuidv1 ->',uuidv1());
    console.log('insertDb dataIn ->', dataIn);  
    //let organization  = 'server'
    let users = this.state.user
    let organization = users.OrganizationSelect
    let collection = 'order'
    let THIS = this
    dataIn.activeDate = new Date(dataIn.activeDate)
    let values = dataIn
        values.createBy = this.state.user.username
        values.updateBy = this.state.user.username
    //console.log('insertDb values ->', values); 
    //fn(null)
    axios({
      method: 'post',
      url: window.url + 'api/v1/collection/' + organization + '/' + collection,
      // url: 'http://13.251.52.58:3001/api/v1/users/' + this.state.user.organization,
      data: values,
      headers:{
        "Authorization": this.state.user.token
      }
    })
    .then(function (response) {
      console.log("insert response =>", response.data); // {acknowledged: true, insertedId: '61b6d89483c392744c1b5c29'}
      if(response.data.value.length){
        THIS.setState({ 
          dataResponse: response.data.value[0]
        },() => {   
          fn(null)
        })
      }else{
        fn(response.data.value)
      }


      // let products = THIS.state.dataResponse
      // products.push(response.data.value[0])
      // //--------------------------cerren last page
      // THIS.setState({ 
      //   dataResponse: products
      // },() => {        
      //   let page = THIS.state.countPerPage
      //   let pageCount = Math.ceil(products.length / page)
      //   //console.log('paginationActive ->',pageCount)
      //   THIS.paginationItemCal(products,pageCount)
      // })
      // fn(null)
    })
    .catch(function (error) {
      console.log(error);
      THIS.setState({modalWorning: "insert error!"})
      fn(error)
    });
  }

  updateDb = (dataIn,fn) => {
    //const uuidv1 = require('uuid/v1');
    //console.log('uuidv1 ->',uuidv1());
    console.log('updateDb dataIn ->', dataIn);  
    //let organization  = 'server'
    let users = this.state.user
    let organization = users.OrganizationSelect
    let collection = 'order'
    let id = dataIn._id
    let THIS = this
    let values = JSON.parse(JSON.stringify(dataIn));
        values.createBy = this.state.user.username
        values.updateBy = this.state.user.username
    delete values._id
    //console.log('updateDb values ->', values);
    //console.log('updateDb dataIn2 ->', dataIn); 
    //fn(null)
    axios({
      method: 'put',
      url: window.url + 'api/v1/collection/' + organization + '/' + collection + '/' + id,
      // url: 'http://13.251.52.58:3001/api/v1/users/' + this.state.user.organization,
      data: values,
      headers:{
        "Authorization": this.state.user.token
      }
    })
    .then(function (response) {
      console.log('updateDb response =>',response.data) // {acknowledged: true , matchedCount: 1, modifiedCount: 1, upsertedCount: 0, upsertedId: null}
      if(response.data.value.acknowledged){
        THIS.setState({ 
          dataResponse: dataIn
        },() => {   
          fn(null)
        })
      }else{
        fn(response.data.value)
      }
      //+-console.log("updateDb response =>", response.data); // {n: 1, nModified: 1, ok: 1}
      let dataSave = {
        "type" : 'warning', //information //warning // error
        "date" : new Date(),
        "surce" : 'detailCampaign',
        "category" : 'webApp',
        "even" : 'Save campaigns',
        "user" :  THIS.state.user.username,
        "ip" : "",
        "discription" : 'Save campaigns'
      }
      THIS.saveLog(dataSave)
    })
    .catch(function (error) {
      console.log(error);
      THIS.setState({modalWorning: "updateDb error!"})
      fn(error)
    });
  }
  
  paginationActive(e) {
    let dataShow = this.state.dataResponse.products
    this.paginationItemCal(dataShow,e)
  }

  paginationItemCal(dataIn,paginationActive) {
    //console.log("pageCount ->",dataIn.length,"paginationActive ->",paginationActive)
    let page = this.state.countPerPage
    let pageCount = Math.ceil(dataIn.length / page)
    let tmppaginationActive = this.state.paginationActive 
    let paginationItem = []

    switch (paginationActive){
      case '>':
          tmppaginationActive++;
      break;
      case '<':
          tmppaginationActive--;
      break;
      case '>|':
          tmppaginationActive = pageCount;
      break;
      case '|<':
          tmppaginationActive  = 1;
      break;
      default:
          tmppaginationActive = paginationActive*1
      break;
    }

    if(tmppaginationActive > pageCount){
      tmppaginationActive = pageCount;
    }

    if(pageCount < 6){
      for(let i = 1; i <= pageCount; i++){
          paginationItem.push(i)
      }
    }else{
      if(tmppaginationActive < 4){
        for(let i = 1; i <= pageCount; i++){
          if(i < 6){
            paginationItem.push(i)
          }else if(i === 6){
            paginationItem.push(">")
          }else if(i === 7){
            paginationItem.push(">|")
          }else{
            break;
          }  
        }
      }else if(tmppaginationActive > (pageCount-3)){
        for(let i = 1; i < 8; i++){
          if(i > 2){
            paginationItem.push((pageCount - (7-i)))
          }else if(i === 2){
            paginationItem.push("<")
          }else if(i === 1){
            paginationItem.push("|<")
          }else{
            break;
          }  
        }  
      }else{
        for(let i = 1; i < 10; i++){
          switch(i){
            case 1:
              paginationItem.push("|<")
            break;
            case 2:
              paginationItem.push("<")
            break;
            case 3:
                paginationItem.push(tmppaginationActive-2)
            break;
            case 4:
                paginationItem.push(tmppaginationActive-1)
            break;
            case 5:
              paginationItem.push(tmppaginationActive)
            break;
            case 6:
                paginationItem.push(tmppaginationActive+1)
            break;
            case 7:
              paginationItem.push(tmppaginationActive+2)
            break;
            case 8:
                paginationItem.push(">")
            break;
            case 9:
                paginationItem.push(">|")
            break;
            default:
            break
          }
        }  
      }
    }

    let dataShow = []
    if(dataIn.length){
      for(let i = page * (tmppaginationActive -1); ((i < page*tmppaginationActive) &&  (i < dataIn.length)); i++){
        dataShow.push(dataIn[i])
      }
    }

    this.setState({
      dataShow: dataShow,
      paginationItem: paginationItem,
      paginationActive: tmppaginationActive
    })
  }
  
  modalClose = () => {
    // this.setState({modalWorning: ""})
    this.setState({ modalWorning: "", modalAddbranchShow: false })
    // console.log('this.state.modalForm =>', this.state.modalForm)
  };

  getForm = () => {
    //const uuidv1 = require('uuid/v1');
    //console.log('uuidv1 ->',uuidv1());
    console.log("dataResponse1 ->",this.state.dataResponse)
    //+-console.log('getForm modalForm =>', this.state.modalForm);   
    let dataSave=[]
    let status = "Delete product"
    let dataResponse = this.state.dataResponse 
    if(this.state.modalFormTitle === "Add Products"){
      let dataEdit = this.state.modalForm
      let addProduct = []
      for(let k = 0; k < dataEdit.length; k++) {
        if(dataEdit[k].cardSelect) {
          let products = JSON.parse(JSON.stringify(this.state.products))
          for(let i = 0; i < products.length; i++){
            if(products[i]._id === dataEdit[k]._id){
              let existingProducts = JSON.parse(JSON.stringify(dataResponse.products))
              for(var j = 0; j < existingProducts.length; j++){
                if(existingProducts[j]._id === dataEdit[k]._id){
                  addProduct.push(existingProducts[j])
                  break
                }
              }
              if(j >= existingProducts.length){
                products[i].status = "New product"
                status = "New product"
                addProduct.push(products[i])
              }
              break
            }
          }
        }
      }
      //console.log('addProduct ->',addProduct)
      dataSave = addProduct
      dataResponse.products = addProduct
      dataResponse.status = status
      dataResponse.createBy = this.state.user.username
      dataResponse.updateBy = this.state.user.username
      this.setState({
        dataResponse: dataResponse,
        isButtonEnable: true,
        isButtonBackEnable: false
      })
    }else{
      let dataEdit = this.state.tempModalForm
      for(let k=0; k<this.state.modalForm.length; k++) {
        if(this.state.modalForm[k].value === '') {
          return this.setState({modalWorning: "Input is blank!"})
        }
        dataEdit[this.state.modalForm[k].key] = this.state.modalForm[k].value
      }
      //console.log("edit product ->",dataEdit)
      let existingProducts = dataResponse.products
      for(let j = 0; j < existingProducts.length; j++){
        if(existingProducts[j]._id === dataEdit._id){
          existingProducts[j] = dataEdit
          status = "Modify product"
          break
        }
      }
      dataSave = existingProducts
      dataResponse.products = existingProducts
      dataResponse.status = status
      dataResponse.createBy = this.state.user.username
      dataResponse.updateBy = this.state.user.username
      this.setState({
        dataResponse: dataResponse,
        isButtonEnable: true,
        isButtonBackEnable: false
      })
    } 

      console.log('getForm data =>', dataSave);
      // this.modalClose()
      // return
      let users = this.state.user
      let organization = users.OrganizationSelect
      let collection = 'order'
      let id = dataResponse._id
      let THIS = this
      let values = {
        products:dataSave,
        status:dataResponse.status,
        createBy:dataResponse.updateBy,
        updateBy:dataResponse.updateBy
      }
      if(id !== undefined){
        axios({
          method: 'put',
          url: window.url + 'api/v1/collection/' + organization + '/' + collection + '/' + id,
          // url: 'http://13.251.52.58:3001/api/v1/users/' + this.state.user.organization,
          data: values,
          headers:{
            "Authorization": this.state.user.token
          }
        })
        .then(function (response) {
          console.log("Add branch response =>", response.data.value); // {n: 1, nModified: 1, ok: 1}
          //console.log("dataResponse.data.value ->",dataResponse)
          let discription = ''
          let statusLog = ''
          if(status == 'New product'){
            statusLog = status
            discription = status + ' in campaigns'
          }else{
            statusLog = 'Edit product'
            discription = 'Edit product in campaigns'
          }
          let dataSave = {
            "type" : 'warning', //information //warning // error
            "date" : new Date(),
            "surce" : 'detailCampaign',
            "category" : 'webApp',
            "even" : statusLog,
            "user" :  THIS.state.user.username,
            "ip" : "",
            "discription" : discription
          }
          THIS.saveLog(dataSave)

          let products = values.products
          // console.log("THIS.state.paginationActive =>",THIS.state.paginationActive)
          // let paginationActive = THIS.state.paginationActive
          THIS.paginationItemCal(products,1)
          THIS.modalClose()
        })
        .catch(function (error) {
          console.log(error);
          THIS.setState({modalWorning: "Edit user error!"})
        });
      }else{
        THIS.paginationItemCal(dataSave,1)
        THIS.modalClose()
      }

    // if(dataEdit.status == 'addCampaign'){
    //   this.insertDb(dataEdit,(calback) =>{
    //     if(calback) console.log('insertDb error ->',calback)
    //     this.modalClose()
    //   })
    // }else{
    //   this.updateDb(dataEdit,(calback) =>{
    //     if(calback) console.log('updateDb error ->',calback)
    //     this.modalClose()
    //   })
    // }
  }

  edit = (item) => {    
    //+-console.log('addBranch item =>',item);
    let tempModalForm = JSON.parse(JSON.stringify(item))
        tempModalForm.status = 'Modify product'
    let modalFormTitle = "Modify product"
    let modalForm = [
      { key: "image", name: "image", placeholder:"", value: item.image, disabled: false, type: 'image', path:item.path, col: '1' }, 
      { key: "sku", name: "SKU", placeholder:"", value: item.sku, disabled: true, type: 'input', col: '2' },
      { key: "name", name: "Name", placeholder:"", value: item.name, disabled: true, type: 'input', col: '2' }, 
      { key: "price", name: "Price", placeholder:"", value: item.price, disabled: true, type: 'input', col: '2' }, 
      { key: "amount", name: "Amount", placeholder:"", value: item.amount, disabled: false, type: 'input', col: '2' },
      // { key: "group", name: "Group", placeholder:"", value: item.group, disabled: false, type: 'input', col: '2' }, 
      // { key: "channel", name: "Channel", placeholder:"", value: item.channel, disabled: true, type: 'input', col: '2' }, 
      // { key: "count", name: "Cerrent Value", placeholder:"", value: item.count, disabled: true, type: 'input', col: '2' }, 
      // { key: "max", name: "Max Value", placeholder:"", value: item.max, disabled: true, type: 'input', col: '2' }, 
      // { key: "max", name: "Max Value", placeholder:"", value: item.max, disabled: true, type: 'input', col: '2' }, 
    ]
    // console.log('modalForm =>', modalForm);
    //this.setState({ modalForm: this.state.modalForm, modalAddbranchShow: true })
    this.setState({ 
      tempModalForm: tempModalForm,
      modalForm: modalForm,
      modalFormSave: modalForm,
      modalFormTitleSave: modalFormTitle,
      modalFormTitle: modalFormTitle,
      modalAddbranchShow: true,
      isButtonSelectFileEnable: false,
      isFilePondEnable: false
    })
  }

  add = () => {    

    console.log('addBranch item =>',this.state.products);
    let tempModalForm = this.state.tempModalForm
        tempModalForm = "New product"
    let modalFormTitle = "Add Products"
    let modalForm = []
    for(let i = 0; i < this.state.products.length; i++){
      let item = this.state.products[i] 
      item.amount = 1
      item.amountPaid = 0
      let tempObj =  JSON.parse(JSON.stringify(item))
        tempObj.type = 'imageSelect'
        //tempObj.path = tempObj.path
        tempObj.value = tempObj.image
        tempObj.col = 1
        tempObj.cardSelect = false
        let existingProducts = this.state.dataResponse.products
        for(let j = 0; j < existingProducts.length; j++){
          if(existingProducts[j]._id === tempObj._id){
            tempObj.cardSelect = true
            break
          }
        }
        modalForm.push(tempObj)
    }
    // this.state.modalForm = [
    //   { key: "image", name: "image", placeholder:"", value: "new-campaign-red.jpg", disabled: false, type: 'image', path:'/campaigns/', col: '1' }, 
    //   { key: "name", name: "Name", placeholder:"", value: "", disabled: false, type: 'input', col: '2' }, 
    //   { key: "activeDate", name: "Active Date", placeholder:"", value: "", disabled: false, type: 'date', col: '2' }, 
    //   { key: "expireDate", name: "Expire Date", placeholder:"", value: "", disabled: false, type: 'date', col: '2' }, 
    //   // { key: "channel", name: "Channel", placeholder:"", value: "", disabled: false, type: 'input', col: '2' }, 
    //   // { key: "count", name: "Cerrent Value", placeholder:"", value: "", disabled: false, type: 'input', col: '2' }, 
    //   // { key: "max", name: "Max Value", placeholder:"", value: "", disabled: false, type: 'input', col: '2' }, 
    // ]
    console.log('modalForm =>', modalForm);
    //this.setState({ modalForm: this.state.modalForm, modalAddbranchShow: true })
    this.setState({ 
      tempModalForm: tempModalForm,
      modalForm: modalForm,
      modalFormSave: modalForm,
      modalFormTitleSave: modalFormTitle,
      modalFormTitle: modalFormTitle,
      modalAddbranchShow: true,
      isButtonSelectFileEnable: true,
      isFilePondEnable: false
    })
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    // console.log("handleChange event  =>", event.target);
    // console.log("this.state.modalForm  =>", this.state.modalForm);
    let modalForm = this.state.modalForm
    for(let i=0; i<modalForm.length; i++) {
      if(modalForm[i].name === name) {
        modalForm[i].value = value
        break
      }
    }

    this.setState({modalForm: modalForm})
  }

  handleInputChangeMain = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    //+-console.log("handleInputChangeMain event  =>", event.target);
    let dataResponse = this.state.dataResponse

    dataResponse[name] = value
    //+-console.log("dataResponse =>",dataResponse)
    this.setState({
      dataResponse: dataResponse,
      isButtonEnable: true
    })
  }

  handleInputChangeSearch = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    //+-console.log("handleInputChangeMain event  =>", event.target);
    let dataResponse = this.state.dataResponse

    dataResponse[name] = value
    //console.log("dataResponse =>",dataResponse)
    this.setState({
      dataResponse: dataResponse,
      searchButton: true,
      isButtonEnable: false,
      isButtonAdd : false,
      isButtonBackEnable: true
    })
  }

  handleChangeActiveDate(date) {
    // let start = new Date('2019-01-29T08:08:09.000Z') //test
    //console.log("handleChange activeDate =>",date);
    let tempRes = this.state.dataResponse
    tempRes.activeDate = date
    if(tempRes.products.length){
      for(let i = 0; i < tempRes.products.length; i++){
        let tmpProducts = tempRes.products[i]
        tmpProducts["activeDate"] = date
      }
    }
    //console.log("tempRes.products =>",tempRes.products)
    this.setState({
      dataResponse: tempRes,
      isButtonEnable: true
    });
  }

  handleChangeExpireDate(date) {
    // let start = new Date('2019-01-29T08:08:09.000Z') //test
    //+-console.log("handleChange expireDate=>",date);
    let tempRes = this.state.dataResponse
    tempRes.expireDate = date
    if(tempRes.products.length){
      for(let i = 0; i < tempRes.products.length; i++){
        let tmpProducts = tempRes.products[i]
        tmpProducts["expireDate"] = date
      }
    }
    //console.log("tempRes.products =>",tempRes.products)
    this.setState({
      dataResponse: tempRes,
      isButtonEnable: true
    });
  }

  // imageOnclick = (dataIn) => {
  //   console.log('dataIn.cardSelect ->',dataIn.cardSelect)
  //   for(let i=0; i<this.state.modalForm.length; i++) {
  //     if(this.state.modalForm[i]._id == dataIn._id) {
  //       this.state.modalForm[i].cardSelect = dataIn.cardSelect
  //       break
  //     }
  //   }
  //   this.setState({modalForm: this.state.modalForm})
  // }

  changOrganization = (dataCallback) => {
    let users = storeGlobal.getusers();
    let project = [{label:"All",value:"All"}];
    //+-console.log("changOrganization dataCallback ->",dataCallback);
    if(dataCallback.value === "All"){
      for(let i = 0; i < users.project.length; i++){
        project.push({label:users.project[i].projectName,value:users.project[i].projectName})  
      }
    }else{
      for(let i = 0; i < users.project.length; i++){
        if(dataCallback.value === users.project[i].organization){
          project.push({label:users.project[i].projectName,value:users.project[i].projectName})    
        }
      }
    }
    //+-console.log("project",project);
    this.setState({
      OrganizationSelect:dataCallback.value,
      ProjectSelect:"All",
      BranchidSelect:[{label:"All",value:"All"}],
      Project:project
    })
  }
  
  changProject = (dataCallback) => {
    let users = storeGlobal.getusers();
    //this.state = storeGlobal.getFilterBranchs();
    let branchs = [{label:"All",value:"All"}];
    //+-console.log("changProject dataCallback ->",dataCallback)
    //console.log("this.state.Project =>",this.state.Project)
    //console.log("users.branch =>",users.branch)
    if(dataCallback.value === "All"){
      for(let j = 0; j < this.state.Project.length; j++){
        for(let i = 0; i < users.branch.length; i++){
          if(users.branch[i].projectName === this.state.Project[j].value){
            branchs.push({label:users.branch[i].branchID,value:users.branch[i].branchID})  
          }
        }
      }
    }else{
      for(let i = 0; i < users.branch.length; i++){
        if(dataCallback.value === users.branch[i].projectName){
          branchs.push({label:users.branch[i].branchID,value:users.branch[i].branchID})  
        }
      }
    }
    this.setState({
      ProjectSelect:dataCallback.value,
      BranchidSelect:[{label:"All",value:"All"}],
      Branchs:branchs
    });
    //this.setState({Branchs:branchs});
    //storeGlobal.setFilterBranchs(this.state);
    //let FilterBranchs = storeGlobal.getFilterBranchs();
    //this.Branchs = FilterBranchs.Branchs;
  }
  
  changBranchs = (dataCallback) => {
    //console.log("changBranchs ->",dataCallback);
    let users = storeGlobal.getusers();
    let branchidSelect = [];
    let branchsSelect = [];
    for(var i = 0; i < dataCallback.length; i++){
      branchidSelect.push(dataCallback[i]);
  
      for(var j = 0; j < users.branch.length; j++){
        if(users.branch[j].branchID === dataCallback[i].value){
          branchsSelect.push(users.branch[j]);
          break;
        }
      }
    }
    this.setState({
      BranchidSelect:branchidSelect,
      BranchsSelect:branchsSelect
    });
  }
  
  changBranchsSect = (dataIn) => {
    //+-console.log("changBranchs ->",dataIn);
    let users = storeGlobal.getusers();
    let branchidSelect = [];
    let branchsSelect = [];
    for(let i = 0; i < dataIn.length; i++){
      branchidSelect.push(dataIn[i].value);
  
      for(let j = 0; j < users.branch.length; j++){
        if(users.branch[j].branchID === dataIn[i].value){
          branchsSelect.push(users.branch[j]);
          break;
        }
      }
  
      if(dataIn[i].value === "All"){
        if(this.state.OrganizationSelect === "All"){
          if(this.state.ProjectSelect === "All"){
            branchidSelect = [];
            branchsSelect = [];
            for(let j = 0; j < users.branch.length; j++){
              branchidSelect.push(users.branch[j].branchID);
              branchsSelect.push(users.branch[j]);
            }
          }else{
            branchidSelect = [];
            branchsSelect = [];
            for(let k = 0; k < users.branch.length; k++){
              if(users.branch[k].projectName === this.state.ProjectSelect){
                branchidSelect.push(users.branch[k].branchID);
                branchsSelect.push(users.branch[k]);
              }
            }   
          }
        }else {
          if(this.state.ProjectSelect === "All"){
            branchidSelect = [];
            branchsSelect = [];
            for(let j = 0; j < users.project.length; j++){
              if(users.project[j].organization === this.state.OrganizationSelect){
                for(var k = 0; k < users.branch.length; k++){
                  if(users.project[j].projectName === users.branch[k].projectName){
                    branchidSelect.push(users.branch[k].branchID);
                    branchsSelect.push(users.branch[k]);
                  }
                }
              }
            }  
          }else{
            branchidSelect = [];
            branchsSelect = [];
            for(let k = 0; k < users.branch.length; k++){
              if(users.branch[k].projectName === this.state.ProjectSelect){
                branchidSelect.push(users.branch[k].branchID);
                branchsSelect.push(users.branch[k]);
              }
            }  
          }
        }
        break;
      }
    }
    return({branchidSelect,branchsSelect})
  }

  
  // changBranchs = (dataCallback) => {
  //   console.log("changBranchs ->",dataCallback);
  //   let branchID = []
  //   for(let i=0; i<dataCallback.length; i++) {
  //     if(dataCallback[i].value == 'All') {
  //       branchID = this.state.user.branchID
  //       // console.log("changValue branchID =>", branchID);
  //       break
  //     }
  //     branchID.push(dataCallback[i].value)
  //   }
  //   this.setState({BranchidSelect:branchID});
  //   //this.setState({BranchsSelect:this.state.BranchsSelect});
  //   console.log("BranchidSelect ->",branchID)
  // }

  saveCampaign = () => {
    this.setState({isButtonLoading:true})
    let dataResponse = this.state.dataResponse
    //console.log("OrganizationSelect =>",this.state.OrganizationSelect)
    //console.log("ProjectSelect =>",this.state.ProjectSelect)
    //+-console.log("BranchidSelect =>",this.state.BranchidSelect)
    if(this.state.BranchidSelect.length){
      let tempBranchidSelect = this.changBranchsSect(this.state.BranchidSelect)

      dataResponse["organization"] = this.state.OrganizationSelect
      dataResponse["project"] = this.state.ProjectSelect
      dataResponse["branchID"] = tempBranchidSelect.branchidSelect
      let dataSave = JSON.parse(JSON.stringify(dataResponse))
      //+-console.log('saveCampaign =>',dataSave); 
      this.updateDb(dataSave,(fn) =>{ 
        if(fn) console.log('saveCampaign error ->',fn) 
        //+-console.log("saveCampaign complete ->",this.state.dataResponse)
        this.setState({isButtonLoading:false})
      })
    }else if(dataResponse.branchID.length){
      let dataSave = JSON.parse(JSON.stringify(dataResponse))
      //+-console.log('saveCampaign =>',dataSave); 
      this.updateDb(dataSave,(fn) =>{ 
        if(fn) console.log('saveCampaign error ->',fn) 
        //+-console.log("saveCampaign complete ->",this.state.dataResponse)
        this.setState({isButtonLoading:false})
      })  
    }else{
      this.setState({isButtonLoading:false})
      this.alert({action:'warning',title:"Did not choose a branchID " })
    }
  }

  savePrescription = () =>{
    this.setState({isButtonLoading:true})
    let dataResponse = this.state.dataResponse
    let users = this.state.user
    let id = dataResponse.id
    //console.log("OrganizationSelect =>",this.state.OrganizationSelect)
    //console.log("ProjectSelect =>",this.state.ProjectSelect)
    console.log("dataResponse =>",dataResponse)

    dataResponse["organization"] = this.state.OrganizationSelect
    dataResponse["project"] = this.state.ProjectSelect
    let dataSave = JSON.parse(JSON.stringify(dataResponse))
    console.log('saveCampaign =>',dataSave); 
    console.log('this.state.flgMode =>',this.state.flgMode);
    if(this.state.flgMode === "new" && dataResponse._id === undefined ){
      let THIS = this
      let organization = users.OrganizationSelect
      let collection = 'order'
      let query = '/?orderId='+dataResponse.orderId
      axios({
        method: 'get',
        url: window.url + 'api/v1/collection/' + organization + '/' + collection + query,
        data: 'values',
        headers:{
          "Authorization": users.token
        }
      })
      .then(function (response) {
        console.log("response =>",response.data)
        let query = '/?_sortNatural=-1&_limit=1'
        if(response.data.value.length){
          axios({
            method: 'get',
            url: window.url + 'api/v1/collection/' + organization + '/' + collection+query,
            data: 'values',
            headers:{
              "Authorization": users.token
            }
          })
          .then(function (response) {
            console.log("response =>",response.data)
            let orderId = "RX"
            //console.log("this.state.dataResponse =>",this.state.dataResponse)
            if(response.data.value.length){
              console.log("response.data.value[0]",response.data.value[0].orderId)
              let str = response.data.value[0].orderId.replace(/\D/g, "")
              let countUp = (str * 1) + 1
              countUp = ('0000000' + countUp).slice(-7)
              orderId = orderId + countUp
            }else{
              orderId = orderId + '0000001'
            }
            console.log("orderId =>",orderId)
            dataSave["orderId"] = orderId
            THIS.insertDb(dataSave,(fn) => { 
              if(fn) console.log('savePrescription error ->',fn) 
              //+-console.log("saveCampaign complete ->",this.state.dataResponse)
              if( (JSON.stringify(users) !== '{}') && (id !== undefined) ){
                let organization = users.OrganizationSelect
                let collection = 'student'
                let values = {
                  history: dataResponse.history
                }
                axios({
                  method: 'put',
                  url: window.url + 'api/v1/collection/' + organization + '/' + collection + '/' + id,
                  // url: 'http://13.251.52.58:3001/api/v1/users/' + this.state.user.organization,
                  data: values,
                  headers:{
                    "Authorization": users.token
                  }
                })
                .then(function (response) {
                  console.log("Update history =>", response.data.value); // {n: 1, nModified: 1, ok: 1}
                  THIS.setState({
                    isButtonLoading:false,
                    isButtonBackEnable: true,
                    isButtonEnable: false
                  })
                })
                .catch(function (error) {
                  console.log(error);
                  THIS.setState({
                    modalWorning: "updateDb error!",
                    isButtonLoading:false,
                    isButtonBackEnable: true,
                    isButtonEnable: false
                  })
                  fn(error)
                });
              }else{
                THIS.setState({
                  isButtonLoading:false,
                  isButtonBackEnable: true,
                  isButtonEnable: false
                })
              }
            })
          })
          .catch(function (error) {
            console.log(error);
            THIS.setState({
              isButtonLoading:false,
              isButtonBackEnable: true,
              isButtonEnable: false
            })
            //+-console.log("response -->",response.data);
          })
        }else{
          THIS.insertDb(dataSave,(fn) =>{ 
            if(fn) console.log('savePrescription error ->',fn) 
            //+-console.log("saveCampaign complete ->",this.state.dataResponse)
            if( (JSON.stringify(users) !== '{}') && (id !== undefined) ){
              let organization = users.OrganizationSelect
              let collection = 'student'
              let values = {
                history: dataResponse.history
              }
              axios({
                method: 'put',
                url: window.url + 'api/v1/collection/' + organization + '/' + collection + '/' + id,
                // url: 'http://13.251.52.58:3001/api/v1/users/' + this.state.user.organization,
                data: values,
                headers:{
                  "Authorization": users.token
                }
              })
              .then(function (response) {
                console.log("Update history =>", response.data.value); // {n: 1, nModified: 1, ok: 1}
                THIS.setState({
                  isButtonLoading:false,
                  isButtonBackEnable: true,
                  isButtonEnable: false
                })
              })
              .catch(function (error) {
                console.log(error);
                THIS.setState({
                  modalWorning: "updateDb error!",
                  isButtonLoading:false,
                  isButtonBackEnable: true,
                  isButtonEnable: false
                })
                fn(error)
              });
            }else{
              THIS.setState({
                isButtonLoading:false,
                isButtonBackEnable: true,
                isButtonEnable: false
              })
            }
          })
        }
      })
      .catch(function (error) {
        console.log(error);
        THIS.setState({
          isButtonLoading:false,
          isButtonBackEnable: true,
          isButtonEnable: false
        })
        //+-console.log("response -->",response.data);
      })
    }else{
      this.updateDb(dataSave,(fn) =>{ 
        if(fn) console.log('savePrescription error ->',fn) 
        //+-console.log("saveCampaign complete ->",this.state.dataResponse)

        if( (JSON.stringify(users) !== '{}') && (id !== undefined) ){
          let THIS = this
          let organization = users.OrganizationSelect
          let collection = 'student'
          let values = {
            history: dataResponse.history
          }
          axios({
            method: 'put',
            url: window.url + 'api/v1/collection/' + organization + '/' + collection + '/' + id,
            // url: 'http://13.251.52.58:3001/api/v1/users/' + this.state.user.organization,
            data: values,
            headers:{
              "Authorization": users.token
            }
          })
          .then(function (response) {
            console.log("Update history =>", response.data.value); // {n: 1, nModified: 1, ok: 1}
            THIS.setState({
              isButtonLoading:false,
              isButtonBackEnable: true,
              isButtonEnable: false
            })
          })
          .catch(function (error) {
            console.log(error);
            THIS.setState({
              modalWorning: "updateDb error!",
              isButtonLoading:false,
              isButtonBackEnable: true,
              isButtonEnable: false
            })
            fn(error)
          });
        }else{
          this.setState({
            isButtonLoading:false,
            isButtonBackEnable: true,
            isButtonEnable: false
          })
        }
      })
    }
  }
 
  branchIDOptions = () => {
    if(!this.state.user.branchID) return this.goto401()
    let branchIDOptions = [{ value: "All", label: "All" }]
    let branchIDAll = this.state.user.branchID    
    for(let i=0; i<branchIDAll.length; i++) {
      let option = { value: branchIDAll[i], label: branchIDAll[i] }
      branchIDOptions.push(option)
    }
    // console.log('branchIDOptions ...')
    return branchIDOptions
  }
  
  // branchIDOptionsDefault = () => {
  //   // console.log('branchIDOptionsDefault this.state.form =>', this.state.form)
  //   let branchIDAll = this.state.dataResponse.branchID
  //   if(!branchIDAll) return null
  //   let branchIDOptionsDefault = []
  //   for(let i=0; i<branchIDAll.length; i++) {
  //     let option = { value: branchIDAll[i], label: branchIDAll[i] }
  //     branchIDOptionsDefault.push(option)
  //   }
  //   // this.state.form.branchID = this.props.form.branchID
  //   return branchIDOptionsDefault
  // }

  selectFileClick = (dataIn) => {
    //+-console.log("selectFileClick =>",dataIn)
    let modalForm = this.state.modalForm 
    let modalFormTitle = this.state.modalFormTitle
    let THIS = this
    axios({
      method: 'get',
      url: window.url + 'api/v1/collection/' + this.state.defaultFileUpload.organization + '/upload?_sortNatural=-1&type=products',
      data: 'values',
      headers:{
        "Authorization": this.state.user.token
      }
    })
    .then(function (response) {
      //+-console.log("response -->",response.data);

      //sort product by name 
      let p = response.data.value
      p.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));

      if(response.data.value.length){
        modalFormTitle = "Select products"
        modalForm = []
        for(let i = 0; i < response.data.value.length; i++){
          let item = response.data.value[i] 
          let tempObj =  JSON.parse(JSON.stringify(item))
            tempObj.type = 'imageSelect'
            //tempObj.path = tempObj.path
            tempObj.value = tempObj.image
            tempObj.col = 1
            tempObj.cardSelect = false
            if(dataIn.value === tempObj.fileName){
              tempObj.cardSelect = true
            }
            modalForm.push(tempObj)
        }
        //+-console.log("modalForm =>",modalForm)
        THIS.setState({
          modalForm: modalForm,
          modalFormTitle: modalFormTitle
        },() =>{
          THIS.setState({
            modalAddbranchShow:true
          })  
        })
      }
    })
    .catch(function (error) {
      THIS.setState({
        alertShow: true,
        alertText: error.message
      })
      console.log('axios error =>',error.message);
    });
  }

  imageOnclicks = (dataIn) => {
    //+-console.log('dataIn.cardSelect =>',dataIn.cardSelect)
    //+-console.log('tempModalForm =>',this.state.modalFormSave)
    let modalForm = this.state.modalForm 
    if((dataIn.cardSelect === true) && (this.state.modalFormTitle === "Select products")){
      this.modalClose()
      let dataEdit = this.state.modalFormSave
      //let dataEdit = this.state.modalForm
      for(let k=0; k < dataEdit.length; k++) {
        if(dataEdit[k].key === 'image'){
          dataEdit[k].value= dataIn.fileName
          dataEdit[k].path= dataIn.path 
        }
        if(dataEdit[k].key === 'name'){
          let name  = dataIn.fileName.split('.');
          dataEdit[k].value= name[0]
        }
      }

      //+-console.log('dataEdit =>',dataEdit)
      this.setState({ 
        modalFormSave: dataEdit,
        modalForm: dataEdit, 
        modalFormTitle: this.state.modalFormTitleSave,
        modalAddbranchShow: true
      })
    }else if(this.state.modalFormTitle === "Add Products"){
      for(let i=0; i<modalForm.length; i++) {
        if(modalForm[i]._id === dataIn._id) {
          modalForm[i].cardSelect = dataIn.cardSelect
          break
        }
      }
      this.setState({modalForm: modalForm})
    }
  }

  saveLog = (dataIn) => {

    fetch('https://api.ipify.org/?format=json').then(response => {
      return response.json();
    }).then((res) => {
      console.log('res ->', res)
      let user = this.state.user
      let data = dataIn
      data.ip = res.ip
      let organization = this.state.user.OrganizationSelect
      console.log("organization =>", organization)
      // return
      let THIS = this
      axios({
        method: 'post',
        url: window.url + 'api/v1/collection/' + organization + '/log',
        data: data,
        headers:{
          "Authorization": user.token
        }
      })
      .then(function (response1) {
        //console.log('response1 ->',organization,'=',response1)

      })
      .catch(function (error) {
        console.log(error);
        // alert("error: " + JSON.stringify(error) );
        THIS.setState({ alertText: error.message, alertShow: true})
        THIS.setState({loadingOverlay: false})
      });
    })
  }

  onSearch = () => {
    console.log('onSearch =>');
    let search = this.state.dataResponse.studentId;    
    let THIS = this
    let values = {
      "query" : { 
        "studentId":search
      },
      "sortNatural": "-1",
      "countSkip":"0",
      "countLimit": "1",
      "projection":{}
    }

    axios({
      method: 'post',
      url: window.url + 'api/v1/collection/' + this.state.user.OrganizationSelect + '/student/query',
      data: values,
      headers:{
        "Authorization": this.state.user.token
      }
    })
    .then(function (response) {
      console.log("response =>",response)
      if(response.data.value.result.length){
        let dataResponse = THIS.state.dataResponse
        dataResponse.tagsId = response.data.value.result[0].tagsId
        dataResponse.name = response.data.value.result[0].name
        dataResponse.lastName = response.data.value.result[0].lastName
        dataResponse.studentId = response.data.value.result[0].studentId
        dataResponse.history = response.data.value.result[0].history
        dataResponse.id = response.data.value.result[0]._id
        THIS.setState({
          searchButton: false,
          isButtonAdd : true,
          isButtonEnable: true,
          dataResponse: dataResponse
        }, () => {
          // let products = res
          // let paginationActive = this.state.paginationActive
          // this.paginationItemCal(products, paginationActive)
        })
      }else {
        let dataResponse = THIS.state.dataResponse
        dataResponse.tagsId = "";
        dataResponse.name = "";
        dataResponse.lastName = "";
        dataResponse.history = "";
        THIS.setState({
          searchButton: true,
          isButtonAdd : false,
          isButtonEnable: false,
          dataResponse: dataResponse
        }, () => {
          // let products = res
          // let paginationActive = this.state.paginationActive
          // this.paginationItemCal(products, paginationActive)
        })
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  onSearchClear = () => {
    //console.log('onSearchClear =>');
    let dataResponse = this.state.dataResponse
    dataResponse.studentId = ""
    this.setState({
      dataResponse: dataResponse, 
      searchButton: true
    }, () => {
      // let products = this.state.usersRes
      // let paginationActive = this.state.paginationActive
      // this.paginationItemCal(products, paginationActive)
    })          
  }

  handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.onSearch()
    }
  }

  gotoCampaign = (item) => {
    // console.log('detailBranch item =>', item);
    let allFilter =  storeGlobal.getAllFilter();
    allFilter['buttonBack'] = true;
    storeGlobal.getAllFilter(allFilter);
    let location = {
      pathname: '/prescriptionman',
      state: {}
    }
    
    this.setState({
      location: location
    },() => { 
      this.setState({
        redirect: true
      })
    })
  }

  handleCopy = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        this.setState({
          dataCopied : text
        })
        // alert('ข้อความถูกคัดลอกแล้ว!');
      })
      .catch((err) => {
        this.setState({
          dataCopied : ""
        })
        console.error('ไม่สามารถคัดลอกข้อความได้:', err);
      });
  };

  goto401 = () => {
    // console.log('detailBranch item =>', item);
    this.props.history.push('/prescriptionman');
    this.props.history.push('/401');
  }

  

  //=========================================================================== render()
  render() {
    // let modalClose = () => this.setState({ modalAddUserShow: false });
    //console.log('this.state ->', this.state);
    const paginationStyle = {
      display: 'table',
      margin: '0 auto',
    };

    if(this.state.redirect) return ( <Redirect to={this.state.location} /> )

    return (     
      <SiteWrapper user={this.state.user} props = {this.props}>
        <>    
          <Page.Content title="prescription">
            {/* <SelectBranchs getbranchs = {this.getbranchs}/> */}

             {/* <Grid.Row cards={true}> */}

              {/* <Grid.Col lg={12}> */}
                <Card>
                  <Card.Header>
                    <Card.Title>Manage prescription</Card.Title>
                    <Card.Options>
                    <Button.List>
                      <Button 
                          color="secondary"      
                          disabled={(!this.state.isButtonBackEnable)}                  
                          onClick={ () => this.state.isButtonBackEnable && this.gotoCampaign(this) }
                        > 
                        <Icon prefix="fe" name="arrow-left" className="mr-2" />
                        Back </Button>
                      { this.state.user.level==='write' &&              
                        this.state.isButtonLoading ? <Button 
                            color="green" 
                            loading
                            >Save
                          </Button> : <Button 
                            disabled={(!this.state.isButtonEnable)}
                            color="green"                  
                            onClick={ () => this.state.isButtonEnable && this.savePrescription() }
                          > Save
                          </Button>  
                      }
                      </Button.List>              
                    </Card.Options>
                  </Card.Header>
                  <Card.Body>
                    <Grid.Row>
                      <Grid.Col lg={6}>
                        <Form.Group label="Order Id">
                          <Form.InputGroup>
                            <Form.Input
                              name="orderId"
                              placeholder=""
                              value={this.state.dataResponse.orderId || ''}
                              onChange={this.handleInputChangeMain}
                              disabled={true}
                            /> 
                            <Form.InputGroupAppend>
                              {this.state.dataCopied === ""?
                                <Button
                                  color="primary"
                                  onClick={() =>{this.handleCopy(this.state.dataResponse.orderId)}}
                                  >Coppy
                                </Button>
                                :
                                <Button
                                  color="primary"
                                  onClick={() =>{this.handleCopy(this.state.dataResponse.orderId)}}
                                >Copied
                                </Button>
                              }
                            </Form.InputGroupAppend>
                          </Form.InputGroup>
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col lg={6}>
                        {/* <Form.Group label="Student Id">
                          <Form.Input
                            name="studenId"
                            placeholder=""
                            value={this.state.dataResponse.studenId || ''}
                            onChange={this.handleInputChangeMain}
                            // disabled={true}
                          />
                        </Form.Group> */}
                        <Form.Group label="Student/employee Id">
                          <Form.InputGroup>
                            <Form.Input 
                                placeholder="Search for..." 
                                name="studentId"
                                onChange={this.handleInputChangeSearch} 
                                value={this.state.dataResponse.studentId || ''} 
                                onKeyPress={this.handleKeyPress}
                              />
                              <Form.InputGroupAppend>
                                { this.state.searchButton? 
                                  <Button
                                    color="primary"
                                    icon="search"
                                    onClick={this.onSearch}
                                  /> 
                                  : <Button
                                    color="default"
                                    icon="x"
                                    onClick={this.onSearchClear}
                                  /> 
                                }
                            </Form.InputGroupAppend>
                          </Form.InputGroup>
                        </Form.Group>
                      </Grid.Col>
                    </Grid.Row>

                    <Grid.Row>
                      <Grid.Col lg={6}>
                        <Form.Group label="Name">
                          <Form.Input
                            name="name"
                            placeholder=""
                            value={this.state.dataResponse.name || ''}
                            onChange={this.handleInputChangeMain}
                            // disabled={true}
                          />
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col lg={6}>
                        <Form.Group label="Last name">
                          <Form.Input
                            name="lastName"
                            placeholder=""
                            value={this.state.dataResponse.lastName || ''}
                            onChange={this.handleInputChangeMain}
                            // disabled={true}
                          />
                        </Form.Group>
                      </Grid.Col>
                    </Grid.Row>

                    <Grid.Row>
                      <Grid.Col>
                        <Form.Group label="Active Date">
                          <DatePicker
                          selected={this.state.dataResponse.activeDate}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dateFormat="dd/MM/yyyy"
                          onChange={this.handleChangeActiveDate.bind(this)}
                          // onChange = {(even) =>{
                          //       {input.value = new Date(even)}
                          //       if(this.handleChangeDate){
                          //         this.handleChangeDate(input)  
                          //       }
                          //     } 
                          //   }
                          className="form-control"
                          />
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col>
                        <Form.Group label="Expire Date">
                          <DatePicker
                          selected={this.state.dataResponse.expireDate}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dateFormat="dd/MM/yyyy"
                          onChange={this.handleChangeExpireDate.bind(this)}
                          // onChange = {(even) =>{
                          //       {input.value = new Date(even)}
                          //       if(this.handleChangeDate){
                          //         this.handleChangeDate(input)  
                          //       }
                          //     } 
                          //   }
                          className="form-control"
                          />
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col lg={6}>
                        <Form.Group label="Status">
                          <Form.Input
                            name="example-text-input"
                            placeholder=""
                            value={this.state.dataResponse.status || ''}
                            disabled={true}
                          />
                        </Form.Group>
                      </Grid.Col>
                    </Grid.Row>

                    <Grid.Row>
                      <Grid.Col lg={12}>
                        <Form.Group label="history of drug allergy">
                          <Form.Textarea
                            name="history"
                            value={this.state.dataResponse.history || ''}
                            placeholder="history of drug allergy..."
                            rows={6}
                            onChange={this.handleInputChangeMain}
                          />
                        </Form.Group>
                      </Grid.Col>
                    </Grid.Row> 

                    <Grid.Row>
                      <Grid.Col lg={12}>
                        <Card>
                          <Card.Header>
                            <Card.Title>Medicine List</Card.Title>
                            { this.state.user.level==='write' && <Card.Options>
                                <Button 
                                  disabled={(!this.state.isButtonAdd)}
                                  color="green"                  
                                  onClick={ () => this.add() }
                                > Add Medicine
                                </Button>  
                            </Card.Options>    
                            }          
                          </Card.Header>

                          {/* <Card.Body> */}
                            {/* <Table className="card-table table-vcenter"> */}
                            <Table
                              responsive
                              className="card-table table-vcenter text-nowrap table-hover "
                            >
                              <Table.Header>
                                <Table.Row>
                                {this.state.header.map( (item, index) =>
                                  this.state.user.level==='write' ? 
                                  <Table.ColHeader key={index}  className = {item.class} style={{ textTransform: 'none'}}>{item.name}</Table.ColHeader>
                                : item.name.toUpperCase()!=='ACTION' && <Table.ColHeader key={index} className = {item.class} style={{ textTransform: 'none'}}>{item.name}</Table.ColHeader>
                              )}
                                </Table.Row>
                              </Table.Header>
                              <Table.Body>
                              {this.state.dataShow.length ?
                                this.state.dataShow.map( (item, index) =>
                                  <Table.Row key={ index }>
                                    <Table.Col className="text-center align-middle table-sticky-left">
                                      { index+1}
                                    </Table.Col>
                                    <Table.Col className="text-center align-middle">
                                      <img 
                                        alt=""
                                        src={item.path}
                                        //src={"/products/"+item.image}
                                        // "/products/คาราบาวแดง.png"
                                        className="h-8"
                                      />
                                    </Table.Col>
                                    <Table.Col className="text-center align-middle">
                                      {item.name}
                                    </Table.Col>
                                    <Table.Col className="text-center align-middle">
                                      { item.price }
                                    </Table.Col>
                                    <Table.Col className="text-center align-middle">
                                      { item.amount }
                                    </Table.Col>
                                    <Table.Col className="text-center align-middle">
                                      { item.amountPaid }
                                    </Table.Col>
                                    <Table.Col className="text-center align-middle">
                                      { item.status }
                                    </Table.Col>
                                    { this.state.user.level==='write' && <Table.Col className="text-center align-middle table-sticky-right">
                                      <Button.List>
                                        <Button size="sm" color="info"
                                          onClick={ () => this.edit(item) }
                                          >
                                          Edit
                                        </Button>
                                        <Button size="sm" color="danger" 
                                          onClick={ ()=> { this.alert({action:'delete', id: item._id, name: item.name, i: index }) } } >
                                          Delete
                                        </Button>  
                                      </Button.List>
                                    </Table.Col>
                                    }
                                  </Table.Row>
                                ) : <Table.Row > 
                                  <Table.Col colSpan="12"	alignContent="center">
                                    <Text className="my-4" size="" center="true" color="primary" >ไม่พบข้อมูล : Data not found.</Text>
                                  </Table.Col>
                                </Table.Row>
                              }

                              <Table.Row > 
                                <Table.Col colSpan="12"	alignContent="center">
                                  <div style={paginationStyle}>
                                    <Pagination>
                                      { this.state.paginationItem.map( number =>
                                        <Pagination.Item key={number} active={number === this.state.paginationActive} onClick={ this.paginationActive.bind(this, number) }>
                                          {number} 
                                        </Pagination.Item>
                                      )}
                                    </Pagination>
                                  </div>      
                                </Table.Col>
                              </Table.Row>
                              </Table.Body>
                            </Table>
                          {/* </Card.Body> */}
                          
                        </Card>
                      </Grid.Col>
                    </Grid.Row>
                
                    </Card.Body>
                  </Card>
                {/* </Grid.Col> */}
              {/* </Grid.Row> */}
            </Page.Content>
          
          <ModalFormLarge
            show={this.state.modalAddbranchShow}
            onHide={this.modalClose}
            onSave={this.getForm}
            onInputChange={this.handleInputChange}
            title={this.state.modalFormTitle}
            worning={this.state.modalWorning}
            form={this.state.modalForm}
            imageOnclicks = {this.imageOnclicks}
            selectFileClick = {this.selectFileClick}
            defaultFileUpload = {this.state.defaultFileUpload}
            isButtonSelectFileEnable={this.state.isButtonSelectFileEnable}
            isFilePondEnable={this.state.isFilePondEnable}
          />

          {this.state.alert}

          <SweetAlert
            warning
            show={this.state.alertShow}
            title="Oops!"
            onConfirm={() => this.setState({ alertShow: false })}
          >
            {this.state.alertText}
          </SweetAlert>

        </>
      </SiteWrapper>   
    )
  }
}

export default DetailPrescription;