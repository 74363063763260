import React, { Component } from "react";
import { Page, Card, Text, Table, Button, Badge, Form } from "tabler-react";
import SiteWrapper from "../components/SiteWrapper.react";
import axios from "axios";
// import ModalFormSmall from "../components/ModalFormSmall";
import ModalFormLarge from "../components/ModalFormLarge";
import SweetAlert from "react-bootstrap-sweetalert";
import Pagination from "react-bootstrap/Pagination";
import storeGlobal from "../components/globalStore";
//import SelectBranchs from "../components/SelectBranchs";
import SelectBranchs from "../components/SelectBranchsStateLess";
import { Redirect } from "react-router-dom";
import Moment from "react-moment";
import Map from "../components/Map";

class BranchMan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: storeGlobal.getusers(),
      branchs: [],
      usersRes: [],
      branchsPage: [],
      userEdit: null,

      modalDetailShow: false,
      modalWorning: "",
      modalForm: [
        {
          key: "branchID",
          name: "ID",
          placeholder: "",
          value: "",
          disabled: false,
          type: "input"
        },
        {
          key: "branch",
          name: "Name",
          placeholder: "",
          value: "",
          disabled: false,
          type: "input"
        },
        {
          key: "location",
          name: "Location",
          placeholder: "",
          value: "",
          disabled: false,
          type: "input"
        },
        {
          key: "project",
          name: "Project",
          placeholder: "",
          value: "",
          disabled: false,
          type: "select",
          items: ["p1", "p2", "p3"]
        }
      ],

      alert: null,
      paginationItem: [],
      paginationActive: 1,
      OrganizationSelect: "",
      branchsPageLimit: 10,

      redirect: false,
      location: {},
      alertShow: false,
      alertText: "",

      searchText: "",
      searchButton: true,

      places: [
        {
          name: "default_name",
          title: "default_title",
          lat: 13.8056305,
          lng: 100.8402304,
          _id: "default_id",
          status: "offline_online",
          organization: "default_organization",
          branchID: "default_branchID",
        },
       
      ],
      typeSelectFilter:"noDate",
      tmpDataBranchSave:{},

      isButtonLoading: true

    };
  }

  componentWillUnmount(){    
    if(this.rtTimeout != null){
      clearTimeout(this.rtTimeout)
    }      
    console.log("componentWillUnmount......", this.rtTimeout);
  }

  componentDidMount() {
    //+-console.log("componentDidMount......");
    if(!(Object.entries(this.state.user).length === 0)){
      // console.log('this.state.user =>',this.state.user)
      //this.setState({branchs:dataCallback.BranchsSelect});
      //this.setState({branchID:this.state.user.branchID});
      //this.setState({ user:this.state.user});
      let THIS = this
      axios({
        method: 'get',
        url: window.url + 'api/v1/collection/' + this.state.user.OrganizationSelect + '/device',
        data: 'values',
        headers:{
          "Authorization": this.state.user.token
        }
      })
      .then(function (response) {
        //console.log("devices =>",response.data.value)
        let devices = {}
        if(response.data.value){
            devices = response.data.value
        }

        let organization = []
        for(var i = 0; i < THIS.state.user.organization.length; i++){
          organization.push({label:THIS.state.user.organization[i],value:THIS.state.user.organization[i]})  
        }
        // this.setState({ optionOrganizations:organization});

        let project = []
        if(THIS.state.user.OrganizationSelect === ''){
          for(let i = 0; i < THIS.state.user.project.length; i++){
            if(organization[0].value === THIS.state.user.project[i].organization){
              project.push({label:THIS.state.user.project[i].projectName,value:THIS.state.user.project[i].projectName})    
            }
          }
        }else{
          for(let i = 0; i < THIS.state.user.project.length; i++){
            if(THIS.state.user.OrganizationSelect === THIS.state.user.project[i].organization){
              project.push({label:THIS.state.user.project[i].projectName,value:THIS.state.user.project[i].projectName})    
            }
          }
        }

        let branchID = [{label:"All",value:"All"}]
        if(THIS.state.user.ProjectSelec == ''){
          for(var j = 0; j < THIS.state.user.branch.length; j++){
            if(THIS.state.user.branch[j].projectName == project[0].value){
              branchID.push({label:THIS.state.user.branch[j].branchID,value:THIS.state.user.branch[j].branchID});
            }
          }
        }else{
          for(var j = 0; j < THIS.state.user.branch.length; j++){
            if(THIS.state.user.branch[j].projectName == THIS.state.user.ProjectSelect){
              branchID.push({label:THIS.state.user.branch[j].branchID,value:THIS.state.user.branch[j].branchID});
            }
          }
        }

        let BranchsSelect = []
        let BranchidSelect = []
        for(let i = 0; i < THIS.state.user.branch.length; i++){
          if(THIS.state.user.ProjectSelect === THIS.state.user.branch[i].projectName){
            for(let j = 0; j < THIS.state.user.BranchidSelect.length; j++){
              if(THIS.state.user.BranchidSelect[j] === THIS.state.user.branch[i].branchID){
                BranchsSelect.push(THIS.state.user.branch[i])
                BranchidSelect.push(THIS.state.user.branch[i].branchID)
              }
            }
          }
        }

        THIS.setState({
          title: 'company',
          optionOrganizations:organization,
          optionProjects:project,
          optionBranchs:branchID,
          OrganizationSelect: THIS.state.user.OrganizationSelect,
          ProjectSelect: THIS.state.user.ProjectSelect,
          devices: devices,
        })

        // let THIS = this
        // let token = user.token
        // let values = {
        //   organization: THIS.state.user.OrganizationSelect,
        //   branchID: THIS.state.user.branchID,
        //   startDate: (new Date()).setHours(0,0,0,0),
        //   endDate: new Date()
        // }

        let dataCallback = {
          BranchsSelect: BranchsSelect, 
          ProjectSelect: THIS.state.user.ProjectSelect,
          BranchidSelect: BranchidSelect,
          OrganizationSelect: THIS.state.user.OrganizationSelect,
          // startDate: values.startDate,
          // endDate: values.endDate,
        }
        //console.log('dataCallback =>',dataCallback)
        THIS.getbranchs(dataCallback)

      })
      .catch(function (error) {
        THIS.setState({
          alertShow: true,
          alertText: error.message
        })
        console.log('axios error =>',error.message);
      });
    }
  }

  handleClick(e) {
    console.log("this ->", e);
  }

  hideAlert() {
    this.setState(state => ({
      alert: null
    }));
  }

  alert(e) {
    // console.log(e)
    switch (e.action) {
      case "delete":
        this.setState(state => ({
          alert: (
            <SweetAlert
              warning
              showCancel
              confirmBtnText="Yes, delete it!"
              confirmBtnBsStyle="danger"
              cancelBtnBsStyle="default"
              title="Are you sure?"
              onConfirm={() => {
                this.deleteBranch(e);
              }} //{this.deleteFile}
              onCancel={this.hideAlert.bind(this)}
            >
              Delete "{e.branch}"
            </SweetAlert>
          )
        }));
        break;
        case "duplicate":
          this.setState(state => ({
            alert: (
              <SweetAlert
                info
                showCancel
                confirmBtnText="Yes, duplicate it!"
                confirmBtnBsStyle="primary"
                cancelBtnBsStyle="default"
                title="Are you sure?"
                onConfirm={() => {
                  this.duplicateBranch(e);
                }} //{this.deleteFile}
                onCancel={this.hideAlert.bind(this)}
              >
                Duplicate "{e.branch}"
              </SweetAlert>
            )
          }));
        break;
        default:
        break
    }
  }

  deleteBranch(branch) {
    console.log("deleteBranch =>", branch);
    let THIS = this;
    axios({
      method: "delete",
      url:
        window.url + "api/v1/collection/" + this.state.user.OrganizationSelect + "/" + "branch" + "/" + branch.id,
      data: { user: this.state.user },
      headers: {
        Authorization: this.state.user.token
      }
    })   
    .then(function(response) {
      console.log('deleteUser response.data.value =>', response.data.value);
      if(THIS.state.devices){
        let devices = THIS.state.devices
        console.log('devices =>',devices)
        let deviceDelete = devices.filter(person => person.branchID === branch.branch);
        console.log('deviceDelete =>',deviceDelete)
        if(deviceDelete.length){
          console.log('update')
          deviceDelete[0].branchID = null
          let id = deviceDelete[0]._id
          delete deviceDelete[0]._id
          axios({
            method: 'put',
            url: window.url + 'api/v1/collection/' + THIS.state.user.OrganizationSelect + '/device/'+ id,
            // url: 'http://13.251.52.58:3001/api/v1/users/' + this.state.user.organization,
            data: deviceDelete[0],
            headers:{
              "Authorization": THIS.state.user.token
            }
          })
          .then(function (response1) {
            console.log("update device response =>", response1.data.value); // {n: 1, nModified: 1, ok: 1}
          })
          .catch(function (error) {
            console.log(error);
            THIS.hideAlert()
          }); 
        }
      }

      let dataSave = {
        "type" : 'warning', //information //warning // error
        "date" : new Date(),
        "surce" : 'branchMan',
        "category" : 'webApp',
        "even" : 'Delete branch',
        "user" :  THIS.state.user.username,
        "ip" : "",
        "discription" : 'Delete branch =>'+ branch.branch
      }
      THIS.saveLog(dataSave)

      let branchs = THIS.state.branchs;
      console.log('branchs =>',branchs)
      branchs = branchs.filter(person => person._id !== branch.id);

      let user = THIS.state.user
      user.branch = []
      user.branchID = []
      branchs.forEach(doc => {
        let obj = {
          "branchID" : doc.branchID,
          "branchName" : doc.branch,
          "projectName" : doc.project,
          "projectID": doc.projectID
        }
        user.branch.push(obj)
        user.branchID.push(doc.branchID)
      });

      THIS.setState({ 
        branchs: branchs,
        user : user
        },() => {
          THIS.paginationItemCal();
          // console.log('THIS.state.paginationItem.length =>', THIS.state.paginationItem.length)
          if (THIS.state.paginationItem.length === 1) THIS.paginationActive(1);
            
        }
      );
      THIS.hideAlert();

      let branchsPage = THIS.state.branchsPage;
      branchsPage = branchsPage.filter(person => person._id !== branch.id);
      // console.log('branchsPage =>', branchsPage);
      
      THIS.setState({ 
        branchsPage: branchsPage 
      });
      if (!branchsPage.length) THIS.paginationActive(1);
    })
    .catch(function(error) {
      console.log(error);
      THIS.hideAlert();
    });
  }

  duplicateBranch(branch) {
    console.log("deleteBranch =>", branch);
    let THIS = this;
    axios({
      method: 'get',
      url: window.url + 'api/v1/collection/' + this.state.user.OrganizationSelect + '/branch/' + branch.id,
      data: 'values',
      headers:{
        "Authorization": this.state.user.token
      }
    })
    .then(function (response) {
      console.log("response -->",response.data);
      if(response.data.value){
        let tmpDataBranchSave = response.data.value
            delete tmpDataBranchSave._id
            delete tmpDataBranchSave.branchID
            delete tmpDataBranchSave.connect
            delete tmpDataBranchSave.disconnect
            delete tmpDataBranchSave.updateBy
            delete tmpDataBranchSave.updateDate

            tmpDataBranchSave.status = 'Duplicate'

        let typeItems = window.configVending.typeItems
        let vendingIdplaceholder = "Vending Id length "+window.configVending.IdMinLengh+ ' - ' + window.configVending.IdMaxLengh + ' must not contain spaces!'
        let modalForm = [
          { key: "branchID", name: "ID", placeholder: vendingIdplaceholder, value: "", disabled: false, type: 'input' }, 
          { key: "branch", name: "Name", placeholder:"", value: tmpDataBranchSave.branch, disabled: false, type: 'input' }, 
          //{ key: "type", name: "Type", placeholder:"", value: tmpDataBranchSave.branch.type, disabled: false, type: 'select', items: typeItems}, 
          { key: "location", name: "Location", placeholder:"", value: tmpDataBranchSave.location, disabled: false, type: 'input' }, 
          { key: "lat", name: "Latitude", placeholder:"Must be numbers only", value: tmpDataBranchSave.lat, disabled: false, type: 'input' }, 
          { key: "lng", name: "Longitude", placeholder:"Must be numbers only", value: tmpDataBranchSave.lng, disabled: false, type: 'input' }, 
          // { key: "project", name: "Project", placeholder:"", value: projectItems[0], disabled: false, type: 'select', items: projectItems}, 
        ]
        // console.log('modalForm =>', modalForm);
        THIS.hideAlert();
        THIS.setState({
          modalForm: modalForm,
          modalAddbranchShow: true,
          tmpDataBranchSave: tmpDataBranchSave
        });
      }
    })
    .catch(function(error) {
      console.log(error);
      THIS.hideAlert();
    });
  }

  paginationActive(page) {
    this.setState(state => ({
      paginationActive: page
    }));

    this.branchsPage(page);

    // =============================================
    let branchsIDs = [];
    let start = (page - 1) * this.state.branchsPageLimit;
    let end = start + this.state.branchsPageLimit;
    if (end >= this.state.branchs.length) {
      end = this.state.branchs.length;
    }
    for (let i = start; i < end; i++) {
      branchsIDs.push(this.state.branchs[i].branchID);
    }
    this.getDataSensor(branchsIDs);

  }

  branchsPage(page) {
    let branchsPage = [];
    let start = (page - 1) * this.state.branchsPageLimit;
    let end = start + this.state.branchsPageLimit;
    if (end >= this.state.branchs.length) {
      end = this.state.branchs.length;
    }

    for (let i = start; i < end; i++) {
      // console.log('this.state.branchs[i] ->', this.state.branchs[i])
      this.state.branchs[i].index = i+1
      branchsPage.push(this.state.branchs[i]);
    }
    this.setState({ branchsPage: branchsPage });
  }

  paginationItemCal() {
    let n = 1;
    let paginationItem = [];
    for (
      let i = 0;
      i < this.state.branchs.length;
      i += this.state.branchsPageLimit
    ) {
      // console.log('n -->', n, THIS.state.paginationItem)
      paginationItem.push(n++);
    }
    //this.state.paginationItem = paginationItem;
    this.setState({ paginationItem: paginationItem });
  }

  getbranchs = dataCallback => {
    //console.log("getbranchs ->", dataCallback);
    let users = this.state.user
    if(users){
      // users.OrganizationSelect = dataCallback.OrganizationSelect
      // users.ProjectSelect = dataCallback.ProjectSelect
      // this.setState({
      //   OrganizationSelect: dataCallback.OrganizationSelect,
      //   user: users
      // });
      //storeGlobal.setusers(users)
      let THIS = this;
      axios({
        method: "get",
        url: window.url +"api/v1/collection/" + dataCallback.OrganizationSelect +"/" + "branch" + "?_show_not=products",
        // url: 'http://13.251.52.58:3001/api/v1/users/' + dataCallback.OrganizationSelect,
        data: "values",
        headers: {
          Authorization: this.state.user.token
        }
      })
      .then(function(response) {
        if(response.data.value){
          //console.log("response.data.value =>", response.data.value);

          let branchs = response.data.value; JSON.parse(JSON.stringify(response.data.value))
          let branchAll = response.data.value; JSON.parse(JSON.stringify(response.data.value))

          // return
          // let branchs = response.data.value; JSON.parse(JSON.stringify(response.data.value))
          // let branchAll = response.data.value; JSON.parse(JSON.stringify(response.data.value))
          // console.log('=====>', dataCallback.BranchidSelect);
          // filter
          branchs = branchs.filter(person => {
            // console.log('=====> 1', person.branchID);
            // return (dataCallback.BranchidSelect.indexOf(person.branchID) >= 0)
            if (person.branchID) {
              if (dataCallback.BranchidSelect.indexOf(person.branchID) >= 0) {
                // console.log('=====> 2', person.branchID);
                return true;
              }else if(person.project === undefined || person.project === null){
                return true;
              }
            }
          });

          //console.log('branchs =>',branchs)
          branchs.sort(function(a, b) {
            var nameA = a.branchID.toUpperCase(); // ignore upper and lowercase
            var nameB = b.branchID.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }

            // names must be equal
            return 0;
          });

          let places = []
          for(let i=0; i<branchs.length; i++) {
            if(!branchs[i].lat) continue
            let connect = new Date(branchs[i].connect);
            let disconnect = new Date(branchs[i].disconnect);
            let status = 'offline'
            if(connect >= disconnect) status = 'online'

            places.push(        
              {
                name: branchs[i].branch,
                title: branchs[i].location,
                lat: (branchs[i].lat * 1) || 13.8056305,
                lng: (branchs[i].lng * 1) || 100.8402304,
                _id: branchs[i]._id,
                status: status,
                organization: branchs[i].organization,
                branchID: branchs[i].branchID,
              }
            )
          }

          // THIS.setState({users: response.data.value})
          //console.log('places =>', places)
          //console.log('branchs =>',branchs)
          THIS.setState(
            {
              branchs: branchs,
              branchAll: branchAll,
              usersRes: branchs,
              places: places,
              paginationActive: 1
            },
            () => {
              THIS.paginationItemCal();
              THIS.branchsPage(1);
              //console.log('THIS.state.branchs =>',THIS.state.branchs)

              let branchsIDs = [];
              for (let i = 0; ( (i < THIS.state.branchsPageLimit ) && (i < THIS.state.branchs.length) ); i++) {
                branchsIDs.push(THIS.state.branchs[i].branchID);
              }
              THIS.getDataSensor(branchsIDs);

            }
          )
        }
      })
      .catch(function(error) {
        THIS.setState({
          alertShow: true,
          alertText: error.message
        });
        console.log("axios error =>", error.message);
      });
    }
  }

  getDataSensor = (branchsToget) => {
    // console.log('-------- branchsToget =>',branchsToget)
    const users = this.state.user
    const THIS = this
    let n = 0

    branchsToget.forEach( branchID => {
      // console.log('------ branchID =>',branchID)
      let values = {
        "query" : { 
          "branchID":branchID
        },
        "sortNatural": "-1",
        "countSkip":"0",
        "countLimit": "1",
        "projection":{"branchID":1, "date":1, "sensor":1, "value":1}
      }

      axios({
        method: 'post',
        url: window.url + 'api/v1/collection/' + users.OrganizationSelect + '/transaction/query',
        data: values,
        headers:{
          "Authorization": users.token
        }
      })
      .then(function (response) {
        // console.log('------- response.data =>', response.data)
        if(response.data.value.result.length){
          // console.log('------- response.data.value.result =>', response.data.value.result)
          let result = THIS.state.branchs.find( (branch) => branch.branchID === branchID );
          if(response.data.value.result[0].sensor) {
            result.sensor = response.data.value.result[0].sensor
            result.value = response.data.value.result[0].value
          }
        }

        let values = {
          "query" : { 
            "branchID":branchID
          },
          "sortNatural": "-1",
          "countSkip":"0",
          "countLimit": "1",
          "projection":{"branchID":1,"status":1, "unread":1, "date":1}
        }

        axios({
          method: 'post',
          url: window.url + 'api/v1/collection/' + users.OrganizationSelect + '/alert/query',
          data: values,
          headers:{
            "Authorization": users.token
          }
        })
        .then(function (response1) {
          // console.log('------ response1.data =>', response1.data)
          if(response1.data.value.result.length){
            let result = THIS.state.branchs.find( (branch) => branch.branchID === branchID );
            result.alert = response1.data.value.result[0]
          }
          n++
          // console.log("n, branchsToget.length =>", n, branchsToget.length);
          if(n >= branchsToget.length) {
            THIS.branchsPage(THIS.state.paginationActive);
            // console.log("n, branchsToget.length, THIS.state.branchs =>", n, branchsToget.length, THIS.state.branchs);
          }
          
        })
        .catch(function(error) {
          n++
          // console.log("catch n, branchsToget.length =>", n, branchsToget.length);
          if(n >= branchsToget.length) {
            THIS.branchsPage(THIS.state.paginationActive);
          }
          THIS.setState({
            alertShow: true,
            alertText: error.message
          });
          console.log("axios error1 =>", error.message);
        });
      })
      .catch(function(error) {
        THIS.setState({
          alertShow: true,
          alertText: error.message
        });
        console.log("axios error2 =>", error.message);
      });
    })
  }

  getDataSensorAsync = (branchsToget) => {
    // console.log('-------- branchsToget =>',branchsToget)
    if(JSON.stringify(this.state.users) !== '{}'){
      let users = this.state.user
      //console.log('users =>',users)

      let branchs  = this.state.branchs
      let tmpBranchs = []
      let async = require('async');
      let THIS = this
      let q = async.queue(function (branchID, callback) {

        //console.log('branchID =>',branchID)
        let values = {
          "query" : { 
            "branchID":branchID
          },
          "sortNatural": "-1",
          "countSkip":"0",
          "countLimit": "1",
          "projection":{"branchID":1, "date":1, "sensor":1, "value":1}
        }

        axios({
          method: 'post',
          url: window.url + 'api/v1/collection/' + users.OrganizationSelect + '/transaction/query',
          data: values,
          headers:{
            "Authorization": users.token
          }
        })
        .then(function (response) {
          // console.log('query =>',response.data.value)
          let values = {
            "query" : { 
              "branchID":branchID
            },
            "sortNatural": "-1",
            "countSkip":"0",
            "countLimit": "1",
            "projection":{"status":1, "unread":1, "date":1}
          }
  
          axios({
            method: 'post',
            url: window.url + 'api/v1/collection/' + users.OrganizationSelect + '/alert/query',
            data: values,
            headers:{
              "Authorization": users.token
            }
          })
          .then(function (response1) {
            //console.log('alert =>',response1.data.value)
            // if(response.data.value.result.length){
            //   let result = response.data.value.result[0]
            //   result['alert'] = {}
            //   if(response1.data.value.result.length){
            //     result['alert'] = response1.data.value.result[0]
            //   }
            //   callback(null, result)
            // }else{
            //   callback('error', {})
            // }

            const branch = THIS.state.branchs.find( (branch) => branch.branchID === branchID );
            axios({
              method: "get",
              url: window.url +"api/v1/collection/" + users.OrganizationSelect +"/" + "branch" + "/" + branch._id,
              // url: 'http://13.251.52.58:3001/api/v1/users/' + dataCallback.OrganizationSelect,
              data: "values",
              headers: {
                Authorization: users.token
              }
            })
            .then(function (response2) {
              // console.log('--------- response2 =>', response2.data.value)  
              if(response.data.value.result.length){
                let result = response.data.value.result[0]
                result['alert'] = {}
                if(response1.data.value.result.length){
                  result['alert'] = response1.data.value.result[0]
                }
                if(response2.data.value){
                  result['connect'] = response2.data.value.connect
                  result['disconnect'] = response2.data.value.disconnect
                }
                callback(null, result)
              }else{
                callback('error', {})
              }
            })
            .catch(function(error) {
              THIS.setState({
                alertShow: true,
                alertText: error.message
              });
              console.log("axios error =>", error.message);
            });

          })
          .catch(function(error) {
            THIS.setState({
              alertShow: true,
              alertText: error.message
            });
            console.log("axios error =>", error.message);
          });
        })
        .catch(function(error) {
          THIS.setState({
            alertShow: true,
            alertText: error.message
          });
          console.log("axios error =>", error.message);
        });
      })

      q.drain = () => {
        //console.log('drain')
        for(let i = 0; i < branchs.length; i++){
          for(let j = 0; j < tmpBranchs.length; j++){
            if(branchs[i].branchID === tmpBranchs[j].branchID){
              delete branchs[i].sensor
              branchs[i].sensor = tmpBranchs[j].sensor
              branchs[i].value = tmpBranchs[j].value
              // branchs[i].connect = tmpBranchs[j].date
              branchs[i].connect = tmpBranchs[j].connect
              branchs[i].disconnect = tmpBranchs[j].disconnect
              branchs[i].alert = tmpBranchs[j].alert
              break
            }
          }
        }
        this.state.branchs = branchs
        // console.log('this.state.branchs =>', this.state.branchs)
        this.setState({
          // branchs: branchs,
          isButtonLoading : true
        },() => {
          this.paginationItemCal();
          this.branchsPage(this.state.paginationActive);
        })
      }

      q.push(branchsToget, function (err, dataFn) {
        //console.log('err dataFn =>',err,dataFn)
        if(!err){
          tmpBranchs.push(dataFn)
        }
      })
      
    }
  }

  refresh = () => {
    //console.log('refresh =>')
    this.setState({
      isButtonLoading : false
    })
    let branchsPage = this.state.branchsPage
    let branchIDs = []
    for (let x = 0; x < branchsPage.length; x++) {
      branchIDs.push(branchsPage[x].branchID)
    }
    //console.log('branchIDs =>',branchIDs)
    this.getDataSensorAsync(branchIDs) 
    if(!this.state.btnSaveEnable){
      this.realTimeGetSensor()
    }
  }

  realTimeGetSensor = () => {
    if(this.rtTimeout != null){
      clearTimeout(this.rtTimeout)
    }
    let timeoutGetRealtime = 10000      //10 sec
    //console.log('timeoutGetRealtime =>',window.configVending.timeoutGetRealtime)
    if(window.configVending.timeoutGetRealtime){
      timeoutGetRealtime = window.configVending.timeoutGetRealtime * 1000
    }

    this.rtTimeout = setTimeout(() => {    
      if(!this.state.modalDetailShow) {  
        this.refresh()
      }else{
        this.setState({
          realtimeEnable : false
        })
      }
    }, timeoutGetRealtime); 

  }

  modalClose = () => {
    // this.setState({modalWorning: ""})
    this.setState({ modalWorning: "", modalDetailShow: false });
    // console.log('this.state.modalForm =>', this.state.modalForm)
  };

  hasWhiteSpace = (s) => {
    return /\s/g.test(s);
  }


  getForm = () => {
    //+-console.log("getForm modalForm =>", this.state.modalForm);
    //let data = {};
    let data = this.state.tmpDataBranchSave
    for (let i = 0; i < this.state.modalForm.length; i++) {
      if (this.state.modalForm[i].value === "") {
        return this.setState({ modalWorning: "Input is blank!" });
      }
      //+-console.log('key =>',this.state.modalForm[i].key)
      //+-console.log('value =>',this.state.modalForm[i].value)
      if(this.state.modalForm[i].key==='branchID'){
        if(this.hasWhiteSpace(this.state.modalForm[i].value)){
          return this.setState({modalWorning: "Vending Id must not contain spaces!"})
        }else if((this.state.modalForm[i].value.length < window.configVending.IdMinLengh) || (this.state.modalForm[i].value.length > window.configVending.IdMaxLengh)){
          return this.setState({modalWorning: "Invalid 'Vending Id' length!"})
        }
      }

      if(this.state.modalForm[i].key==='lat' || this.state.modalForm[i].key==='lng'){
        if(isNaN(this.state.modalForm[i].value)){
          return this.setState({modalWorning: "Latitude or Longitude is not a number"})
        }
      }


      data[this.state.modalForm[i].key] = this.state.modalForm[i].value
    }

    for (let i = 0; i < this.state.branchAll.length; i++) {
      if (this.state.branchAll[i].branchID == data.branchID)
        return this.setState({ modalWorning: "ID "+data.branchID+" is duplicate!" });
    }

    data.createBy = this.state.user.username
    data.updateBy = this.state.user.username
    data.organization = this.state.user.OrganizationSelect
    data.approved = true
  
    //data.products = [];
    console.log("getForm data =>", data)
    // return
    let THIS = this;
    axios({
      method: "post",
      url: window.url + "api/v1/collection/" + this.state.user.OrganizationSelect + "/" + "branch",
      // url: 'http://13.251.52.58:3001/api/v1/users/' + this.state.user.organization,
      data: data,
      headers: {
        Authorization: this.state.user.token
      }
    })
    .then(function(response) {
      if(response.data.value){
        //console.log("Add branch response =>", response.data.value); // {n: 1, nModified: 1, ok: 1}
        let dataSave = {
          "type" : 'information', //information //warning // error
          "date" : new Date(),
          "surce" : 'branchMan',
          "category" : 'webApp',
          "even" : data.status,
          "user" :  THIS.state.user.username,
          "ip" : "",
          "discription" : 'Add new branch =>'+ data.branch
        }
        THIS.saveLog(dataSave)

        let branchAdd = response.data.value[0];
        branchAdd.connect = branchAdd.disconnect = new Date();

        let branchs = THIS.state.branchs
        branchs.unshift(branchAdd);

        // console.log('branchs =>',branchs)
        // console.log('THIS.state.branchsPage =>',THIS.state.branchsPage)
        // console.log('THIS.state.usersRes =>',THIS.state.usersRes)

        let user = THIS.state.user
        user.branch = []
        user.branchID = []
        branchs.forEach(doc => {
          let obj = {
            "branchID" : doc.branchID,
            "branchName" : doc.branch,
            "projectName" : doc.project,
            "projectID": doc.projectID
          }
          user.branch.push(obj)
          user.branchID.push(doc.branchID)
        });

        THIS.setState({
          branchs: branchs,
          branchsPage: branchs,
          usersRes: branchs,
          user : user
        });
        // THIS.state.user.branch.push(data);
        // THIS.state.user.branchID.push(data.branchID);
        // let tempUser = JSON.parse(JSON.stringify(THIS.state.user));
        // tempUser.updateBy = THIS.state.user.username;
        // tempUser.organization = [data.organization];
        // storeGlobal.setusers(THIS.state.user);
        // THIS.setState({ user: THIS.state.user }, () => {
        //   delete tempUser.password;
        //   delete tempUser.permission;
        //   delete tempUser.project;
        //   delete tempUser.roleAll;
        //   delete tempUser.token;
        //   delete tempUser.permission;
        //   delete tempUser.branch;
        //   delete tempUser.branchID;

        //   delete tempUser.OrganizationSelect;
        //   delete tempUser.ProjectSelect;
        //   delete tempUser.BranchidSelect;
        //   delete tempUser.branchsSelect;
        //   tempUser.status = "Modify";
        // });
        // let THIS1 = THIS;
        // axios({
        //   method: "put",
        //   //url: 'http://localhost:3001/api/v1/users/' + this.state.userEdit.organization[0] + '/' + this.state.userEdit._id ,
        //   url:  window.url + "api/v1/users/" + THIS.state.user.OrganizationSelect + "/" + THIS.state.user._id,
        //   // url: 'http://13.251.52.58:3001/api/v1/users/' + this.state.user.organization,
        //   data: tempUser,
        //   headers: {
        //     Authorization: THIS.state.user.token
        //   }
        // })
        //   .then(function(response) {
        //     // let userAdd = response.data.value[0]
        //     //+-console.log("Edit User response =>", response.data.value); // {n: 1, nModified: 1, ok: 1}
        //     if (response.data.value.ok === 1) {
        //       //+-console.log("modify user complete user =>",THIS1.state.user.branchID);
        //     }
        //   })
        //   .catch(function(error) {
        //     THIS1.setState({
        //       alertShow: true,
        //       alertText: error.message
        //     });
        //     console.log("axios error =>", error.message);
        //   });
        THIS.modalClose();
        // console.log("Add branch response 2 =>", branchAdd, THIS.state.branchsPage); // {n: 1, nModified: 1, ok: 1}
      }
    })
    .catch(function(error) {
      THIS.setState({
        alertShow: true,
        alertText: error.message
      });
      console.log("axios error =>", error.message);
    });
  };

  addBranch = () => {
    //+-console.log("addBranch item =>");
    let tmpDataBranchSave = {
      products : [],
      status: 'New branch'
    }
    let projectItems = [];
    for (let i = 0; i < this.state.user.project.length; i++) {
      projectItems.push(this.state.user.project[i].projectName);
    }

    let typeItems = window.configVending.typeItems
    let vendingIdplaceholder = "Vending Id length "+window.configVending.IdMinLengh+ ' - ' + window.configVending.IdMaxLengh + ' must not contain spaces!'
    let modalForm = [
      { key: "branchID", name: "ID", placeholder: vendingIdplaceholder, value: "", disabled: false, type: 'input' }, 
      { key: "branch", name: "Name", placeholder:"", value: "", disabled: false, type: 'input' }, 
      { key: "type", name: "Type", placeholder:"", value: typeItems[0], disabled: false, type: 'select', items: typeItems}, 
      { key: "location", name: "Location", placeholder:"", value: "", disabled: false, type: 'input' }, 
      { key: "lat", name: "Latitude", placeholder:"Must be numbers only", value: "", disabled: false, type: 'input' }, 
      { key: "lng", name: "Longitude", placeholder:"Must be numbers only", value: "", disabled: false, type: 'input' }, 
      // { key: "project", name: "Project", placeholder:"", value: projectItems[0], disabled: false, type: 'select', items: projectItems}, 
    ]
    // console.log('modalForm =>', modalForm);
    this.setState({
      modalForm: modalForm,
      modalAddbranchShow: true,
      tmpDataBranchSave: tmpDataBranchSave
    });
  };

  detailBranch = item => {
    //console.log('detailBranch item =>', item);
    let location = {
      pathname: "/branchsetting",
      state: { id: item._id, branchID: item.branchID, organization: item.organization },
      pageFrom: "/branchmonitoring"
    };

    this.setState({
      location: location,
      redirect: true
    });
  };

  detailAlert = item => {
    //console.log('detailAlert item =>',item)
    if(JSON.stringify(this.state.user) !== '{}'){
      let users = this.state.user
      //calculate your data here
      //then redirect:
      let startDate = new Date()
      let endDate = new Date()
      if(new Date(item.connect) < new Date(item.alert.date)){
        startDate = new Date(item.connect)
        endDate = new Date(item.alert.date)
      }else{
        startDate = new Date(item.alert.date)
        endDate = new Date(item.connect)
      }

      let value = {
        //urls:"http://13.251.52.58:3001/api/v1/report/alert-report",
        urls: window.url + "api/v1/report/alert-report",
        title:"Detail Alert",
        header:["ITEM","VENDING ID","VENDING NAME","STATUS","TYPE","ACTION","MESSAGE","DATE"],
        defaultValue:[],
        defaultSum:[],
        countPerPage:20,
        BranchsSelect: users.branchsSelect,
        BranchidSelect: [item.branchID],
        OrganizationSelect: users.OrganizationSelect,
        _sortNatural: -1,
        startDate: startDate,
        endDate : endDate,
        username : users.username
      }

      let location = {
        pathname: '/detaildashboard',
        pageFrom: "/branchmonitoring",
        state: {
          dataSend: value
        }
      }

      this.setState({
        redirect: true,
        location: location
      });
    }
  }

  saveLog = (dataIn) => {

    fetch('https://api.ipify.org/?format=json').then(response => {
      return response.json();
    }).then((res) => {
      //console.log('res ->', res)
      let user = this.state.user
      let data = dataIn
      data.ip = res.ip
      let organization = this.state.user.OrganizationSelect
      console.log("organization =>", organization)
      // return
      let THIS = this
      axios({
        method: 'post',
        url: window.url + 'api/v1/collection/' + organization + '/log',
        data: data,
        headers:{
          "Authorization": user.token
        }
      })
      .then(function (response1) {
        console.log('response1 ->',organization,'=',response1)

      })
      .catch(function (error) {
        console.log(error);
        // alert("error: " + JSON.stringify(error) );
        THIS.setState({ alertText: error.message, alertShow: true})
        THIS.setState({loadingOverlay: false})
      });
    })
  }

  statusOnline = item => {
    let connect = new Date(item.connect);
    let disconnect = new Date(item.disconnect);
    if (connect >= disconnect) {
      return (
        <Badge color="success" className="my-4">
          Online
        </Badge>
      )
    }
    return (
      <Badge color="danger" className="my-4">
        Offline
      </Badge>
    )
  }

  statusAlert = item => {
    //console.log("item =>",item)
    if(item !== undefined){
      if(JSON.stringify(item.alert) !== '{}'){
        if(JSON.stringify(this.state.user) !== '{}'){
          let users = this.state.user
          let found = undefined
          if(item.alert.unread !== undefined){
            found = item.alert.unread.find(element => {
              if(element === users.username) return true
            })
          }
          //console.log('found =>',found)

          if(found === undefined){
            switch(item.alert.status){
              case 'info':
                return (
                  <Button size="sm" color="info"
                    onClick={ () => this.detailAlert(item) }
                    >
                    {item.alert.status}
                  </Button>
                )
              break
              case 'warning':
                return (
                  <Button size="sm" color="warning"
                    onClick={ () => this.detailAlert(item) }
                    >
                    {item.alert.status}
                  </Button>
                )
              break
              case 'error':
                return (
                  <Button size="sm" color="danger"
                    onClick={ () => this.detailAlert(item) }
                    >
                    {item.alert.status}
                  </Button>
                )
              break
            }

          }else{
            return (
              <Button size="sm" color="default"
                onClick={ () => this.detailAlert(item) }
                >
                {item.alert.status}
              </Button>
            )
          }
        }
      }
    }
  }

  approvedBadge = item => {
    if(item){
      return (
        <Badge color="success" className="my-4">
            true
        </Badge>
      )
    }else{
      return (
        <Badge color="secondary" className="my-4">
          false
        </Badge>
      )
    }
  }


  handleInputChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    // console.log("handleChange  =>", name, value);
    // console.log("this.state.modalForm  =>", this.state.modalForm);
    let modalForm = this.state.modalForm
    for (let i = 0; i < modalForm.length; i++) {
      if (modalForm[i].name === name) {
        modalForm[i].value = value;
        break;
      }
    }

    this.setState({ modalForm: modalForm });
  };

  onSearch = () => {
    //+-console.log("onSearch =>", this.state.searchText, this.state.usersRes);
    let search = this.state.searchText;
    let res = this.state.usersRes.filter(obj =>
      Object.keys(obj).some(key => {
        let out =
          key !== "_id" &&
          typeof obj[key] == "string" &&
          obj[key].includes(search);
        // console.log('onSearch out =>', out);
        return out;
      })
    );
    this.setState({ searchButton: false, branchs: res }, () => {
      this.paginationItemCal();
      // this.usersPage(1)
      this.branchsPage(1);
    });
  };

  onSearchClear = () => {
    //+-console.log("onSearchClear =>", this.state.searchText);
    this.setState(
      { searchText: "", searchButton: true, branchs: this.state.usersRes },
      () => {
        this.paginationItemCal();
        // this.usersPage(1)
        this.branchsPage(1);
      }
    );
  };

  showDetail = (itemIn,index) =>{
    //console.log(itemIn,index)
    let dataShow = {}
    if(itemIn.branchID) { dataShow['branchID'] = itemIn.branchID} else {dataShow['branchID'] = '-'}
    if(itemIn.branch) { dataShow['branch'] = itemIn.branch} else {dataShow['branch'] = '-'}
    if(itemIn.value) { dataShow['value'] = itemIn.value} else {dataShow['value'] = '-'}
    if(itemIn.sensor && itemIn.sensor.volt_1 != undefined) { dataShow['volt_1'] = itemIn.sensor.volt_1} else {dataShow['volt_1'] = '-'}
    if(itemIn.sensor && itemIn.sensor.volt_2 != undefined) { dataShow['volt_2'] = itemIn.sensor.volt_2} else {dataShow['volt_2'] = '-'}
    if(itemIn.sensor && itemIn.sensor.current_1 != undefined) { dataShow['current_1'] = itemIn.sensor.current_1} else {dataShow['current_1'] = '-'}
    if(itemIn.sensor && itemIn.sensor.current_2 != undefined) { dataShow['current_2'] = itemIn.sensor.current_2} else {dataShow['current_2'] = '-'}
    if(itemIn.sensor && itemIn.sensor.watt_1 != undefined) { dataShow['watt_1'] = itemIn.sensor.watt_1} else {dataShow['watt_1'] = '-'}
    if(itemIn.sensor && itemIn.sensor.watt_2 != undefined) { dataShow['watt_2'] = itemIn.sensor.watt_2} else {dataShow['watt_2'] = '-'}
    if(itemIn.sensor && itemIn.sensor.var_PH != undefined) { dataShow['var_PH'] = itemIn.sensor.var_PH} else {dataShow['var_PH'] = '-'}
    if(itemIn.sensor && itemIn.sensor.var_ORP != undefined) { dataShow['var_ORP'] = itemIn.sensor.var_ORP} else {dataShow['var_ORP'] = '-'}
    if(itemIn.sensor && itemIn.sensor.var_EC != undefined) { dataShow['var_EC'] = itemIn.sensor.var_EC} else {dataShow['var_EC'] = '-'}
    if(itemIn.sensor && itemIn.sensor.Set_EC != undefined) { dataShow['Set_EC'] = itemIn.sensor.Set_EC} else {dataShow['Set_EC'] = '-'}
    if(itemIn.sensor && itemIn.sensor.M_V != undefined) { dataShow['M_V'] = itemIn.sensor.M_V} else {dataShow['M_V'] = '-'}
    if(itemIn.sensor && itemIn.sensor.M_A != undefined) { dataShow['M_A'] = itemIn.sensor.M_A} else {dataShow['M_A'] = '-'}
    if(itemIn.sensor && itemIn.sensor.M_PP != undefined) { dataShow['M_PP'] = itemIn.sensor.M_PP} else {dataShow['M_PP'] = '-'}
    if(itemIn.sensor && itemIn.sensor.M_PN != undefined) { dataShow['M_PN'] = itemIn.sensor.M_PN} else {dataShow['M_PN'] = '-'}
    if(itemIn.sensor && itemIn.sensor.M_VL != undefined) { dataShow['M_VL'] = itemIn.sensor.M_VL} else {dataShow['M_VL'] = '-'}
    if(itemIn.sensor && itemIn.sensor.M_VT != undefined) { dataShow['M_VT'] = itemIn.sensor.M_VT} else {dataShow['M_VT'] = '-'}
    if(itemIn.sensor && itemIn.sensor.M_EP != undefined) { dataShow['M_EP'] = itemIn.sensor.M_EP} else {dataShow['M_EP'] = '-'}
    if(itemIn.sensor && itemIn.sensor.M_EN != undefined) { dataShow['M_EN'] = itemIn.sensor.M_EN} else {dataShow['M_EN'] = '-'}
    if(itemIn.sensor && itemIn.sensor.M_VF != undefined) { dataShow['M_VF'] = itemIn.sensor.M_VF} else {dataShow['M_VF'] = '-'}

    let modalForm = [
      { key: "branchID", name: "ID", placeholder: "", value: dataShow.branchID, disabled: true, type: 'input', col : 1}, 
      { key: "branch", name: "Name", placeholder:"", value: dataShow.branch, disabled: true, type: 'input', col:2 }, 
      { key: "Value", name: "Value", placeholder:"", value: dataShow.value, disabled: true, type: 'input', col:1 }, 
      { key: "var_PH", name: "var_PH", placeholder:"", value: dataShow.var_PH, disabled: true, type: 'input', col:2 }, 
      { key: "var_ORP", name: "var_ORP", placeholder:"", value: dataShow.var_ORP, disabled: true, type: 'input', col:1 }, 
      { key: "var_EC", name: "var_EC", placeholder:"", value: dataShow.var_EC, disabled: true, type: 'input', col:2 }, 
      { key: "Set_EC", name: "Set_EC", placeholder:"", value: dataShow.Set_EC, disabled: true, type: 'input', col:1 },
      { key: "M_VF", name: "M_VF", placeholder:"", value: dataShow.M_VF, disabled: true, type: 'input', col:2 }, 
      { key: "M_VL", name: "M_VL", placeholder:"", value: dataShow.M_VL, disabled: true, type: 'input', col:1 }, 
      { key: "M_VT", name: "M_VT", placeholder:"", value: dataShow.M_VT, disabled: true, type: 'input', col:2 }, 
      { key: "volt_1", name: "volt_1", placeholder:"", value: dataShow.volt_1, disabled: true, type: 'input', col:1 }, 
      { key: "volt_2", name: "volt_2", placeholder:"", value: dataShow.volt_2, disabled: true, type: 'input', col:2 }, 
      { key: "current_1", name: "current_1", placeholder:"", value: dataShow.current_1, disabled: true, type: 'input', col:1 }, 
      { key: "current_2", name: "current_2", placeholder:"", value: dataShow.current_2, disabled: true, type: 'input', col:2 }, 
      { key: "watt_1", name: "watt_1", placeholder:"", value: dataShow.watt_1, disabled: true, type: 'input', col:1 }, 
      { key: "watt_2", name: "watt_2", placeholder:"", value: dataShow.watt_2, disabled: true, type: 'input', col:2 },  
      { key: "M_V", name: "M_V", placeholder:"", value: dataShow.M_V, disabled: true, type: 'input', col:1 }, 
      { key: "M_A", name: "M_A", placeholder:"", value: dataShow.M_A, disabled: true, type: 'input', col:2 }, 
      { key: "M_PP", name: "M_PP", placeholder:"", value: dataShow.M_PP, disabled: true, type: 'input', col:1 }, 
      { key: "M_PN", name: "M_PN", placeholder:"", value: dataShow.M_PN, disabled: true, type: 'input', col:2 }, 
      { key: "M_EP", name: "M_EP", placeholder:"", value: dataShow.M_EP, disabled: true, type: 'input', col:1 }, 
      { key: "M_EN", name: "M_EN", placeholder:"", value: dataShow.M_EN, disabled: true, type: 'input', col:2 }, 
    ]
    // console.log('modalForm =>', modalForm);
    this.setState({
      modalForm: modalForm,
      modalDetailShow: true
    });
  }

  handleKeyPress = e => {
    if (e.key === "Enter") {
      this.onSearch();
    }
  };

  handleClickMarker = place => {
    // console.log("handleClickMarker =>", place);
    this.detailBranch(place)
  };

  //=========================================================================== render()
  render() {
    // let modalClose = () => this.setState({ modalAddbranchShow: false });

    const paginationStyle = {
      display: "table",
      margin: "0 auto"
    };
    // let users = storeGlobal.getusers();
    // let props = storeGlobal.getprops();

    if (this.state.redirect) return <Redirect to={this.state.location} />;

    return (
      // <LoadingOverlay
      //   active={true}
      //   spinner={<SyncLoader />}
      // >

      // </LoadingOverlay>

      <SiteWrapper user={this.state.user} props={this.props}>
        <Page.Content
          title="Vending monitoring"
          options={
            this.state.usersRes.length && (
              <Form.Group label="">
                <Form.InputGroup>
                  <Form.Input
                    placeholder="Search for..."
                    onChange={e =>
                      this.setState({
                        searchText: e.target.value,
                        searchButton: true
                      })
                    }
                    value={this.state.searchText}
                    onKeyPress={this.handleKeyPress}
                  />
                  <Form.InputGroupAppend>
                    {this.state.searchButton ? (
                      <Button
                        color="primary"
                        icon="search"
                        onClick={this.onSearch}
                      />
                    ) : (
                      <Button
                        color="default"
                        icon="x"
                        onClick={this.onSearchClear}
                      />
                    )}
                  </Form.InputGroupAppend>
                </Form.InputGroup>
              </Form.Group>
            )
          }
        >
          { ((this.state.user.organization.length) && this.state.optionBranchs && this.state.optionProjects && this.state.optionOrganizations) &&
            <SelectBranchs 
              typeSelectFilter = {this.state.typeSelectFilter}
              getbranchs = {this.getbranchs} 
              optionOrganizations = {this.state.optionOrganizations}
              optionProjects = {this.state.optionProjects}
              optionBranchs = {this.state.optionBranchs}
              btLoading={this.state.btLoading}
            />
          }

          { window.mapShowVending &&
            <Card className="">
              {/* google-maps-react */}
              <div style={{ height: '400px',}}>
                <Map  
                  places={this.state.places} 
                  onClickDetail={this.handleClickMarker} 
                /> 
              </div>

              {/* react-google-maps */}
              {/* <Map 
                center={{ lat: 13.8056305, lng: 100.8402304 }}
                zoom={4}
                places={places}
                onClickMarker = {this.onClickMarker}
              /> */}
            </Card>
          }

          <Card>
            <Card.Header>
              <Card.Title>Vending</Card.Title>
              <Card.Options>
                <Button.List>
                  {!this.state.realtimeEnable ? 
                    <Button
                    color="info"
                    onClick={ () => { 
                      this.setState({
                        realtimeEnable : true
                        },() => {
                        this.refresh()
                      })
                    }}
                    >
                    Start realtime
                    </Button>

                    :

                    (this.state.isButtonLoading? 
                    <Button 
                      color="secondary" 
                      icon="rotate-cw" 
                      onClick={ () => { 
                        this.setState({
                          realtimeEnable : true
                          },() => {
                          this.refresh()
                        })
                      }}
                    />
                    :
                    <Button 
                      color="secondary" 
                      icon="rotate-cw" 
                      loading 
                    />
                    )
                  }   
                </Button.List>
              </Card.Options>
            </Card.Header>

            <Table
              responsive
              className="card-table table-vcenter text-nowrap table-hover "
              // headerItems= { this.state.headerItems }
              // bodyItems={ this.state.bodyItems }
            >
              <Table.Header>
                <Table.Row>
                  <Table.ColHeader className="table-sticky-left">Item</Table.ColHeader>
                  <Table.ColHeader>Name</Table.ColHeader>    
                  <Table.ColHeader>Value</Table.ColHeader>
                  <Table.ColHeader>PH</Table.ColHeader>
                  <Table.ColHeader>ORP</Table.ColHeader>
                  <Table.ColHeader>EC</Table.ColHeader>
                  <Table.ColHeader>watt</Table.ColHeader>
                  {/* <Table.ColHeader>watt_2</Table.ColHeader> */}
                  <Table.ColHeader>Date</Table.ColHeader>
                  <Table.ColHeader>Status</Table.ColHeader>
                  <Table.ColHeader>Alert</Table.ColHeader>
                  <Table.ColHeader className="text-center table-sticky-right">
                    Action
                  </Table.ColHeader>
                </Table.Row>
              </Table.Header>
              {/* <Table.Row key={ i } onClick = {() =>this.showDetail(item,i)}></Table.Row> */}
              <Table.Body>
                { this.state.branchsPage.length ? this.state.branchsPage.map( (item, i) =>
                  <Table.Row key={ i } onClick = {() =>this.showDetail(item,i)}>
                    <Table.Col className="table-sticky-left">{ item.index }</Table.Col>
                    <Table.Col>{ item.branch }</Table.Col>
                    {/* <Table.Col>{ item.approved?'true':'false' }</Table.Col>   */}
                    {/* <Table.Col>{ this.ON_OFFBadge(item.sensor.pumpout) }</Table.Col>  */}
                    {/* <Table.Col>{ item.sensor ? this.ON_OFFBadge(item.sensor.pumpout) : this.ON_OFFBadge(false)}</Table.Col>  */}
                    <Table.Col>{ item.value ? item.value : <Text>-</Text>} </Table.Col> 
                    <Table.Col>{ item.sensor ? item.sensor.var_PH : <Text>-</Text>} </Table.Col> 
                    <Table.Col>{ item.sensor ? item.sensor.var_ORP : <Text>-</Text>} </Table.Col> 
                    <Table.Col>{ item.sensor ? item.sensor.var_EC : <Text>-</Text>} </Table.Col> 
                    <Table.Col>{ item.sensor ? item.sensor.watt_1+item.sensor.watt_2 : <Text>-</Text>} </Table.Col> 
                    {/* <Table.Col>{ item.sensor ? item.sensor.watt_2 : <Text>-</Text>} </Table.Col>  */}
                    <Table.Col>
                      <Moment format="DD/MM/YYYY HH:mm:ss">
                        { item.connect }
                      </Moment>  
                    </Table.Col>
                    {/* <Table.Col>{ item.sensor ? this.ON_OFFBadge(item.sensor.pumpout) : this.ON_OFFBadge(false)}</Table.Col> 
                    <Table.Col>{ item.sensor ? this.ON_OFFBadge(item.sensor.pumpout) : this.ON_OFFBadge(false)}</Table.Col> 
                    <Table.Col>{ item.sensor ? this.ON_OFFBadge(item.sensor.pumpout) : this.ON_OFFBadge(false)}</Table.Col> 
                    <Table.Col>{ item.sensor ? this.ON_OFFBadge(item.sensor.pumpout) : this.ON_OFFBadge(false)}</Table.Col>   */}
                    <Table.Col>{ this.statusOnline(item) }</Table.Col> 
                    <Table.Col>{ item.alert ? this.statusAlert(item) : <Text>-</Text>}</Table.Col> 
                    <Table.Col className="text-center table-sticky-right">
                      <Button.List>                        
                        <Button size="sm" color="info"
                          onClick={ () => this.detailBranch(item) }
                          >
                            Detail
                          </Button>
                        </Button.List>
                      </Table.Col>
                    </Table.Row>
                ) : (
                  <Table.Row>
                    <Table.Col colSpan="12" alignContent="center">
                      <Text className="my-4" size="" center="true" color="primary">
                        ไม่พบข้อมูล : Data not found.
                      </Text>
                    </Table.Col>
                  </Table.Row>
                )}
                <Table.Row>
                  <Table.Col colSpan="12" alignContent="center">
                    <div style={paginationStyle}>
                      <Pagination>
                        {this.state.paginationItem.map(number => (
                          <Pagination.Item
                            key={number}
                            active={number === this.state.paginationActive}
                            onClick={this.paginationActive.bind(this, number)}
                          >
                            {number}
                          </Pagination.Item>
                        ))}
                      </Pagination>
                    </div>
                  </Table.Col>
                </Table.Row>
              </Table.Body>
            </Table>
          </Card>
        </Page.Content>

        <ModalFormLarge
          show={this.state.modalDetailShow}
          onHide={this.modalClose}
          title={"Show more details"}
          worning={this.state.modalWorning}
          form={this.state.modalForm}
        />

        {this.state.alert}

        <SweetAlert
          warning
          show={this.state.alertShow}
          title="Oops!"
          onConfirm={() => this.setState({ alertShow: false })}
        >
          {this.state.alertText}
        </SweetAlert>
      </SiteWrapper>
    );
  }
}

export default BranchMan;
