import React,{Component} from 'react'
import { Modal } from  'react-bootstrap';
import { Button, Form, Grid, Text } from "tabler-react";
import Select from 'react-select';
import storeGlobal from "../components/globalStore";
import { fromUnixTime } from 'date-fns';

class ModalAddUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        userID: props.form.userID || '',
        username: props.form.username || '',
        password: props.form.password || '',
        fullname: props.form.fullname || '',
        role: props.roleAll[0] || '',
        branchID: props.form.branchID || [],
        organization: [],
        project: [],
        routeman: props.form.routeman,
        organizationSelect: props.organizationAll[0] || '',
        projectSelect:''
      }
    };
    // this.handleClick = this.handleClick.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    //+-console.log('ModalAddUser props =>', props)
  }

  componentDidMount() {
    // console.log('componentDidMount this.state.form =>', this.state.form)  
    let users = storeGlobal.getusers();
    if(users){
      let Organization = []
      let organizationDefault = {label:users.OrganizationSelect,value:users.OrganizationSelect}
      for(let i = 0; i < users.organization.length; i++){
        Organization.push({label:users.organization[i],value:users.organization[i]})
      }
      let Project = []
      for(let i = 0; i < users.project.length; i++){
        if(users.OrganizationSelect === users.project[i].organization){
          Project.push({label:users.project[i].projectName,value:users.project[i].projectName})    
        }
      }
      let branchIDAll = []
      for(let i = 0; i < users.branchID.length; i++){
        branchIDAll.push({label:users.branchID[i],value:users.branchID[i]})
      }

      //console.log('componentDidMount =>',Project,Organization,users.OrganizationSelect)

      let form = this.state.form
      form.organization = Organization
      form.organizationSelect = [organizationDefault]
      form.organizationDefault = organizationDefault
      form.project = Project
      form.branchIDAll = branchIDAll
  
      this.setState({
        form
      }, () => { 
        //console.log("this.state.form =>", this.state.form)
      })
    }
  }

  // componentDidUpdate(prevProps) {
  //   console.log('componentDidUpdate prevProps =>', prevProps)
  // }

  // componentWillReceiveProps(prevProps) {
  //   // console.log('componentWillReceiveProps prevProps =>', prevProps)
  //   // if(prevProps.show) {
  //     this.state.form.userID = prevProps.form.userID
  //     this.state.form.username = prevProps.form.username
  //     this.state.form.password = prevProps.form.password
  //     this.state.form.fullname = prevProps.form.fullname
  //     this.state.form.branchID = prevProps.form.branchID
  //     this.state.form.routeman = prevProps.form.routeman
  //   // }
    
  // }

  //componentWillReceiveProps(prevProps) {
  UNSAFE_componentWillReceiveProps(prevProps) {
    //console.log('componentWillReceiveProps prevProps =>', prevProps)
    let users = storeGlobal.getusers();
    if(users){
      let Organization = []
      let organizationDefault = {label:users.OrganizationSelect,value:users.OrganizationSelect}
      // let organizationDefault = []
      // for(let i = 0; i < prevProps.organizationAll.length; i++){
      //   organizationDefault.push({label: prevProps.organizationAll[i],value: prevProps.organizationAll[i]})
      // }
      for(let i = 0; i < users.organization.length; i++){
        Organization.push({label:users.organization[i],value:users.organization[i]})
      }
      let Project = []
      for(let i = 0; i < users.project.length; i++){
        if(users.OrganizationSelect === users.project[i].organization){
          Project.push({label:users.project[i].projectName,value:users.project[i].projectName})    
        }
      }
      let branchIDAll = []
      for(let i = 0; i < users.branchID.length; i++){
        branchIDAll.push({label:users.branchID[i],value:users.branchID[i]})
      }
      //console.log('componentDidMount =>',Project,Organization,users.OrganizationSelect)

      let form = this.state.form
      form.organization = Organization
      //form.organizationSelect = organizationDefault
      form.organizationDefault = organizationDefault
      form.project = Project
      form.branchIDAll = branchIDAll
      form.branchIDSelect = prevProps.form.branchIDSelect
      form.projectSelect = prevProps.form.projectSelect
      form.organizationSelect = prevProps.form.organizationSelect

      form.userID = prevProps.form.userID
      form.username = prevProps.form.username
      form.password = prevProps.form.password
      form.fullname = prevProps.form.fullname
      form.branchID = prevProps.form.branchID
      form.routeman = prevProps.form.routeman
      form.role = prevProps.formSelected.role

      this.setState({
        form
      }, () => { 
        //console.log("this.state.form =>", this.state.form)
      })
    }
    // let form = this.state.form
    // form.userID = prevProps.form.userID
    // form.username = prevProps.form.username
    // form.password = prevProps.form.password
    // form.fullname = prevProps.form.fullname
    // form.branchID = prevProps.form.branchID
    // form.routeman = prevProps.form.routeman
    // this.setState({
    //   form: form
    // })
    
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    // console.log("handleChange  =>", name, value, this.state.form);
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        [name]: value
      }
    }));
  }

  changOrganization = (dataCallback) => {
    let users = storeGlobal.getusers();
    let Project = [];
    //console.log("changOrganization dataCallback ->",dataCallback);
    if(dataCallback.length){
      for(let j = 0; j < dataCallback.length; j++){
        for(let i = 0; i < users.project.length; i++){
          if(dataCallback[j].value === users.project[i].organization){
            Project.push({label:users.project[i].projectName,value:users.project[i].projectName})    
          }
        }
      }
    }
    //+-console.log("project",project);
    // this.setState({
    //   ProjectSelect:"",
    //   Project
    // })
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        organizationSelect: dataCallback,
        project: Project,
        projectSelect: ''
      }
    }), () => { 
      //console.log("this.state.form =>", this.state.form) 
    });
  }

  changProject = (dataCallback) => {
    // this.setState({
    //   ProjectSelect: dataCallback.value
    // });
    //console.log('dataCallback =>',dataCallback)
    let users = storeGlobal.getusers();

    let branchIDAll = []
    for(let j = 0; j < dataCallback.length; j++){
      for(let i = 0; i < users.branch.length; i++){
        if(dataCallback[j].value == users.branch[i].projectName){
          branchIDAll.push({label:users.branchID[i],value:users.branchID[i]})
        }
      }
    }

    this.setState(prevState => ({
      form: {
        ...prevState.form,
        projectSelect: dataCallback,
        branchIDAll: branchIDAll
      }
    }), () => { 
      //console.log("this.state.form =>", this.state.form) 
    });
  }

  changBranch = (dataCallback) => {
    // this.setState({
    //   ProjectSelect: dataCallback.value
    // });
    //console.log('dataCallback =>',dataCallback)

    this.setState(prevState => ({
      form: {
        ...prevState.form,
        branchIDSelect : dataCallback
      }
    }), () => { 
      //console.log("this.state.form =>", this.state.form) 
    });
  }

  changValue = (val, opts) => {
    //+-console.log("val, opts =>", val, opts);
    // if (this.props.dataChang) {
    //   this.props.dataChang(val);
    // }

    let branchID = []
    for(let i=0; i<val.length; i++) {
      if(val[i].value === 'all') {
        branchID = this.props.branchIDAll
        // console.log("changValue branchID =>", branchID);
        break
      }
      branchID.push(val[i].value)
    }
    // console.log("changValue branchID 2 =>", branchID);
    //this.state.form.branchID = branchID
    //+-console.log("this.state.form 1 =>", this.state.form, branchID)
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        branchID: branchID
      }
    }), () => { 
      //console.log("this.state.form =>", this.state.form, branchID) 
    });
    
  }

  branchIDOptions = () => {
    let branchIDOptions = [{ value: "all", label: "all" }]
    for(let i=0; i<this.props.branchIDAll.length; i++) {
      let option = { value: this.props.branchIDAll[i], label: this.props.branchIDAll[i] }
      branchIDOptions.push(option)
    }
    // console.log('branchIDOptions ...')
    return branchIDOptions
  }
  
  branchIDOptionsDefault = () => {
    // console.log('branchIDOptionsDefault this.state.form =>', this.state.form)
    if(!this.props.form.branchID) return null
    let branchIDOptionsDefault = []
    for(let i=0; i<this.props.form.branchID.length; i++) {
      let option = { value: this.props.form.branchID[i], label: this.props.form.branchID[i] }
      branchIDOptionsDefault.push(option)
    }
    // this.state.form.branchID = this.props.form.branchID
    return branchIDOptionsDefault
  }

  render() {

    return (
      <Modal
        {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Form onSubmit={(event) => console.log(event.target.name + 'clicked')}>
        <Modal.Header >
          <Modal.Title >
            { this.props.title }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
          <Grid.Row>
            <Grid.Col>
              {/* <Form.Group label="User ID">
                <Form.Input
                  name="userID"
                  placeholder=""
                  onChange={this.handleInputChange}
                  value={this.state.form.userID}
                  disabled={this.props.formDisabled.userID} 
                />
              </Form.Group> */}
              <Form.Group label="User Name">
                <Form.Input
                  name="username"
                  placeholder="User Name length 4-20 must not contain spaces!"
                  onChange={this.handleInputChange}
                  value={this.state.form.username}
                  disabled={this.props.formDisabled.username}
                />
              </Form.Group>
              <Form.Group label="Password">
                <Form.Input
                  name="password"
                  placeholder="Password length 4-16 must not contain spaces!"
                  onChange={this.handleInputChange}
                  value={this.state.form.password}
                  disabled={this.props.formDisabled.password}
                />
              </Form.Group>
              <Form.Group label="Full name">
                <Form.Input
                  name="fullname"
                  placeholder=""
                  onChange={this.handleInputChange}
                  value={this.state.form.fullname}
                />
              </Form.Group>
              <Form.Group label='Route Man'>         
                <Form.Checkbox
                  isInline={true}
                  checked={this.state.form.routeman}
                  label='routeman'
                  name='routeman'
                  value='routeman'
                  onChange={this.handleInputChange}
                />        
              </Form.Group>
            </Grid.Col>
            <Grid.Col>
              <Form.Group label="Role">
                <Form.Select disabled={this.props.nowUserNane === this.state.form.username ? true : false} name={"role"} onChange={this.handleInputChange}>                
                  { this.props.roleAll.map( (item, i) => 
                    (this.props.formSelected.role === item) ? (<option key={i} selected> {item} </option>) : (<option key={i}> {item} </option>)
                  )}
                </Form.Select>
              </Form.Group>
              {/* <Form.Group label="Organization">
                <Form.Select name={"organization"} onChange={this.changOrganization.bind(this)}>                
                  { this.props.organizationAll.map( (item, i) => 
                    <option key={i}> {item} </option>
                  )}
                </Form.Select>
              </Form.Group> */}
              <Form.Group label="Organization">
                <Select
                  name={"organization"}
                  closeMenuOnSelect={true}
                  isMulti
                  //components={makeAnimated()}
                  //defaultValue={this.state.form.organizationDefault}
                  options={this.state.form.organization}
                  onChange = {this.changOrganization}
                  value = {this.state.form.organizationSelect}
                />
              </Form.Group>
              <Form.Group label="Project">
                <Select
                  name={"project"}
                  closeMenuOnSelect={false}
                  //components={makeAnimated()}
                  isMulti
                  //defaultValue={this.state.projectDefault}
                  options={this.state.form.project}
                  onChange = {this.changProject}
                  value = {this.state.form.projectSelect}
                />
              </Form.Group>

              {/* <Form.Group label="Vending ID">
                <Select
                  name={"branchID"}
                  closeMenuOnSelect={false}
                  //components={makeAnimated()}
                  isMulti
                  //defaultValue={this.state.form.branchIDSelect}
                  options={this.state.form.branchIDAll}
                  onChange = {this.changBranch}
                  value = {this.state.form.branchIDSelect}
                />
              </Form.Group> */}
              
            </Grid.Col>

          </Grid.Row>
          
        </Modal.Body>
        <Modal.Footer>
          <div className="mr-auto ml-auto">
            <Text color="orange" >{this.props.worning}</Text>
          </div>          
          <Button type="button" onClick={ this.props.onHide } >Close</Button>
          <Button color="success" type="button" onClick={() => this.props.onSave(this.state.form)} >Save</Button>
        </Modal.Footer>
        </Form>
      </Modal>
    );
  }

}

ModalAddUser.defaultProps = {
  title: 'ModalAddUser title',
  worning: "",
  branchIDAll: [],
  roleAll: [],
  organizationAll: [],
  form: {
    userID: '',
    username: "",
    password: "",
    fullname: "",
    role: '',
    branchID: [],
    organization: '',
    routeman: true
  },
  formDisabled: {
    userID: false,
    username: false,
    password: false,
    fullname: false,
    role: false,
    branchID: false,
    organization: false,
  },
  formSelected: {
    role: '',
    // branchID: '',
    organization: '',
  },
}

export default ModalAddUser