// @flow

//import * as React from "react";
import React, { Component } from 'react';
import axios from 'axios';
import storeGlobal from "../components/globalStore";
//import SelectBranchs from "../components/SelectBranchsAndDate";
import SelectBranchs from "../components/SelectBranchsStateLess";
import Pagination from 'react-bootstrap/Pagination'
import ButtonExport from "../components/buttonExports"
import SweetAlert from 'react-bootstrap-sweetalert'

import {Page, Grid, Card,  Text,  Table} from "tabler-react";
import SiteWrapper from "../components/SiteWrapper.react";

import ModalFormLarge from "../components/ModalFormLarge";
import Moment from "react-moment";

let users = storeGlobal.getusers();

class PrescriptionAllReport extends Component {
  constructor() {
    super()
    //let users = storeGlobal.getusers();
    this.state = {
      organization:"",
      branchs:[],
      brabchidSelect:[],
      brabchNameSelect:"",
      key: 'cash',
      title:"Prescription report",
      //urls:"http://localhost:3001/api/v1/report/row-data",
      urls: window.url + "api/v1/report/order",
      //headerShow: ["item","date","vending name","product","channel","Report type","Payment Type","Value","Change","Last count","Last added","Max count","Card no/Ref no"],
      headerShow:["item","date","orderId","student/employee Id","name","lastName","createBy","activeDate","expireDate","status"],
      header:["item","date","orderId","studentId","name","lastName","createBy","activeDate","expireDate","status"],
      value:[
      ],
      sum:[],
      sumShow:[],
      paginationItem: [],
      paginationActive: 1,
      countPerPage:20,
      dataCallback:{},
      resultCount:"",
      resultAll:[],

      optionOrganizations: null,  //"../components/SelectBranchsStateLess";
      optionProjects: null,
      optionBranchs: null,
      //typeSelectFilter:"useDate"
      typeSelectFilter:"useSelectDateOrgProj",
      Collections: null,
      Collections2: null,
      
      parameters:{
        urls:"",
        token:"",
        query:"",
        headerShow:[],
        headers:[],
      },
      isButtonDisabled:true,
      alertShow: false,
      alertText: '',
      labelSelectSort: "Sorting",
      Collections3: window.transactionsPageList.sorting,

      modalDetailShow: false,
    }
  }

  CollectionsChang = (dataCallback) => {
    //console.log("dataCallback =>",dataCallback)
    if(dataCallback.value === "prescriber"){
      this.setState({
        studenId: null,
        labelSelectName4: "Prescriber",
      })
    }else{
      this.setState({
        labelSelectName4: null,
        studenId: "Studen Id"
      })
    }
  }

  findPrescriber = () =>{     
    //let users = this.state.user
    if(!(Object.entries(users).length === 0)){
      let THIS = this     
      let organization = users.OrganizationSelect
      axios({
        method: 'get',
        url: window.url + 'api/v1/users/' + organization,
        data: 'values',
        headers:{
          "Authorization": users.token
        }
      })
      .then(function (response) {
        //console.log("response =>",response)
        let PrescriberOpt = [{label:"All",value:"All"}] 
        for(let i = 0; i < response.data.value.length; i++){
          if(response.data.value[i].role === 'prescriber'){
            PrescriberOpt.push({label:response.data.value[i].username,value:response.data.value[i].username})    
          }
        }
        THIS.setState({
          labelSelectName4: "Prescriber",
          Collections4: PrescriberOpt  
        })
      })
      .catch(function (error) {
        THIS.setState({
          alertShow: true,
          alertText: error.message
        })
        console.log('axios error =>',error.message);
      });
    }
  }


  getbranchs = (dataCallback) => {
    //console.log('dataCallback -->', dataCallback)
    //slet users = this.state.user
    if(!(Object.entries(users).length === 0)){
      let THIS = this
      let organization = dataCallback.OrganizationSelect
      let ProjectSelect = dataCallback.ProjectSelect
      let CollectionsSelect = dataCallback.CollectionsSelect
      let CollectionsSelect4 = dataCallback.CollectionsSelect4
      let startDate = dataCallback.startDate
      let endDate = dataCallback.endDate
      let Filter = dataCallback.Filter
      let url =  window.url + 'api/v1/collection/' + organization + '/order/query'

      //let id = this.props.location.state.id
      let query = {}
      if(startDate !== undefined && endDate !== undefined){
        query['date'] = {
          '$gte': startDate,
          '$lt': endDate 
        }
      }
      if(ProjectSelect !== null){
        query['project'] = ProjectSelect
      }
      if(CollectionsSelect === "prescriber"){
        if(CollectionsSelect4 !== "All"){
          query['createBy'] = CollectionsSelect4  
        }
        this.setState({
          studenId: null
        })
      }else if(CollectionsSelect === "student" || CollectionsSelect === "employee"){
        if(Filter !== ""){
          query['studentId'] = Filter  
        }
        this.setState({
          labelSelectName4: null
        })
      }
      let keyChangeIsoDate = ['date']
      let values = {
        query,
        keyChangeIsoDate,
        startDate: startDate,
        endDate: endDate,
        "sortNatural": "-1",
        "countSkip":"0",
        "countLimit": this.state.countPerPage,
        "projection":{}
      }

      let valuesNolimit = JSON.parse(JSON.stringify(values))
      valuesNolimit.countLimit = 0;
      valuesNolimit.collections  = organization + '_order';
      valuesNolimit.organization  = organization;
      valuesNolimit.branchID  = [];
      valuesNolimit.headerShow = ["item","date","orderId","products count","studentId","name","lastName","createBy","activeDate","expireDate","status"]
      valuesNolimit.header = ["item","date","orderId","products","studentId","name","lastName","createBy","activeDate","expireDate","status"]
      let parameters = {
        urls: this.state.urls,
        token : users.token,
        values:valuesNolimit,
        nameOfReport : this.state.title,
        headerShow : valuesNolimit.headerShow,
        method: "post"
      } 

      //console.log("parameters =>",parameters)
      this.setState({
        parameters
      })

      //console.log("query =>",query)

      axios({
        method: 'post',
        url: url,
        data: values,
        headers:{
          "Authorization": users.token
        }
      })
      .then(function (response) {
        //console.log("response -->",response.data.value);
        let page = THIS.state.countPerPage>>0
        let resultAll = response.data.value.result
        let resultCount = response.data.value.resultCount>>0
        let pageCount =  Math.ceil(resultCount / page)
        let paginationActive = 1
        let paginationItem = []
        for(let i = 1; i <= pageCount; i++){
          if(i < 6){
            paginationItem.push(i)
          }else if(i===6){
            paginationItem.push(">")
          }else if(i===7){
            paginationItem.push(">|")
          }
        }
        //console.log("resultAll =>",resultAll)
        let value = []
        // for(let i = page * (paginationActive -1); ((i < page*paginationActive) &&  (i < resultAll.length)); i++){
        //   THIS.state.value.push(resultAll[i])
        // }
        for(let i = 0; i < resultAll.length; i++){
          resultAll[i].index = i+1
          value.push(resultAll[i])
        }
        //console.log("value =>",value)
        THIS.setState({
          resultAll:resultAll,
          value: value,
          paginationItem: paginationItem,
          resultCount: resultCount,
          paginationActive: paginationActive,
          organization: organization,
          ProjectSelect: ProjectSelect,
          CollectionsSelect: CollectionsSelect,
          CollectionsSelect4: CollectionsSelect4,
          startDate: startDate,
          endDate: endDate,
          Filter: Filter,
          isButtonDisabled:false
        })
    })
    .catch(function (error) {
      THIS.setState({
        alertShow: true,
        alertText: error.message
      })
      console.log('axios error =>',error.message);
    });
  }
}

  paginationActive(e) {
    //this.state.paginationActive = e
    let page = this.state.countPerPage
    let resultCount = this.state.resultCount
    let pageCount =  Math.ceil(resultCount / page)
    let paginationActive = this.state.paginationActive
    if(e==='>'){
      paginationActive++;
    }else if(e==='<'){
      paginationActive--;
    }else if(e==='>|'){
      paginationActive = pageCount;
    }else if(e==='|<'){
      paginationActive  = 1;
    }else{
      paginationActive = e
    }
    e = paginationActive
    this.setState({
      paginationActive: paginationActive
    })
    console.log("paginationActive e ->",e)
    if(!(Object.entries(users).length === 0)){
      let THIS = this
      let organization = this.state.organization
      let ProjectSelect = this.state.ProjectSelect
      let CollectionsSelect = this.state.CollectionsSelect
      let CollectionsSelect4 = this.state.CollectionsSelect4
      let startDate = this.state.startDate
      let endDate = this.state.endDate
      let Filter = this.state.Filter
      let url =  window.url + 'api/v1/collection/' + organization + '/order/query'

      //let id = this.props.location.state.id
      let query = {}
      if(startDate !== undefined && endDate !== undefined){
        query['date'] = {
          '$gte': startDate,
          '$lt': endDate 
        }
      }
      if(ProjectSelect !== null){
        query['project'] = ProjectSelect
      }
      if(CollectionsSelect === "prescriber"){
        if(CollectionsSelect4 !== "All"){
          query['createBy'] = CollectionsSelect4  
        }
        this.setState({
          studenId: null
        })
      }else if(CollectionsSelect === "student" || CollectionsSelect === "employee"){
        if(Filter !== ""){
          query['studentId'] = Filter  
        }
        this.setState({
          labelSelectName4: null
        })
      }
      let keyChangeIsoDate = ['date']
      let values = {
        query,
        keyChangeIsoDate,
        "sortNatural": "-1",
        "countSkip":(e-1)*this.state.countPerPage,
        "countLimit": this.state.countPerPage,
        "projection":{}
      }
      console.log("query =>",query)

      axios({
        method: 'post',
        url: url,
        data: values,
        headers:{
          "Authorization": users.token
        }
      })
      .then(function (response) {
        console.log("response -->",response.data.value);
        let page = THIS.state.countPerPage>>0
        let resultAll = response.data.value.result
        let resultCount = response.data.value.resultCount>>0
        let pageCount =  Math.ceil(resultCount / page)
        //let paginationActive = THIS.state.paginationActive
        let paginationItem = []
        if(pageCount < 6){
          for(let i = 1; i <= pageCount; i++){
              paginationItem.push(i)
          }
        }else{
          if(paginationActive < 4){
            for(let i = 1; i <= pageCount; i++){
              if(i < 6){
                paginationItem.push(i)
              }else if(i === 6){
                paginationItem.push(">")
              }else if(i === 7){
                paginationItem.push(">|")
              }else{
                break;
              }  
            }
          }else if(paginationActive > (pageCount-3)){
            for(let i = 1; i < 8; i++){
              if(i > 2){
                paginationItem.push((pageCount - (7-i)))
              }else if(i === 2){
                paginationItem.push("<")
              }else if(i === 1){
                paginationItem.push("|<")
              }else{
                break;
              }  
            }  
          }else{
            for(let i = 1; i < 10; i++){
              switch(i){
                case 1:
                  paginationItem.push("|<")
                break;
                case 2:
                  paginationItem.push("<")
                break;
                case 3:
                    paginationItem.push(paginationActive-2)
                break;
                case 4:
                    paginationItem.push(paginationActive-1)
                break;
                case 5:
                  paginationItem.push(paginationActive)
                break;
                case 6:
                    paginationItem.push(paginationActive+1)
                break;
                case 7:
                  paginationItem.push(paginationActive+2)
                break;
                case 8:
                    paginationItem.push(">")
                break;
                case 9:
                    paginationItem.push(">|")
                break;
                default:
                break
              }
            }  
          }
        }
        let value = []
        for(let i = 0; i < resultAll.length; i++){
          resultAll[i].index = i+1+(page*(e-1))
          value.push(resultAll[i])
          //response.data.transactions[i].index = i+1+(page*(e-1))
        }

        THIS.setState({
          resultAll:resultAll,
          value: value,
          paginationItem: paginationItem

        })

      })
      .catch(function (error) {
        THIS.setState({
          alertShow: true,
          alertText: error.message
        })
        console.log('axios error =>',error.message);
      });
    }
  }

  componentDidMount() {
    let start = new Date()
    start.setHours(0); start.setMinutes(0); start.setSeconds(0)
    this.setState({startDate:start});
    let end = new Date()
    end.setHours(23); end.setMinutes(59); end.setSeconds(59);
    this.setState({endDate:end});
    if(!(Object.entries(users).length === 0)){
      let organization = []
      for(var i = 0; i < users.organization.length; i++){
        organization.push({label:users.organization[i],value:users.organization[i]})  
      }
      this.setState({ optionOrganizations:organization});

      let branchID = [{label:"All",value:"All"}]
      for(var j = 0; j < users.branch.length; j++){
        branchID.push({label:users.branch[j].branchID,value:users.branch[j].branchID});
      }
      this.setState({optionBranchs:branchID});

      let project = [{label:"All",value:"All"}]
      for(var i = 0; i < users.project.length; i++){
        if(organization[0].value == users.project[i].organization){
          project.push({label:users.project[i].projectName,value:users.project[i].projectName})    
        }
      }

      let personnelOpt = [
        {label:"Prescriber",value:"prescriber"},
        {label:"Student",value:"student"},
        {label:"Employee",value:"employee"}
      ]

      let PrescriberOpt = [{label:"All",value:"All"}]

      this.setState({optionProjects:project});

      this.setState({Collections:window.transactionsPageList.type});
      this.setState({Collections2:window.transactionsPageList.payType});

      let parameters = {
        urls: this.state.urls,
        token : users.token,
        values:{},
        nameOfReport : this.state.title,
        headerShow : this.state.headerShow
      } 
      this.setState({
        "parameters":parameters,
        labelSelectName: "Select personnel",
        Collections: personnelOpt,
        labelSelectName4: "Prescriber",
        Collections4: PrescriberOpt,
        studenId: null
      })

      let dataCallback = {
        BranchsSelect: users.branch, 
        BranchidSelect: users.BranchidSelect,
        OrganizationSelect: users.OrganizationSelect,
        startDate: start,
        endDate: end,
        // CollectionsSelect: window.transactionsPageList.type[0],
        // CollectionsSelect2: window.transactionsPageList.payType[0],
        // CollectionsSelect3: window.transactionsPageList.sorting[0]
      }
      this.getbranchs(dataCallback)
      this.findPrescriber()

      //+-console.log("optionBranchs =>",branchID)
      //+-console.log("optionProjects =>",project)
      //+-console.log("optionOrganization =>",organization)
    }
  }

  showDetail = (itemIn,index) =>{
    // console.log('-------- itemIn,index =>', itemIn, index)
    if(!itemIn.sensor) return

    let dataShow = {}
    if(itemIn.branchID) { dataShow['branchID'] = itemIn.branchID} else {dataShow['branchID'] = '-'}
    if(itemIn.branch) { dataShow['branch'] = itemIn.branch} else {dataShow['branch'] = '-'}
    if(itemIn.value) { dataShow['value'] = itemIn.value} else {dataShow['value'] = '-'}
    if(itemIn.sensor && itemIn.sensor.volt_1 != undefined) { dataShow['volt_1'] = itemIn.sensor.volt_1} else {dataShow['volt_1'] = '-'}
    if(itemIn.sensor && itemIn.sensor.volt_2 != undefined) { dataShow['volt_2'] = itemIn.sensor.volt_2} else {dataShow['volt_2'] = '-'}
    if(itemIn.sensor && itemIn.sensor.current_1 != undefined) { dataShow['current_1'] = itemIn.sensor.current_1} else {dataShow['current_1'] = '-'}
    if(itemIn.sensor && itemIn.sensor.current_2 != undefined) { dataShow['current_2'] = itemIn.sensor.current_2} else {dataShow['current_2'] = '-'}
    if(itemIn.sensor && itemIn.sensor.watt_1 != undefined) { dataShow['watt_1'] = itemIn.sensor.watt_1} else {dataShow['watt_1'] = '-'}
    if(itemIn.sensor && itemIn.sensor.watt_2 != undefined) { dataShow['watt_2'] = itemIn.sensor.watt_2} else {dataShow['watt_2'] = '-'}
    if(itemIn.sensor && itemIn.sensor.var_PH != undefined) { dataShow['var_PH'] = itemIn.sensor.var_PH} else {dataShow['var_PH'] = '-'}
    if(itemIn.sensor && itemIn.sensor.var_ORP != undefined) { dataShow['var_ORP'] = itemIn.sensor.var_ORP} else {dataShow['var_ORP'] = '-'}
    if(itemIn.sensor && itemIn.sensor.var_EC != undefined) { dataShow['var_EC'] = itemIn.sensor.var_EC} else {dataShow['var_EC'] = '-'}
    if(itemIn.sensor && itemIn.sensor.Set_EC != undefined) { dataShow['Set_EC'] = itemIn.sensor.Set_EC} else {dataShow['Set_EC'] = '-'}
    if(itemIn.sensor && itemIn.sensor.M_V != undefined) { dataShow['M_V'] = itemIn.sensor.M_V} else {dataShow['M_V'] = '-'}
    if(itemIn.sensor && itemIn.sensor.M_A != undefined) { dataShow['M_A'] = itemIn.sensor.M_A} else {dataShow['M_A'] = '-'}
    if(itemIn.sensor && itemIn.sensor.M_PP != undefined) { dataShow['M_PP'] = itemIn.sensor.M_PP} else {dataShow['M_PP'] = '-'}
    if(itemIn.sensor && itemIn.sensor.M_PN != undefined) { dataShow['M_PN'] = itemIn.sensor.M_PN} else {dataShow['M_PN'] = '-'}
    if(itemIn.sensor && itemIn.sensor.M_VL != undefined) { dataShow['M_VL'] = itemIn.sensor.M_VL} else {dataShow['M_VL'] = '-'}
    if(itemIn.sensor && itemIn.sensor.M_VT != undefined) { dataShow['M_VT'] = itemIn.sensor.M_VT} else {dataShow['M_VT'] = '-'}
    if(itemIn.sensor && itemIn.sensor.M_EP != undefined) { dataShow['M_EP'] = itemIn.sensor.M_EP} else {dataShow['M_EP'] = '-'}
    if(itemIn.sensor && itemIn.sensor.M_EN != undefined) { dataShow['M_EN'] = itemIn.sensor.M_EN} else {dataShow['M_EN'] = '-'}
    if(itemIn.sensor && itemIn.sensor.M_VF != undefined) { dataShow['M_VF'] = itemIn.sensor.M_VF} else {dataShow['M_VF'] = '-'}

    let modalForm = [
      { key: "branchID", name: "ID", placeholder: "", value: dataShow.branchID, disabled: true, type: 'input', col : 1}, 
      { key: "branch", name: "Name", placeholder:"", value: dataShow.branch, disabled: true, type: 'input', col:2 }, 
      { key: "Value", name: "Value", placeholder:"", value: dataShow.value, disabled: true, type: 'input', col:1 }, 
      { key: "var_PH", name: "var_PH", placeholder:"", value: dataShow.var_PH, disabled: true, type: 'input', col:2 }, 
      { key: "var_ORP", name: "var_ORP", placeholder:"", value: dataShow.var_ORP, disabled: true, type: 'input', col:1 }, 
      { key: "var_EC", name: "var_EC", placeholder:"", value: dataShow.var_EC, disabled: true, type: 'input', col:2 }, 
      { key: "Set_EC", name: "Set_EC", placeholder:"", value: dataShow.Set_EC, disabled: true, type: 'input', col:1 },
      { key: "M_VF", name: "M_VF", placeholder:"", value: dataShow.M_VF, disabled: true, type: 'input', col:2 }, 
      { key: "M_VL", name: "M_VL", placeholder:"", value: dataShow.M_VL, disabled: true, type: 'input', col:1 }, 
      { key: "M_VT", name: "M_VT", placeholder:"", value: dataShow.M_VT, disabled: true, type: 'input', col:2 }, 
      { key: "volt_1", name: "volt_1", placeholder:"", value: dataShow.volt_1, disabled: true, type: 'input', col:1 }, 
      { key: "volt_2", name: "volt_2", placeholder:"", value: dataShow.volt_2, disabled: true, type: 'input', col:2 }, 
      { key: "current_1", name: "current_1", placeholder:"", value: dataShow.current_1, disabled: true, type: 'input', col:1 }, 
      { key: "current_2", name: "current_2", placeholder:"", value: dataShow.current_2, disabled: true, type: 'input', col:2 }, 
      { key: "watt_1", name: "watt_1", placeholder:"", value: dataShow.watt_1, disabled: true, type: 'input', col:1 }, 
      { key: "watt_2", name: "watt_2", placeholder:"", value: dataShow.watt_2, disabled: true, type: 'input', col:2 },  
      { key: "M_V", name: "M_V", placeholder:"", value: dataShow.M_V, disabled: true, type: 'input', col:1 }, 
      { key: "M_A", name: "M_A", placeholder:"", value: dataShow.M_A, disabled: true, type: 'input', col:2 }, 
      { key: "M_PP", name: "M_PP", placeholder:"", value: dataShow.M_PP, disabled: true, type: 'input', col:1 }, 
      { key: "M_PN", name: "M_PN", placeholder:"", value: dataShow.M_PN, disabled: true, type: 'input', col:2 }, 
      { key: "M_EP", name: "M_EP", placeholder:"", value: dataShow.M_EP, disabled: true, type: 'input', col:1 }, 
      { key: "M_EN", name: "M_EN", placeholder:"", value: dataShow.M_EN, disabled: true, type: 'input', col:2 }, 
    ]
    // console.log('modalForm =>', modalForm);
    this.setState({
      modalForm: modalForm,
      modalDetailShow: true
    });
  }

  modalClose = () => {
    // this.setState({modalWorning: ""})
    this.setState({ modalWorning: "", modalDetailShow: false });
    // console.log('this.state.modalForm =>', this.state.modalForm)
  };

  render() {
    users = storeGlobal.getusers();
    const paginationStyle = {
      display: 'table',
      margin: '0 auto',
    };
    // console.log("Products user -->",users)
    // console.log("Products branchs -->",this.state.branchs)
    // console.log("Products branchs -->",this.state.organization)

    return (
      <SiteWrapper user={users} props = {this.props}>
        <Page.Content title={this.state.title}>
        {(this.state.optionBranchs && this.state.optionProjects && this.state.optionOrganizations) &&
          <SelectBranchs 
            typeSelectFilter = {this.state.typeSelectFilter}
            getbranchs = {this.getbranchs}
            CollectionsChang = {this.CollectionsChang} 
            optionOrganizations = {this.state.optionOrganizations}
            optionProjects = {this.state.optionProjects}
            optionBranchs = {this.state.optionBranchs}
            labelSelectName = {this.state.labelSelectName}
            Collections = {this.state.Collections}
            labelSelectName4 = {this.state.labelSelectName4}
            Collections4 = {this.state.Collections4}
            labelInputName = {this.state.studenId}
          />
        }
          <Grid.Row cards={true}>
            <Grid.Col lg={12}>
              <Card>
                <Card.Header>
                  {/* <Card.Title>{this.state.title} : {this.state.brabchNameSelect}</Card.Title> */}
                  <Card.Title>{this.state.title} </Card.Title>
                  <Card.Options>
                    {(this.state.parameters.token !== "") && (this.state.parameters.urls !== "") &&  
                      <ButtonExport
                        parameters = {this.state.parameters}
                        isButtonDisabled = {this.state.isButtonDisabled}
                      >
                      </ButtonExport>
                    }
                  </Card.Options>
                </Card.Header>
                  <Table
                    cards={true}
                    striped={true}
                    responsive={true}
                    className="table-vcenter"
                  >
                  <Table.Header>
                    <Table.Row>
                    {this.state.headerShow.map( (item, index) =>
                      <Table.ColHeader key={index} style={{ textTransform: 'none'}}>{item}</Table.ColHeader>
                    )}
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {this.state.value.length ? 
                      this.state.value.map( (items, indexs) =>
                        <Table.Row key={indexs} onClick = {() =>this.showDetail(items, indexs)}>
                          {/* {items.map( (item, index) =>
                            <Table.Col key={index}>{item}</Table.Col> 
                          )} */}

                          <Table.Col className="text-center align-middle">{items.index}</Table.Col>
                          <Table.Col className="text-center align-middle">
                            <Moment format="DD/MM/YYYY HH:mm">
                              { items.date }
                            </Moment>
                          </Table.Col>
                          <Table.Col className="text-center align-middle">{items.orderId}</Table.Col>
                          <Table.Col className="text-center align-middle">{items.studentId}</Table.Col>
                          <Table.Col className="text-center align-middle">{items.name}</Table.Col>
                          <Table.Col className="text-center align-middle">{items.lastName}</Table.Col>
                          <Table.Col className="text-center align-middle">{items.createBy}</Table.Col>
                          <Table.Col className="text-center align-middle">
                            <Moment format="DD/MM/YYYY HH:mm">
                              { items.activeDate }
                            </Moment>
                          </Table.Col>
                          <Table.Col className="text-center align-middle">
                            <Moment format="DD/MM/YYYY HH:mm">
                              { items.expireDate }
                            </Moment>
                          </Table.Col>
                          <Table.Col className="text-center align-middle">{items.status}</Table.Col>

                          {/* {this.state.headers.map( (item, index) =>
                            (item==='date')? <Table.Col key={index}>
                              <Moment format="DD/MM/YYYY HH:mm:ss">
                                { items[item] }
                              </Moment></Table.Col> :
                            <Table.Col key={index}>{items[item]? items[item]: '-'}</Table.Col> 
                          )}                         */}

                        </Table.Row>
                      ) : <Table.Row > 
                            <Table.Col colSpan="12"	alignContent="center">
                              <Text className="my-4" size="" center="true" color="primary" >ไม่พบข้อมูล : Data not found.</Text>
                            </Table.Col>
                          </Table.Row>
                    }
                    <Table.Row > 
                    <Table.Col colSpan="13"	alignContent="center">
                        <div style={paginationStyle}>
                          <Pagination>
                            { this.state.paginationItem.map( number =>
                              <Pagination.Item key={number} active={number === this.state.paginationActive} onClick={ this.paginationActive.bind(this, number) }>
                                {number} 
                              </Pagination.Item>
                            )}
                          </Pagination>
                        </div>      
                      </Table.Col>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Card>
            </Grid.Col>
          </Grid.Row>
        </Page.Content>

        <SweetAlert
          warning
          show={this.state.alertShow}
          title="Oops!"
          onConfirm={() => this.setState({ alertShow: false })}
        >
          {this.state.alertText}
        </SweetAlert>

        <ModalFormLarge
          show={this.state.modalDetailShow}
          onHide={this.modalClose}
          title={"Show more details"}
          worning={this.state.modalWorning}
          form={this.state.modalForm}
        />

      </SiteWrapper>
    );
  }
}

export default PrescriptionAllReport